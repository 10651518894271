// src/web3Service.ts
import { ethers as ethers10 } from "ethers";

// src/utils.ts
import { ethers } from "ethers";
var utils_default = {
  toWei: (value, units) => ethers.parseUnits(value, units),
  // This converts a string representation from a value to a number of units, based on the number of decimals passed in
  toDecimal: (value, decimals) => ethers.parseUnits(value, decimals),
  hexlify: ethers.toBeHex,
  hexStripZeros: ethers.stripZerosLeft,
  bigNumberify: BigInt,
  hexToNumberString: (num) => ethers.formatUnits(BigInt(num), "wei").replace(".0", ""),
  toChecksumAddress: ethers.getAddress,
  fromWei: (num, units) => {
    return ethers.formatUnits(BigInt(num), units).replace(/\.0$/, "");
  },
  // This converts a string representation from a unit value to a higher base
  fromDecimal: (num, decimals) => {
    return ethers.formatUnits(BigInt(num), decimals).replace(/\.0$/, "");
  },
  isInfiniteKeys: (value) => {
    return BigInt(value) === ethers.MaxUint256;
  },
  isInfiniteDuration: (value) => {
    return BigInt(value) === ethers.MaxUint256;
  },
  toNumber: ethers.toNumber,
  toRpcResultNumber: (value) => {
    const num = ethers.toBeArray(value);
    return ethers.zeroPadValue(ethers.getBytes(num), 32);
  },
  toRpcResultString: (str) => {
    return str;
  },
  utf8ToHex: (str) => ethers.hexlify(ethers.toUtf8Bytes(str.length ? str : "0x")),
  sha3: ethers.keccak256,
  verifyMessage: ethers.verifyMessage
};

// src/unlockService.ts
import { ethers as ethers9 } from "ethers";

// src/abis.ts
var abis = {
  "PublicLock": {
    "v9": {
      "contractName": "PublicLock",
      "abi": [
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event Disable ()",
        "event ExpirationChanged (uint256 indexed _tokenId,uint256 _amount,bool _timeAdded)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event NewLockSymbol (string symbol)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RenewKeyPurchase (address indexed owner,uint256 newExpiration)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed beneficiary,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function KEY_GRANTER_ROLE() view returns (bytes32)",
        "function LOCK_MANAGER_ROLE() view returns (bytes32)",
        "function addKeyGranter(address account)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function approveBeneficiary(address _spender,uint256 _amount) returns (bool)",
        "function balanceOf(address _keyOwner) view returns (uint256)",
        "function beneficiary() view returns (address)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function disableLock()",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(address _keyOwner,uint256 amount)",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValueFor(address _keyOwner) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTokenIdFor(address _account) view returns (uint256)",
        "function getTransferFee(address _keyOwner,uint256 _time) view returns (uint256)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers)",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isAlive() view returns (bool)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isKeyGranter(address account) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function keyExpirationTimestampFor(address _keyOwner) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256 _value,address _recipient,address _referrer,address _keyManager,bytes _data) payable",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeKeyGranter(address _granter)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setBaseTokenURI(string _baseTokenURI)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook)",
        "function setExpirationDuration(uint256 _newExpirationDuration)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setMaxNumberOfKeys(uint256 _maxNumberOfKeys)",
        "function shareKey(address _to,uint256 _tokenId,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalSupply() view returns (uint256)",
        "function transfer(address _to,uint256 _value) returns (bool success)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateBeneficiary(address _beneficiary)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockName(string _lockName)",
        "function updateLockSymbol(string _lockSymbol)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,uint256 _amount)"
      ],
      "bytecodeHash": "0x0cf7c1b77621773fd65811a23143e5e49946d2de75c18934b495940cab871397"
    },
    "v10": {
      "contractName": "PublicLock",
      "abi": [
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event ExpirationChanged (uint256 indexed _tokenId,uint256 _amount,bool _timeAdded)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event KeyExtended (uint256 indexed tokenId,uint256 newTimestamp)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event KeysMigrated (uint256 updatedRecordsCount)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event NewLockSymbol (string symbol)",
        "event OwnershipTransferred (address previousOwner,address newOwner)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RenewKeyPurchase (address indexed owner,uint256 newExpiration)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed beneficiary,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function KEY_GRANTER_ROLE() view returns (bytes32)",
        "function LOCK_MANAGER_ROLE() view returns (bytes32)",
        "function addKeyGranter(address account)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function approveBeneficiary(address _spender,uint256 _amount) returns (bool)",
        "function balanceOf(address _keyOwner) view returns (uint256)",
        "function beneficiary() view returns (address)",
        "function burn(uint256 _tokenId)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(uint256 _tokenId,uint256 _amount)",
        "function extend(uint256 _value,uint256 _tokenId,address _referrer,bytes _data) payable",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValue(uint256 _tokenId) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTransferFee(uint256 _tokenId,uint256 _time) view returns (uint256)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers)",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isKeyGranter(address account) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function isOwner(address account) view returns (bool)",
        "function isValidKey(uint256 _tokenId) view returns (bool)",
        "function keyExpirationTimestampFor(uint256 _tokenId) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function maxKeysPerAddress() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function mergeKeys(uint256 _tokenIdFrom,uint256 _tokenIdTo,uint256 _amount)",
        "function migrate(bytes _calldata)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function owner() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256[] _values,address[] _recipients,address[] _referrers,address[] _keyManagers,bytes[] _data) payable",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renewMembershipFor(uint256 _tokenId,address _referrer)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeKeyGranter(address _granter)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function schemaVersion() view returns (uint256)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setBaseTokenURI(string _baseTokenURI)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook)",
        "function setExpirationDuration(uint256 _newExpirationDuration)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setMaxKeysPerAddress(uint256 _maxKeys)",
        "function setMaxNumberOfKeys(uint256 _maxNumberOfKeys)",
        "function setOwner(address account)",
        "function shareKey(address _to,uint256 _tokenIdFrom,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalSupply() view returns (uint256)",
        "function transfer(uint256 _tokenId,address _to,uint256 _valueBasisPoint) returns (bool success)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateBeneficiary(address _beneficiary)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockName(string _lockName)",
        "function updateLockSymbol(string _lockSymbol)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateSchemaVersion()",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,uint256 _amount)"
      ],
      "bytecodeHash": "0xab13b065214b7b56d27e5a3626bcb8ba4c691b34b8ebb01e1ab0fb93165fa40a"
    },
    "v11": {
      "contractName": "PublicLock",
      "abi": [
        "error CANNOT_APPROVE_SELF()",
        "error CANT_BE_SMALLER_THAN_SUPPLY()",
        "error CANT_EXTEND_NON_EXPIRING_KEY()",
        "error GAS_REFUND_FAILED()",
        "error INSUFFICIENT_ERC20_VALUE()",
        "error INSUFFICIENT_VALUE()",
        "error INVALID_ADDRESS()",
        "error INVALID_HOOK(uint8 hookIndex)",
        "error INVALID_LENGTH()",
        "error INVALID_TOKEN()",
        "error KEY_NOT_VALID()",
        "error KEY_TRANSFERS_DISABLED()",
        "error LOCK_HAS_CHANGED()",
        "error LOCK_SOLD_OUT()",
        "error MAX_KEYS_REACHED()",
        "error MIGRATION_REQUIRED()",
        "error NON_COMPLIANT_ERC721_RECEIVER()",
        "error NON_RENEWABLE_LOCK()",
        "error NOT_ENOUGH_FUNDS()",
        "error NOT_ENOUGH_TIME()",
        "error NOT_READY_FOR_RENEWAL()",
        "error NO_SUCH_KEY()",
        "error NULL_VALUE()",
        "error ONLY_KEY_MANAGER_OR_APPROVED()",
        "error ONLY_LOCK_MANAGER()",
        "error ONLY_LOCK_MANAGER_OR_BENEFICIARY()",
        "error ONLY_LOCK_MANAGER_OR_KEY_GRANTER()",
        "error OUT_OF_RANGE()",
        "error OWNER_CANT_BE_ADDRESS_ZERO()",
        "error SCHEMA_VERSION_NOT_CORRECT()",
        "error TRANSFER_TO_SELF()",
        "error UNAUTHORIZED()",
        "error UNAUTHORIZED_KEY_MANAGER_UPDATE()",
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event ExpirationChanged (uint256 indexed _tokenId,uint256 _amount,bool _timeAdded)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event Initialized (uint8 version)",
        "event KeyExtended (uint256 indexed tokenId,uint256 newTimestamp)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event KeysMigrated (uint256 updatedRecordsCount)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event NewLockSymbol (string symbol)",
        "event OwnershipTransferred (address previousOwner,address newOwner)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed beneficiary,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function KEY_GRANTER_ROLE() view returns (bytes32)",
        "function LOCK_MANAGER_ROLE() view returns (bytes32)",
        "function addKeyGranter(address account)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function approveBeneficiary(address _spender,uint256 _amount) returns (bool)",
        "function balanceOf(address _keyOwner) view returns (uint256 balance)",
        "function beneficiary() view returns (address)",
        "function burn(uint256 _tokenId)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(uint256 _tokenId,uint256 _amount)",
        "function extend(uint256 _value,uint256 _tokenId,address _referrer,bytes _data) payable",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValue(uint256 _tokenId) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTransferFee(uint256 _tokenId,uint256 _time) view returns (uint256)",
        "function grantKeyExtension(uint256 _tokenId,uint256 _duration)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers) returns (uint256[])",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isKeyGranter(address account) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function isOwner(address account) view returns (bool)",
        "function isValidKey(uint256 _tokenId) view returns (bool)",
        "function keyExpirationTimestampFor(uint256 _tokenId) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function lendKey(address _from,address _recipient,uint256 _tokenId)",
        "function maxKeysPerAddress() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function mergeKeys(uint256 _tokenIdFrom,uint256 _tokenIdTo,uint256 _amount)",
        "function migrate(bytes)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onKeyTransferHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function owner() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256[] _values,address[] _recipients,address[] _referrers,address[] _keyManagers,bytes[] _data) payable returns (uint256[])",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function referrerFees(address) view returns (uint256)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renewMembershipFor(uint256 _tokenId,address _referrer)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeKeyGranter(address _granter)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function schemaVersion() view returns (uint256)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setBaseTokenURI(string _baseTokenURI)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook,address _onKeyTransferHook)",
        "function setExpirationDuration(uint256 _newExpirationDuration)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setMaxKeysPerAddress(uint256 _maxKeys)",
        "function setMaxNumberOfKeys(uint256 _maxNumberOfKeys)",
        "function setOwner(address account)",
        "function setReferrerFee(address _referrer,uint256 _feeBasisPoint)",
        "function shareKey(address _to,uint256 _tokenIdFrom,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalKeys(address _keyOwner) view returns (uint256)",
        "function totalSupply() view returns (uint256)",
        "function transfer(uint256 _tokenId,address _to,uint256 _valueBasisPoint) returns (bool success)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlendKey(address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateBeneficiary(address _beneficiary)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockName(string _lockName)",
        "function updateLockSymbol(string _lockSymbol)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateSchemaVersion()",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,uint256 _amount)"
      ],
      "bytecodeHash": "0x2cc5097486050fd8d352e6f0bb5e5044510093b9c71879538bb182699c12cd88"
    },
    "v12": {
      "contractName": "PublicLock",
      "abi": [
        "error CANNOT_APPROVE_SELF()",
        "error CANT_BE_SMALLER_THAN_SUPPLY()",
        "error CANT_EXTEND_NON_EXPIRING_KEY()",
        "error GAS_REFUND_FAILED()",
        "error INSUFFICIENT_ERC20_VALUE()",
        "error INSUFFICIENT_VALUE()",
        "error INVALID_ADDRESS()",
        "error INVALID_HOOK(uint8 hookIndex)",
        "error INVALID_LENGTH()",
        "error INVALID_TOKEN()",
        "error KEY_NOT_VALID()",
        "error KEY_TRANSFERS_DISABLED()",
        "error LOCK_HAS_CHANGED()",
        "error LOCK_SOLD_OUT()",
        "error MAX_KEYS_REACHED()",
        "error MIGRATION_REQUIRED()",
        "error NON_COMPLIANT_ERC721_RECEIVER()",
        "error NON_RENEWABLE_LOCK()",
        "error NOT_ENOUGH_FUNDS()",
        "error NOT_ENOUGH_TIME()",
        "error NOT_READY_FOR_RENEWAL()",
        "error NO_SUCH_KEY()",
        "error NULL_VALUE()",
        "error ONLY_KEY_MANAGER_OR_APPROVED()",
        "error ONLY_LOCK_MANAGER()",
        "error ONLY_LOCK_MANAGER_OR_KEY_GRANTER()",
        "error OUT_OF_RANGE()",
        "error OWNER_CANT_BE_ADDRESS_ZERO()",
        "error SCHEMA_VERSION_NOT_CORRECT()",
        "error TRANSFER_TO_SELF()",
        "error UNAUTHORIZED()",
        "error UNAUTHORIZED_KEY_MANAGER_UPDATE()",
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event ExpirationChanged (uint256 indexed tokenId,uint256 newExpiration,uint256 amount,bool timeAdded)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event Initialized (uint8 version)",
        "event KeyExtended (uint256 indexed tokenId,uint256 newTimestamp)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event KeysMigrated (uint256 updatedRecordsCount)",
        "event LockConfig (uint256 expirationDuration,uint256 maxNumberOfKeys,uint256 maxKeysPerAcccount)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event LockMetadata (string name,string symbol,string baseTokenURI)",
        "event OwnershipTransferred (address previousOwner,address newOwner)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed recipient,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function KEY_GRANTER_ROLE() view returns (bytes32)",
        "function LOCK_MANAGER_ROLE() view returns (bytes32)",
        "function addKeyGranter(address account)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function balanceOf(address _keyOwner) view returns (uint256 balance)",
        "function burn(uint256 _tokenId)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(uint256 _tokenId,uint256 _amount)",
        "function extend(uint256 _value,uint256 _tokenId,address _referrer,bytes _data) payable",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValue(uint256 _tokenId) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTransferFee(uint256 _tokenId,uint256 _time) view returns (uint256)",
        "function grantKeyExtension(uint256 _tokenId,uint256 _duration)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers) returns (uint256[])",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isKeyGranter(address account) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function isOwner(address account) view returns (bool)",
        "function isValidKey(uint256 _tokenId) view returns (bool)",
        "function keyExpirationTimestampFor(uint256 _tokenId) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function lendKey(address _from,address _recipient,uint256 _tokenId)",
        "function maxKeysPerAddress() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function mergeKeys(uint256 _tokenIdFrom,uint256 _tokenIdTo,uint256 _amount)",
        "function migrate(bytes)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyExtendHook() view returns (address)",
        "function onKeyGrantHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onKeyTransferHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function owner() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256[] _values,address[] _recipients,address[] _referrers,address[] _keyManagers,bytes[] _data) payable returns (uint256[])",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function referrerFees(address) view returns (uint256)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renewMembershipFor(uint256 _tokenId,address _referrer)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeKeyGranter(address _granter)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function schemaVersion() view returns (uint256)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook,address _onKeyTransferHook,address _onKeyExtendHook,address _onKeyGrantHook)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setLockMetadata(string _lockName,string _lockSymbol,string _baseTokenURI)",
        "function setOwner(address account)",
        "function setReferrerFee(address _referrer,uint256 _feeBasisPoint)",
        "function shareKey(address _to,uint256 _tokenIdFrom,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalKeys(address _keyOwner) view returns (uint256)",
        "function totalSupply() view returns (uint256)",
        "function transfer(uint256 _tokenId,address _to,uint256 _valueBasisPoint) returns (bool success)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlendKey(address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockConfig(uint256 _newExpirationDuration,uint256 _maxNumberOfKeys,uint256 _maxKeysPerAcccount)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateSchemaVersion()",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,address _recipient,uint256 _amount)"
      ],
      "bytecodeHash": "0xdd005f4fe02b48a06215a3996da43da9689a5638d00ec714d19d6aae81f9dbf6"
    },
    "v13": {
      "contractName": "PublicLock",
      "abi": [
        "error CANNOT_APPROVE_SELF()",
        "error CANT_BE_SMALLER_THAN_SUPPLY()",
        "error CANT_EXTEND_NON_EXPIRING_KEY()",
        "error GAS_REFUND_FAILED()",
        "error INSUFFICIENT_ERC20_VALUE()",
        "error INSUFFICIENT_VALUE()",
        "error INVALID_ADDRESS()",
        "error INVALID_HOOK(uint8 hookIndex)",
        "error INVALID_LENGTH()",
        "error INVALID_TOKEN()",
        "error KEY_NOT_VALID()",
        "error KEY_TRANSFERS_DISABLED()",
        "error LOCK_HAS_CHANGED()",
        "error LOCK_SOLD_OUT()",
        "error MAX_KEYS_REACHED()",
        "error MIGRATION_REQUIRED()",
        "error NON_COMPLIANT_ERC721_RECEIVER()",
        "error NON_RENEWABLE_LOCK()",
        "error NOT_ENOUGH_FUNDS()",
        "error NOT_ENOUGH_TIME()",
        "error NOT_READY_FOR_RENEWAL()",
        "error NO_SUCH_KEY()",
        "error NULL_VALUE()",
        "error ONLY_KEY_MANAGER_OR_APPROVED()",
        "error ONLY_LOCK_MANAGER()",
        "error ONLY_LOCK_MANAGER_OR_KEY_GRANTER()",
        "error OUT_OF_RANGE()",
        "error OWNER_CANT_BE_ADDRESS_ZERO()",
        "error SCHEMA_VERSION_NOT_CORRECT()",
        "error TRANSFER_TO_SELF()",
        "error TransferFailed()",
        "error UNAUTHORIZED()",
        "error UNAUTHORIZED_KEY_MANAGER_UPDATE()",
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event EventHooksUpdated (address onKeyPurchaseHook,address onKeyCancelHook,address onValidKeyHook,address onTokenURIHook,address onKeyTransferHook,address onKeyExtendHook,address onKeyGrantHook)",
        "event ExpirationChanged (uint256 indexed tokenId,uint256 newExpiration,uint256 amount,bool timeAdded)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefundValueChanged (uint256 refundValue)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event Initialized (uint8 version)",
        "event KeyExtended (uint256 indexed tokenId,uint256 newTimestamp)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event KeysMigrated (uint256 updatedRecordsCount)",
        "event LockConfig (uint256 expirationDuration,uint256 maxNumberOfKeys,uint256 maxKeysPerAcccount)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event LockMetadata (string name,string symbol,string baseTokenURI)",
        "event OwnershipTransferred (address previousOwner,address newOwner)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event ReferrerFee (address indexed referrer,uint256 fee)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed recipient,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function balanceOf(address _keyOwner) view returns (uint256 balance)",
        "function burn(uint256 _tokenId)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(uint256 _tokenId,uint256 _amount)",
        "function extend(uint256 _value,uint256 _tokenId,address _referrer,bytes _data) payable",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValue(uint256 _tokenId) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTransferFee(uint256 _tokenId,uint256 _time) view returns (uint256)",
        "function grantKeyExtension(uint256 _tokenId,uint256 _duration)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers) returns (uint256[])",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function isOwner(address account) view returns (bool)",
        "function isRenewable(uint256 _tokenId,address _referrer) view returns (bool)",
        "function isValidKey(uint256 _tokenId) view returns (bool)",
        "function keyExpirationTimestampFor(uint256 _tokenId) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function lendKey(address _from,address _recipient,uint256 _tokenId)",
        "function maxKeysPerAddress() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function mergeKeys(uint256 _tokenIdFrom,uint256 _tokenIdTo,uint256 _amount)",
        "function migrate(bytes)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyExtendHook() view returns (address)",
        "function onKeyGrantHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onKeyTransferHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function owner() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256[] _values,address[] _recipients,address[] _referrers,address[] _keyManagers,bytes[] _data) payable returns (uint256[])",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function referrerFees(address) view returns (uint256)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renewMembershipFor(uint256 _tokenId,address _referrer)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function schemaVersion() view returns (uint256)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook,address _onKeyTransferHook,address _onKeyExtendHook,address _onKeyGrantHook)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setLockMetadata(string _lockName,string _lockSymbol,string _baseTokenURI)",
        "function setOwner(address account)",
        "function setReferrerFee(address _referrer,uint256 _feeBasisPoint)",
        "function shareKey(address _to,uint256 _tokenIdFrom,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalKeys(address _keyOwner) view returns (uint256)",
        "function totalSupply() view returns (uint256 _totalKeysCreated)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlendKey(address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockConfig(uint256 _newExpirationDuration,uint256 _maxNumberOfKeys,uint256 _maxKeysPerAcccount)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateSchemaVersion()",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,address _recipient,uint256 _amount)"
      ],
      "bytecodeHash": "0x80041134ce431f1adb2b2dfe00df82753d269761cbf9bac1232e0523fa787ed2"
    },
    "v14": {
      "contractName": "PublicLockV14",
      "abi": [
        "error CANNOT_APPROVE_SELF()",
        "error CANT_BE_SMALLER_THAN_SUPPLY()",
        "error CANT_EXTEND_NON_EXPIRING_KEY()",
        "error GAS_REFUND_FAILED()",
        "error INSUFFICIENT_ERC20_VALUE()",
        "error INSUFFICIENT_VALUE()",
        "error INVALID_ADDRESS()",
        "error INVALID_HOOK(uint8 hookIndex)",
        "error INVALID_LENGTH()",
        "error INVALID_TOKEN()",
        "error KEY_NOT_VALID()",
        "error KEY_TRANSFERS_DISABLED()",
        "error LOCK_HAS_CHANGED()",
        "error LOCK_SOLD_OUT()",
        "error MAX_KEYS_REACHED()",
        "error MIGRATION_REQUIRED()",
        "error NON_COMPLIANT_ERC721_RECEIVER()",
        "error NON_RENEWABLE_LOCK()",
        "error NOT_ENOUGH_FUNDS()",
        "error NOT_ENOUGH_TIME()",
        "error NOT_READY_FOR_RENEWAL()",
        "error NO_SUCH_KEY()",
        "error NULL_VALUE()",
        "error ONLY_KEY_MANAGER_OR_APPROVED()",
        "error ONLY_LOCK_MANAGER()",
        "error ONLY_LOCK_MANAGER_OR_KEY_GRANTER()",
        "error OUT_OF_RANGE()",
        "error OWNER_CANT_BE_ADDRESS_ZERO()",
        "error SCHEMA_VERSION_NOT_CORRECT()",
        "error TRANSFER_TO_SELF()",
        "error TransferFailed()",
        "error UNAUTHORIZED()",
        "error UNAUTHORIZED_KEY_MANAGER_UPDATE()",
        "event Approval (address indexed owner,address indexed approved,uint256 indexed tokenId)",
        "event ApprovalForAll (address indexed owner,address indexed operator,bool approved)",
        "event CancelKey (uint256 indexed tokenId,address indexed owner,address indexed sendTo,uint256 refund)",
        "event EventHooksUpdated (address onKeyPurchaseHook,address onKeyCancelHook,address onValidKeyHook,address onTokenURIHook,address onKeyTransferHook,address onKeyExtendHook,address onKeyGrantHook)",
        "event ExpirationChanged (uint256 indexed tokenId,uint256 prevExpiration,uint256 newExpiration)",
        "event ExpireKey (uint256 indexed tokenId)",
        "event GasRefundValueChanged (uint256 refundValue)",
        "event GasRefunded (address indexed receiver,uint256 refundedAmount,address tokenAddress)",
        "event Initialized (uint8 version)",
        "event KeyExtended (uint256 indexed tokenId,uint256 newTimestamp)",
        "event KeyGranterAdded (address indexed account)",
        "event KeyGranterRemoved (address indexed account)",
        "event KeyManagerChanged (uint256 indexed _tokenId,address indexed _newManager)",
        "event KeysMigrated (uint256 updatedRecordsCount)",
        "event LockConfig (uint256 expirationDuration,uint256 maxNumberOfKeys,uint256 maxKeysPerAcccount)",
        "event LockManagerAdded (address indexed account)",
        "event LockManagerRemoved (address indexed account)",
        "event LockMetadata (string name,string symbol,string baseTokenURI)",
        "event OwnershipTransferred (address previousOwner,address newOwner)",
        "event PricingChanged (uint256 oldKeyPrice,uint256 keyPrice,address oldTokenAddress,address tokenAddress)",
        "event ReferrerFee (address indexed referrer,uint256 fee)",
        "event RefundPenaltyChanged (uint256 freeTrialLength,uint256 refundPenaltyBasisPoints)",
        "event RoleAdminChanged (bytes32 indexed role,bytes32 indexed previousAdminRole,bytes32 indexed newAdminRole)",
        "event RoleGranted (bytes32 indexed role,address indexed account,address indexed sender)",
        "event RoleRevoked (bytes32 indexed role,address indexed account,address indexed sender)",
        "event Transfer (address indexed from,address indexed to,uint256 indexed tokenId)",
        "event TransferFeeChanged (uint256 transferFeeBasisPoints)",
        "event UnlockCallFailed (address indexed lockAddress,address unlockAddress)",
        "event Withdrawal (address indexed sender,address indexed tokenAddress,address indexed recipient,uint256 amount)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function addLockManager(address account)",
        "function approve(address _approved,uint256 _tokenId)",
        "function balanceOf(address _keyOwner) view returns (uint256 balance)",
        "function burn(uint256 _tokenId)",
        "function cancelAndRefund(uint256 _tokenId)",
        "function expirationDuration() view returns (uint256)",
        "function expireAndRefundFor(uint256 _tokenId,uint256 _amount)",
        "function extend(uint256 _value,uint256 _tokenId,address _referrer,bytes _data) payable",
        "function freeTrialLength() view returns (uint256)",
        "function gasRefundValue() view returns (uint256 _refundValue)",
        "function getApproved(uint256 _tokenId) view returns (address)",
        "function getCancelAndRefundValue(uint256 _tokenId) view returns (uint256 refund)",
        "function getHasValidKey(address _keyOwner) view returns (bool isValid)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getTransferFee(uint256 _tokenId,uint256 _time) view returns (uint256)",
        "function grantKeyExtension(uint256 _tokenId,uint256 _duration)",
        "function grantKeys(address[] _recipients,uint256[] _expirationTimestamps,address[] _keyManagers) returns (uint256[])",
        "function grantRole(bytes32 role,address account)",
        "function hasRole(bytes32 role,address account) view returns (bool)",
        "function initialize(address _lockCreator,uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName)",
        "function isApprovedForAll(address _owner,address _operator) view returns (bool)",
        "function isLockManager(address account) view returns (bool)",
        "function isOwner(address account) view returns (bool)",
        "function isRenewable(uint256 _tokenId,address _referrer) view returns (bool)",
        "function isValidKey(uint256 _tokenId) view returns (bool)",
        "function keyExpirationTimestampFor(uint256 _tokenId) view returns (uint256)",
        "function keyManagerOf(uint256) view returns (address)",
        "function keyPrice() view returns (uint256)",
        "function lendKey(address _from,address _recipient,uint256 _tokenId)",
        "function maxKeysPerAddress() view returns (uint256)",
        "function maxNumberOfKeys() view returns (uint256)",
        "function mergeKeys(uint256 _tokenIdFrom,uint256 _tokenIdTo,uint256 _amount)",
        "function migrate(bytes)",
        "function name() view returns (string)",
        "function numberOfOwners() view returns (uint256)",
        "function onKeyCancelHook() view returns (address)",
        "function onKeyExtendHook() view returns (address)",
        "function onKeyGrantHook() view returns (address)",
        "function onKeyPurchaseHook() view returns (address)",
        "function onKeyTransferHook() view returns (address)",
        "function onTokenURIHook() view returns (address)",
        "function onValidKeyHook() view returns (address)",
        "function owner() view returns (address)",
        "function ownerOf(uint256 _tokenId) view returns (address)",
        "function publicLockVersion() pure returns (uint16)",
        "function purchase(uint256[] _values,address[] _recipients,address[] _referrers,address[] _keyManagers,bytes[] _data) payable returns (uint256[])",
        "function purchasePriceFor(address _recipient,address _referrer,bytes _data) view returns (uint256 minKeyPrice)",
        "function referrerFees(address) view returns (uint256)",
        "function refundPenaltyBasisPoints() view returns (uint256)",
        "function renewMembershipFor(uint256 _tokenId,address _referrer)",
        "function renounceLockManager()",
        "function renounceRole(bytes32 role,address account)",
        "function revokeRole(bytes32 role,address account)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId)",
        "function safeTransferFrom(address _from,address _to,uint256 _tokenId,bytes _data)",
        "function schemaVersion() view returns (uint256)",
        "function setApprovalForAll(address _to,bool _approved)",
        "function setEventHooks(address _onKeyPurchaseHook,address _onKeyCancelHook,address _onValidKeyHook,address _onTokenURIHook,address _onKeyTransferHook,address _onKeyExtendHook,address _onKeyGrantHook)",
        "function setGasRefundValue(uint256 _refundValue)",
        "function setKeyExpiration(uint256 _tokenId,uint256 _newExpiration)",
        "function setKeyManagerOf(uint256 _tokenId,address _keyManager)",
        "function setLockMetadata(string _lockName,string _lockSymbol,string _baseTokenURI)",
        "function setOwner(address account)",
        "function setReferrerFee(address _referrer,uint256 _feeBasisPoint)",
        "function shareKey(address _to,uint256 _tokenIdFrom,uint256 _timeShared)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function symbol() view returns (string)",
        "function tokenAddress() view returns (address)",
        "function tokenByIndex(uint256 _index) view returns (uint256)",
        "function tokenOfOwnerByIndex(address _keyOwner,uint256 _index) view returns (uint256)",
        "function tokenURI(uint256 _tokenId) view returns (string)",
        "function totalKeys(address _keyOwner) view returns (uint256)",
        "function totalSupply() view returns (uint256 _totalKeysCreated)",
        "function transferFeeBasisPoints() view returns (uint256)",
        "function transferFrom(address _from,address _recipient,uint256 _tokenId)",
        "function unlendKey(address _recipient,uint256 _tokenId)",
        "function unlockProtocol() view returns (address)",
        "function updateKeyPricing(uint256 _keyPrice,address _tokenAddress)",
        "function updateLockConfig(uint256 _newExpirationDuration,uint256 _maxNumberOfKeys,uint256 _maxKeysPerAcccount)",
        "function updateRefundPenalty(uint256 _freeTrialLength,uint256 _refundPenaltyBasisPoints)",
        "function updateSchemaVersion()",
        "function updateTransferFee(uint256 _transferFeeBasisPoints)",
        "function withdraw(address _tokenAddress,address _recipient,uint256 _amount)"
      ],
      "bytecodeHash": "0x349cbd03803a9577581041659dfa1d832ca72d580bdb998e38907e98f20ad649"
    }
  },
  "Unlock": {
    "v11": {
      "contractName": "Unlock",
      "abi": [
        "event ConfigUnlock (address udt,address weth,uint256 estimatedGasForPurchase,string globalTokenSymbol,string globalTokenURI,uint256 chainId)",
        "event GNPChanged (uint256 grossNetworkProduct,uint256 _valueInETH,address tokenAddress,uint256 value,address lockAddress)",
        "event LockUpgraded (address lockAddress,uint16 version)",
        "event NewLock (address indexed lockOwner,address indexed newLockAddress)",
        "event OwnershipTransferred (address indexed previousOwner,address indexed newOwner)",
        "event ResetTrackedValue (uint256 grossNetworkProduct,uint256 totalDiscountGranted)",
        "event SetLockTemplate (address publicLockAddress)",
        "event UnlockTemplateAdded (address indexed impl,uint16 indexed version)",
        "function __initializeOwnable(address sender)",
        "function addLockTemplate(address impl,uint16 version)",
        "function chainId() view returns (uint256)",
        "function computeAvailableDiscountFor(address,uint256) pure returns (uint256 discount,uint256 tokens)",
        "function configUnlock(address _udt,address _weth,uint256 _estimatedGasForPurchase,string _symbol,string _URI,uint256 _chainId)",
        "function createLock(uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName,bytes12) returns (address)",
        "function createUpgradeableLock(bytes data) returns (address)",
        "function createUpgradeableLockAtVersion(bytes data,uint16 _lockVersion) returns (address)",
        "function estimatedGasForPurchase() view returns (uint256)",
        "function getGlobalBaseTokenURI() view returns (string)",
        "function getGlobalTokenSymbol() view returns (string)",
        "function globalBaseTokenURI() view returns (string)",
        "function globalTokenSymbol() view returns (string)",
        "function grossNetworkProduct() view returns (uint256)",
        "function initialize(address _unlockOwner)",
        "function initializeProxyAdmin()",
        "function isOwner() view returns (bool)",
        "function locks(address) view returns (bool deployed,uint256 totalSales,uint256 yieldedDiscountTokens)",
        "function networkBaseFee() view returns (uint256)",
        "function owner() view returns (address)",
        "function proxyAdminAddress() view returns (address)",
        "function publicLockAddress() view returns (address)",
        "function publicLockImpls(uint16 _version) view returns (address)",
        "function publicLockLatestVersion() view returns (uint16)",
        "function publicLockVersions(address _impl) view returns (uint16)",
        "function recordConsumedDiscount(uint256,uint256) view",
        "function recordKeyPurchase(uint256 _value,address _referrer)",
        "function renounceOwnership()",
        "function resetTrackedValue(uint256 _grossNetworkProduct,uint256 _totalDiscountGranted)",
        "function setLockTemplate(address _publicLockAddress)",
        "function setOracle(address _tokenAddress,address _oracleAddress)",
        "function totalDiscountGranted() view returns (uint256)",
        "function transferOwnership(address newOwner)",
        "function udt() view returns (address)",
        "function uniswapOracles(address) view returns (address)",
        "function unlockVersion() pure returns (uint16)",
        "function upgradeLock(address lockAddress,uint16 version) returns (address)",
        "function weth() view returns (address)"
      ],
      "bytecodeHash": "0xa7e4659ad2ab8fff93d896c39a634e985bc475dac4caa8a0494433e53d9a9574"
    },
    "v12": {
      "contractName": "Unlock",
      "abi": [
        "error Unlock__ALREADY_DEPLOYED()",
        "error Unlock__INVALID_AMOUNT()",
        "error Unlock__MANAGER_ONLY()",
        "error Unlock__MISSING_LOCK(address lockAddress)",
        "error Unlock__MISSING_LOCK_TEMPLATE()",
        "error Unlock__MISSING_PROXY_ADMIN()",
        "error Unlock__MISSING_TEMPLATE()",
        "error Unlock__VERSION_TOO_HIGH()",
        "event ConfigUnlock (address udt,address weth,uint256 estimatedGasForPurchase,string globalTokenSymbol,string globalTokenURI,uint256 chainId)",
        "event GNPChanged (uint256 grossNetworkProduct,uint256 _valueInETH,address tokenAddress,uint256 value,address lockAddress)",
        "event LockUpgraded (address lockAddress,uint16 version)",
        "event NewLock (address indexed lockOwner,address indexed newLockAddress)",
        "event OwnershipTransferred (address indexed previousOwner,address indexed newOwner)",
        "event ResetTrackedValue (uint256 grossNetworkProduct,uint256 totalDiscountGranted)",
        "event SetLockTemplate (address publicLockAddress)",
        "event UnlockTemplateAdded (address indexed impl,uint16 indexed version)",
        "function __initializeOwnable(address sender)",
        "function addLockTemplate(address impl,uint16 version)",
        "function chainId() view returns (uint256)",
        "function computeAvailableDiscountFor(address,uint256) pure returns (uint256 discount,uint256 tokens)",
        "function configUnlock(address _udt,address _weth,uint256 _estimatedGasForPurchase,string _symbol,string _URI,uint256 _chainId)",
        "function createLock(uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName,bytes12) returns (address)",
        "function createUpgradeableLock(bytes data) returns (address)",
        "function createUpgradeableLockAtVersion(bytes data,uint16 _lockVersion) returns (address)",
        "function estimatedGasForPurchase() view returns (uint256)",
        "function getAdmin() view returns (address)",
        "function getGlobalBaseTokenURI() view returns (string)",
        "function getGlobalTokenSymbol() view returns (string)",
        "function globalBaseTokenURI() view returns (string)",
        "function globalTokenSymbol() view returns (string)",
        "function grossNetworkProduct() view returns (uint256)",
        "function initialize(address _unlockOwner)",
        "function initializeProxyAdmin()",
        "function isOwner() view returns (bool)",
        "function locks(address) view returns (bool deployed,uint256 totalSales,uint256 yieldedDiscountTokens)",
        "function networkBaseFee() view returns (uint256)",
        "function owner() view returns (address)",
        "function postLockUpgrade()",
        "function protocolFee() view returns (uint256)",
        "function proxyAdminAddress() view returns (address)",
        "function publicLockAddress() view returns (address)",
        "function publicLockImpls(uint16 _version) view returns (address)",
        "function publicLockLatestVersion() view returns (uint16)",
        "function publicLockVersions(address _impl) view returns (uint16)",
        "function recordConsumedDiscount(uint256,uint256) view",
        "function recordKeyPurchase(uint256 _value,address _referrer)",
        "function removeLock(address lock)",
        "function renounceOwnership()",
        "function resetTrackedValue(uint256 _grossNetworkProduct,uint256 _totalDiscountGranted)",
        "function setLockTemplate(address _publicLockAddress)",
        "function setOracle(address _tokenAddress,address _oracleAddress)",
        "function setProtocolFee(uint256 _protocolFee)",
        "function totalDiscountGranted() view returns (uint256)",
        "function transferOwnership(address newOwner)",
        "function transferTokens(address token,address to,uint256 amount)",
        "function udt() view returns (address)",
        "function uniswapOracles(address) view returns (address)",
        "function unlockVersion() pure returns (uint16)",
        "function upgradeLock(address lockAddress,uint16 version) returns (address)",
        "function weth() view returns (address)"
      ],
      "bytecodeHash": "0x461d3c895d16600884d7cf2a9d05727c467e59a93d606cff28a33e2d76d8fc0b"
    },
    "v13": {
      "contractName": "UnlockV13",
      "abi": [
        "error Unlock__ALREADY_DEPLOYED()",
        "error Unlock__INVALID_AMOUNT()",
        "error Unlock__INVALID_TOKEN()",
        "error Unlock__MANAGER_ONLY()",
        "error Unlock__MISSING_LOCK(address lockAddress)",
        "error Unlock__MISSING_LOCK_TEMPLATE()",
        "error Unlock__MISSING_PROXY_ADMIN()",
        "error Unlock__MISSING_TEMPLATE()",
        "error Unlock__VERSION_TOO_HIGH()",
        "event ConfigUnlock (address udt,address weth,uint256 estimatedGasForPurchase,string globalTokenSymbol,string globalTokenURI,uint256 chainId)",
        "event GNPChanged (uint256 grossNetworkProduct,uint256 _valueInETH,address tokenAddress,uint256 value,address lockAddress)",
        "event LockUpgraded (address lockAddress,uint16 version)",
        "event NewLock (address indexed lockOwner,address indexed newLockAddress)",
        "event OwnershipTransferred (address indexed previousOwner,address indexed newOwner)",
        "event ResetTrackedValue (uint256 grossNetworkProduct,uint256 totalDiscountGranted)",
        "event SetLockTemplate (address publicLockAddress)",
        "event SwapBurnerChanged (address indexed oldAddress,address indexed newAddress)",
        "event UnlockTemplateAdded (address indexed impl,uint16 indexed version)",
        "function __initializeOwnable(address sender)",
        "function addLockTemplate(address impl,uint16 version)",
        "function chainId() view returns (uint256)",
        "function computeAvailableDiscountFor(address,uint256) pure returns (uint256 discount,uint256 tokens)",
        "function configUnlock(address _udt,address _weth,uint256 _estimatedGasForPurchase,string _symbol,string _URI,uint256 _chainId)",
        "function createLock(uint256 _expirationDuration,address _tokenAddress,uint256 _keyPrice,uint256 _maxNumberOfKeys,string _lockName,bytes12) returns (address)",
        "function createUpgradeableLock(bytes data) returns (address)",
        "function createUpgradeableLockAtVersion(bytes data,uint16 _lockVersion) returns (address)",
        "function estimatedGasForPurchase() view returns (uint256)",
        "function getAdmin() view returns (address)",
        "function getGlobalBaseTokenURI() view returns (string)",
        "function getGlobalTokenSymbol() view returns (string)",
        "function globalBaseTokenURI() view returns (string)",
        "function globalTokenSymbol() view returns (string)",
        "function grossNetworkProduct() view returns (uint256)",
        "function initialize(address _unlockOwner)",
        "function initializeProxyAdmin()",
        "function isOwner() view returns (bool)",
        "function locks(address) view returns (bool deployed,uint256 totalSales,uint256 yieldedDiscountTokens)",
        "function networkBaseFee() view returns (uint256)",
        "function owner() view returns (address)",
        "function postLockUpgrade()",
        "function protocolFee() view returns (uint256)",
        "function proxyAdminAddress() view returns (address)",
        "function publicLockAddress() view returns (address)",
        "function publicLockImpls(uint16 _version) view returns (address)",
        "function publicLockLatestVersion() view returns (uint16)",
        "function publicLockVersions(address _impl) view returns (uint16)",
        "function recordConsumedDiscount(uint256,uint256) view",
        "function recordKeyPurchase(uint256 _value,address _referrer)",
        "function removeLock(address lock)",
        "function renounceOwnership()",
        "function resetTrackedValue(uint256 _grossNetworkProduct,uint256 _totalDiscountGranted)",
        "function setLockTemplate(address _publicLockAddress)",
        "function setOracle(address _tokenAddress,address _oracleAddress)",
        "function setProtocolFee(uint256 _protocolFee)",
        "function setSwapBurner(address _swapBurnerAddress)",
        "function swapAndBurn(address token,uint256 amount,uint24 poolFee)",
        "function swapBurnerAddress() view returns (address)",
        "function totalDiscountGranted() view returns (uint256)",
        "function transferOwnership(address newOwner)",
        "function transferTokens(address token,address to,uint256 amount)",
        "function udt() view returns (address)",
        "function uniswapOracles(address) view returns (address)",
        "function unlockVersion() pure returns (uint16)",
        "function upgradeLock(address lockAddress,uint16 version) returns (address)",
        "function weth() view returns (address)"
      ],
      "bytecodeHash": "0x0d3fbc5f5f25dc446aec98818fe223bfd976aa4e2968d7613935aabc3cb862f1"
    }
  }
};
var abis_default = abis;

// src/PublicLock/v9/addKeyGranter.js
async function addKeyGranter_default({ lockAddress, keyGranter }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.addKeyGranter(keyGranter);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return true;
}

// src/PublicLock/v9/addLockManager.js
async function addLockManager_default({ lockAddress, userAddress }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.addLockManager(userAddress);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/constants.ts
import { ethers as ethers2 } from "ethers";
var MAX_UINT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
var ETHERS_MAX_UINT = BigInt(ethers2.MaxUint256);
var UNLIMITED_KEYS_COUNT = -1;
var ZERO = ethers2.ZeroAddress;
var DEFAULT_TOKEN_DECIMALS = 18;

// src/erc20.ts
import { ethers as ethers3 } from "ethers";

// src/erc20abi.ts
var erc20abi = [
  "function name() public view returns (string)",
  "function version() public view returns (string)",
  "function totalSupply() public view returns (uint)",
  "function balanceOf(address tokenOwner) public view returns (uint balance)",
  "function decimals() public view returns (uint decimals)",
  "function allowance(address tokenOwner, address spender) public view returns (uint remaining)",
  "function transfer(address to, uint tokens) public returns (bool success)",
  "function approve(address spender, uint tokens) public returns (bool success)",
  "function transferFrom(address from, address to, uint tokens) public returns (bool success)",
  "function symbol() public view returns (string)",
  "event Transfer(address indexed from, address indexed to, uint tokens)",
  "event Approval(address indexed tokenOwner, address indexed spender, uint tokens)"
];
var erc20abi_default = erc20abi;

// src/erc20.ts
var SAI_ADDRESS = "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359".toLowerCase();
var TransferWithAuthorizationTypes = {
  TransferWithAuthorization: [
    { name: "from", type: "address" },
    { name: "to", type: "address" },
    { name: "value", type: "uint256" },
    { name: "validAfter", type: "uint256" },
    { name: "validBefore", type: "uint256" },
    { name: "nonce", type: "bytes32" }
  ]
};
async function getErc20BalanceForAddress(erc20ContractAddress, address, provider) {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, provider);
  const balance = await contract.balanceOf(address);
  return utils_default.hexToNumberString(balance);
}
async function getErc20Decimals(erc20ContractAddress, provider) {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, provider);
  let decimals;
  try {
    decimals = await contract.decimals();
  } catch (e) {
    console.error(e);
    return DEFAULT_TOKEN_DECIMALS;
  }
  return utils_default.toNumber(decimals);
}
async function getErc20TokenSymbol(erc20ContractAddress, provider) {
  if (erc20ContractAddress.toLowerCase() === SAI_ADDRESS) {
    return "SAI";
  }
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, provider);
  let symbol;
  try {
    symbol = await contract.symbol();
  } catch (e) {
    return null;
  }
  return symbol;
}
async function getAllowance(erc20ContractAddress, lockContractAddress, provider, spenderAddress) {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, provider);
  let amount = BigInt(0);
  try {
    amount = await contract.allowance(spenderAddress, lockContractAddress);
  } catch (e) {
  }
  return amount;
}
async function approveTransfer(erc20ContractAddress, lockContractAddress, value, provider, signer) {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, signer);
  return contract.approve(lockContractAddress, value);
}
var getDomain = async (chainId, erc20ContractAddress, provider) => {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, provider);
  let version = "1";
  try {
    version = await contract.version();
  } catch (error) {
    console.error(
      `We could not retrieve the version of ${erc20ContractAddress} using the version() method. Defaulting to ${version}`
    );
  }
  const name2 = await contract.name();
  if (chainId === 137n) {
    return {
      name: name2,
      version,
      salt: ethers3.zeroPadValue(
        ethers3.getBytes(ethers3.solidityPackedKeccak256(["uint"], [137n])),
        32
      ),
      verifyingContract: ethers3.getAddress(erc20ContractAddress)
    };
  }
  return {
    name: name2,
    version,
    chainId,
    verifyingContract: ethers3.getAddress(erc20ContractAddress)
  };
};
async function signTransferAuthorization(erc20ContractAddress, message, provider, signer) {
  const { chainId } = await provider.getNetwork();
  const domain = await getDomain(chainId, erc20ContractAddress, provider);
  return signer.signTypedData(domain, TransferWithAuthorizationTypes, message);
}
async function recoverTransferAuthorization(erc20ContractAddress, message, chainId, signature, provider) {
  const domain = await getDomain(chainId, erc20ContractAddress, provider);
  return ethers3.verifyTypedData(
    domain,
    TransferWithAuthorizationTypes,
    message,
    signature
  );
}
async function transferWithAuthorization(erc20ContractAddress, message, signature, signer) {
  const contract = new ethers3.Contract(erc20ContractAddress, erc20abi_default, signer);
  const { v, r, s } = ethers3.Signature.from(signature);
  return contract.transferWithAuthorization(
    message.from,
    message.to,
    message.value,
    message.validAfter,
    message.validBefore,
    message.nonce,
    v,
    r,
    s
  );
}

// src/PublicLock/v9/approveBeneficiary.js
async function approveBeneficiary_default({ lockAddress, spender, amount = "0", decimals, erc20Address }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address || erc20Address !== ZERO) {
    erc20Address = await lockContract.tokenAddress();
  }
  if (erc20Address === ZERO) {
    throw new Error("Lock can only set approval for ERC20");
  }
  let actualAmount;
  if (amount !== MAX_UINT) {
    if (decimals == null) {
      decimals = await getErc20Decimals(erc20Address, this.provider);
    }
    actualAmount = utils_default.toDecimal(amount, decimals);
  } else {
    actualAmount = amount;
  }
  const transactionPromise = lockContract.approveBeneficiary(
    spender,
    actualAmount
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/cancelAndRefund.js
async function cancelAndRefund_default({ lockAddress, tokenId }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!tokenId) {
    const owner = await this.signer.getAddress();
    tokenId = await lockContract.getTokenIdFor(owner);
  }
  const transactionPromise = lockContract.cancelAndRefund(tokenId);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/expireAndRefundFor.js
async function expireAndRefundFor_default({ lockAddress, keyOwner, amount = "0", decimals, erc20Address }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address || erc20Address !== ZERO) {
    erc20Address = await lockContract.tokenAddress();
  }
  if (decimals == null) {
    if (erc20Address !== ZERO) {
      decimals = await getErc20Decimals(erc20Address, this.provider);
    } else {
      decimals = 18;
    }
  }
  const actualAmount = utils_default.toDecimal(amount, decimals);
  const transactionPromise = lockContract.expireAndRefundFor(
    keyOwner,
    actualAmount
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/getCancelAndRefundValueFor.js
async function getCancelAndRefundValueFor({ lockAddress, owner, tokenAddress }, transactionOptions = {}, provider) {
  const lockContract = await this.getLockContract(lockAddress, provider);
  const value = await lockContract.getCancelAndRefundValueFor(owner);
  let refundValue;
  if (tokenAddress === ZERO) {
    refundValue = utils_default.fromWei(value, "ether");
  } else {
    refundValue = await getErc20Decimals(tokenAddress, this.provider);
  }
  return refundValue;
}
var getCancelAndRefundValueFor_default = getCancelAndRefundValueFor;

// src/PublicLock/v9/getKeyExpirationByLockForOwner.js
async function getKeyExpirationByLockForOwner_default(lockAddress, owner, network) {
  const lockContract = await this.getLockContract(
    lockAddress,
    this.providerForNetwork(network)
  );
  if (await lockContract.publicLockVersion() > 10) {
    throw new Error("Only available until Lock v10");
  }
  try {
    const expiration = await lockContract.keyExpirationTimestampFor(owner);
    if (expiration == "3963877391197344453575983046348115674221700746820753546331534351508065746944") {
      return 0;
    }
    if (expiration === ETHERS_MAX_UINT) {
      return -1;
    }
    return parseInt(expiration, 10);
  } catch (error) {
    return 0;
  }
}

// src/PublicLock/utils/parseLockGetters.js
var attributes = {
  name: (x) => x,
  keyPrice: (x) => x,
  expirationDuration: (value) => {
    if (utils_default.isInfiniteDuration(value)) {
      return -1;
    }
    return parseInt(value, 10);
  },
  maxNumberOfKeys: (value) => {
    if (utils_default.isInfiniteKeys(value)) {
      return UNLIMITED_KEYS_COUNT;
    }
    return utils_default.toNumber(value);
  },
  maxKeysPerAddress: parseInt,
  beneficiary: (x) => x,
  totalSupply: parseInt,
  tokenAddress: (x) => x,
  publicLockVersion: parseInt,
  unlockProtocol: (x) => x
};
async function parseLockGetters_default(address, provider, { fields = [] } = {}) {
  const fieldItems = fields.map((f) => f.toLowerCase().trim()).concat(["unlockprotocol"]);
  const network = await provider.getNetwork();
  const networkConfig = this.networks?.[network.chainId];
  const contract = await this.getLockContract(address, provider);
  const getBlockNumber = async () => {
    const blockNumber = await provider.getBlockNumber();
    update.asOf = blockNumber;
  };
  const update = {};
  const constantPromises = Object.keys(attributes).filter((attribute) => contract.interface.getFunction(attribute)).map(async (attribute) => {
    if (fields.length && !fieldItems.includes(attribute.toLowerCase().trim())) {
      return;
    }
    const result = await contract.getFunction(attribute)();
    update[attribute] = attributes[attribute](result);
  });
  constantPromises.push(getBlockNumber());
  await Promise.all(constantPromises);
  if (update.tokenAddress) {
    if (update.tokenAddress === ZERO) {
      if (update.keyPrice) {
        update.keyPrice = utils_default.fromWei(update.keyPrice, "ether");
      }
      const balance = await provider.getBalance(address);
      update.balance = utils_default.fromWei(balance, "ether");
      update.currencySymbol = networkConfig?.nativeCurrency?.symbol;
      update.currencyDecimals = networkConfig?.nativeCurrency?.decimals;
      update.currencyName = networkConfig?.nativeCurrency?.name;
    } else {
      const [erc20Decimals, erc20Balance, erc20Symbol] = await Promise.all([
        getErc20Decimals(update.tokenAddress, provider),
        getErc20BalanceForAddress(update.tokenAddress, address, provider),
        getErc20TokenSymbol(update.tokenAddress, provider)
      ]);
      if (update.keyPrice) {
        update.keyPrice = utils_default.fromDecimal(update.keyPrice, erc20Decimals);
      }
      update.balance = utils_default.fromDecimal(erc20Balance, erc20Decimals);
      update.currencySymbol = erc20Symbol;
      update.currencyDecimals = erc20Decimals;
      update.currencyName = erc20Symbol;
    }
  }
  update.outstandingKeys = update.totalSupply;
  delete update.totalSupply;
  if (update.tokenAddress === ZERO) {
    update.currencyContractAddress = null;
  } else {
    update.currencyContractAddress = update.tokenAddress;
  }
  delete update.tokenAddress;
  if (update.unlockProtocol) {
    update.unlockContractAddress = update.unlockProtocol;
    delete update.unlockProtocol;
  }
  return update;
}

// src/PublicLock/v9/getLock.js
async function getLock_default(address, provider, options = {}) {
  const update = await parseLockGetters_default.bind(this)(address, provider, options);
  return update;
}

// src/PublicLock/v9/getTokenIdForOwner.js
async function getTokenIdForOwner_default(lockAddress, owner, network) {
  const lockContract = await this.getLockContract(
    lockAddress,
    this.providerForNetwork(network)
  );
  try {
    const tokenId = await lockContract.getTokenIdFor(owner);
    return parseInt(tokenId, 10);
  } catch (error) {
    return 0;
  }
}

// src/PublicLock/v9/grantKeys.js
async function grantKeys_default({ lockAddress, recipients, expirations, keyManagers }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!expirations?.length) {
    const duration = await lockContract.expirationDuration();
    if (ETHERS_MAX_UINT === duration) {
      expirations = new Array(recipients.length).fill(ETHERS_MAX_UINT);
    } else {
      expirations = new Array(recipients.length).fill(
        BigInt(Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3)) + duration
      );
    }
  }
  if (!keyManagers?.length) {
    const signer = this.signer;
    const manager = await signer.getAddress();
    keyManagers = new Array(recipients.length).fill(manager);
  }
  if (recipients.length !== expirations.length) {
    throw new Error(
      "Number of recipients don't match the number of expirations provided"
    );
  }
  if (recipients.length !== keyManagers.length) {
    throw new Error(
      "Number of recipients don't match the number of keyManagers provided"
    );
  }
  const transactionPromise = lockContract.grantKeys(
    recipients,
    expirations,
    keyManagers,
    transactionOptions || {}
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  const parser = lockContract.interface;
  const transferEvents = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  }).filter(({ fragment }) => fragment && fragment.name === "Transfer");
  if (transferEvents.length) {
    return transferEvents.map((item) => {
      return {
        id: item.args.tokenId.toString(),
        owner: item.args.to.toString()
      };
    });
  }
  return null;
}

// src/PublicLock/v9/grantKey.js
async function grantKey_default({ lockAddress, recipient, expiration, keyManager }, transactionOptions = {}, callback) {
  const events = await grantKeys_default.bind(this)(
    {
      lockAddress,
      recipients: [recipient],
      expirations: expiration ? [expiration] : [],
      keyManagers: keyManager ? [keyManager] : []
    },
    transactionOptions,
    callback
  );
  if (events?.length) {
    return events[0];
  }
  return null;
}

// src/PublicLock/v9/initializeTemplate.js
async function initializeTemplate_default({ templateAddress }, transactionOptions = {}, callback) {
  const owner = await this.signer.getAddress();
  const expirationDuration = 0;
  const tokenAddress = ZERO;
  const keyPrice = 0;
  const maxNumberOfKeys = 0;
  const lockName = "Public Lock Template";
  const contract = await this.getLockContract(templateAddress);
  const initializeTransaction = await contract.initialize(
    owner,
    expirationDuration,
    tokenAddress,
    keyPrice,
    maxNumberOfKeys,
    lockName
  );
  if (callback) {
    callback(null, initializeTransaction.hash);
  }
  await this.provider.waitForTransaction(initializeTransaction.hash);
}

// src/PublicLock/v9/isKeyGranter.js
async function isKeyGranter_default(lockAddress, address, provider) {
  const lockContract = await this.getLockContract(lockAddress, provider);
  return lockContract.isKeyGranter(address);
}

// src/PublicLock/v9/isLockManager.js
async function isLockManager_default(lockAddres, userAddress, provider) {
  const lockContract = await this.getLockContract(lockAddres, provider);
  return lockContract.isLockManager(userAddress);
}

// src/PublicLock/v9/keyManagerOf.js
async function keyManagerOf_default(lockAddres, tokenId, provider) {
  const lockContract = await this.getLockContract(lockAddres, provider);
  return lockContract.keyManagerOf(tokenId);
}

// src/PublicLock/utils/approveAllowance.js
async function approveAllowance({
  erc20Address,
  address,
  totalAmountToApprove
}) {
  if (erc20Address && erc20Address !== ZERO) {
    const approvedAmount = await getAllowance(
      erc20Address,
      address,
      this.provider,
      this.signer.getAddress()
    );
    if (!approvedAmount || utils_default.bigNumberify(approvedAmount) < totalAmountToApprove) {
      await (await approveTransfer(
        erc20Address,
        address,
        totalAmountToApprove,
        this.provider,
        this.signer
      )).wait();
    }
  }
}

// src/PublicLock/utils/formatKeyPrice.js
var formatKeyPrice_default = async (keyPrice, erc20Address, decimals, provider) => {
  let actualAmount;
  if (decimals !== void 0 && decimals !== null) {
    actualAmount = utils_default.toDecimal(keyPrice, decimals);
  } else {
    if (erc20Address && erc20Address !== ZERO) {
      decimals = await getErc20Decimals(erc20Address, provider);
    } else {
      decimals = 18;
    }
    actualAmount = utils_default.toDecimal(keyPrice, decimals);
  }
  return actualAmount;
};

// src/PublicLock/v9/purchaseKey.js
async function purchaseKey_default({
  lockAddress,
  owner,
  keyManager,
  keyPrice,
  erc20Address,
  decimals,
  referrer,
  data
}, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!owner) {
    owner = await this.signer.getAddress();
  }
  if (!referrer) {
    referrer = ZERO;
  }
  if (!keyManager) {
    keyManager = ZERO;
  }
  if (!data) {
    data = "0x";
  }
  if (!erc20Address) {
    erc20Address = await lockContract.tokenAddress();
  }
  let actualAmount;
  if (!keyPrice) {
    actualAmount = await lockContract.keyPrice();
  } else {
    actualAmount = await formatKeyPrice_default(
      keyPrice,
      erc20Address,
      decimals,
      this.provider
    );
  }
  const purchaseArgs = [actualAmount, owner, referrer, keyManager, data];
  const callData = lockContract.interface.encodeFunctionData(
    "purchase",
    purchaseArgs
  );
  if (!erc20Address || erc20Address === ZERO) {
    transactionOptions.value = actualAmount;
  }
  const approvalOptions = {
    erc20Address,
    totalAmountToApprove: actualAmount,
    address: lockAddress
  };
  if (approvalOptions.erc20Address && approvalOptions.erc20Address !== ZERO) {
    await approveAllowance.bind(this)(approvalOptions);
  }
  if (!transactionOptions.gasLimit) {
    try {
      const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = await this.provider.getFeeData();
      if (maxFeePerGas && maxPriorityFeePerGas) {
        transactionOptions.maxFeePerGas = maxFeePerGas;
        transactionOptions.maxPriorityFeePerGas = maxPriorityFeePerGas;
      } else {
        transactionOptions.gasPrice = gasPrice;
      }
      const gasLimitPromise = lockContract.purchase.estimateGas(
        actualAmount,
        owner,
        referrer,
        keyManager,
        data,
        transactionOptions
      );
      const gasLimit = await gasLimitPromise;
      delete transactionOptions.maxFeePerGas;
      delete transactionOptions.maxPriorityFeePerGas;
      delete transactionOptions.gasPrice;
      transactionOptions.gasLimit = gasLimit * 13n / 10n;
    } catch (error) {
      console.error(
        "We could not estimate gas ourselves. Let wallet do it.",
        error
      );
      delete transactionOptions.maxFeePerGas;
      delete transactionOptions.maxPriorityFeePerGas;
      delete transactionOptions.gasPrice;
    }
  }
  const transactionRequestPromise = lockContract.purchase(
    actualAmount,
    owner,
    referrer,
    keyManager,
    data,
    transactionOptions
  );
  const hash = await this._handleMethodCall(transactionRequestPromise);
  if (callback) {
    callback(null, hash, await transactionRequestPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  if (receipt.status === 0) {
    throw new Error("Transaction failed");
  }
  const parser = lockContract.interface;
  const transferEvent = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  }).find((evt) => evt && evt?.fragment?.name === "Transfer");
  if (transferEvent) {
    return transferEvent.args.tokenId.toString();
  }
  return null;
}

// src/PublicLock/utils/multiplePurchaseWrapper.js
async function multiplePurchaseWrapper_default(purchaseKey, {
  lockAddress,
  erc20Address,
  decimals,
  owners = [],
  keyManagers = [],
  keyPrices = [],
  referrers = [],
  data = ["0x"]
}, transactionOptions = {}, callback) {
  if (!erc20Address) {
    const lockContract = await this.getLockContract(lockAddress);
    erc20Address = await lockContract.tokenAddress();
    if (erc20Address !== ZERO) {
      const getPrice = async (price) => !price ? await lockContract.keyPrice() : await formatKeyPrice_default(price, erc20Address, decimals, this.provider);
      const prices = await Promise.all(
        (keyPrices.length === owners.length ? keyPrices : Array(owners.length).fill(null)).map((kp) => getPrice(kp))
      );
      const totalPrice = prices.reduce(
        (total, kp) => total + kp,
        utils_default.bigNumberify(0)
      );
      const approvedAmount = await getAllowance(
        erc20Address,
        lockAddress,
        this.provider,
        this.signer.getAddress()
      );
      if (!approvedAmount || approvedAmount < totalPrice) {
        await (await approveTransfer(
          erc20Address,
          lockAddress,
          totalPrice,
          this.provider,
          this.signer
        )).wait();
      }
    }
  }
  return Promise.all(
    owners.map(
      async (owner, i) => purchaseKey.bind(this)(
        {
          lockAddress,
          owner,
          keyManager: keyManagers[i],
          keyPrice: keyPrices[i],
          referrer: referrers[i],
          data: data[i],
          erc20Address,
          decimals
        },
        transactionOptions,
        callback
      )
    )
  );
}

// src/PublicLock/v9/purchaseKeys.js
async function purchaseKeys_default(params, transactionOptions = {}, callback) {
  return await multiplePurchaseWrapper_default.bind(this)(
    purchaseKey_default,
    params,
    transactionOptions,
    callback
  );
}

// src/PublicLock/v9/removeKeyGranter.js
async function removeKeyGranter_default({ lockAddress, keyGranter }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.revokeKeyGranter(keyGranter);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return true;
}

// src/PublicLock/v9/renounceLockManager.js
async function renounceLockManager_default({ lockAddress }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.renounceLockManager();
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/setBaseTokenURI.js
async function setBaseTokenURI_default({ lockAddress, baseTokenURI }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.setBaseTokenURI(baseTokenURI);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return baseTokenURI;
}

// src/PublicLock/v9/setEventHooks.js
async function setEventHooks_default({
  lockAddress,
  keyPurchase = ZERO,
  keyCancel = ZERO,
  validKey = ZERO,
  tokenURI = ZERO
}, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setEventHooks(
    keyPurchase,
    keyCancel,
    validKey,
    tokenURI
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/setExpirationDuration.js
async function setExpirationDuration({ lockAddress, expirationDuration }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setExpirationDuration(expirationDuration);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}
var setExpirationDuration_default = setExpirationDuration;

// ../networks/dist/index.mjs
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name2 in all)
    __defProp(target, name2, { get: all[name2], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var require_unlockTypes = __commonJS({
  "../types/dist/src/types/unlockTypes.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.KeyStatus = exports.HooksName = exports.HookType = exports.TransactionStatus = exports.TransactionType = void 0;
    var TransactionType;
    (function(TransactionType2) {
      TransactionType2["LOCK_CREATION"] = "LOCK_CREATION";
      TransactionType2["KEY_PURCHASE"] = "KEY_PURCHASE";
      TransactionType2["WITHDRAWAL"] = "WITHDRAWAL";
      TransactionType2["UPDATE_KEY_PRICE"] = "UPDATE_KEY_PRICE";
    })(TransactionType || (exports.TransactionType = TransactionType = {}));
    var TransactionStatus;
    (function(TransactionStatus2) {
      TransactionStatus2["SUBMITTED"] = "submitted";
      TransactionStatus2["PENDING"] = "pending";
      TransactionStatus2["MINED"] = "mined";
      TransactionStatus2["STALE"] = "stale";
      TransactionStatus2["FAILED"] = "failed";
      TransactionStatus2["NONE"] = "";
    })(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
    var HookType13;
    (function(HookType14) {
      HookType14["CUSTOM_CONTRACT"] = "CUSTOM_CONTRACT";
      HookType14["PASSWORD"] = "PASSWORD";
      HookType14["PROMOCODE"] = "PROMOCODE";
      HookType14["PROMO_CODE_CAPPED"] = "PROMO_CODE_CAPPED";
      HookType14["PASSWORD_CAPPED"] = "PASSWORD_CAPPED";
      HookType14["CAPTCHA"] = "CAPTCHA";
      HookType14["GUILD"] = "GUILD";
      HookType14["GITCOIN"] = "GITCOIN";
      HookType14["ADVANCED_TOKEN_URI"] = "ADVANCED_TOKEN_URI";
    })(HookType13 || (exports.HookType = HookType13 = {}));
    exports.HooksName = [
      "onKeyPurchaseHook",
      "onKeyCancelHook",
      "onValidKeyHook",
      "onTokenURIHook",
      "onKeyTransferHook",
      "onKeyExtendHook",
      "onKeyGrantHook"
    ];
    var KeyStatus;
    (function(KeyStatus2) {
      KeyStatus2["NONE"] = "none";
      KeyStatus2["CONFIRMING"] = "confirming";
      KeyStatus2["CONFIRMED"] = "confirmed";
      KeyStatus2["EXPIRED"] = "expired";
      KeyStatus2["VALID"] = "valid";
      KeyStatus2["SUBMITTED"] = "submitted";
      KeyStatus2["PENDING"] = "pending";
      KeyStatus2["FAILED"] = "failed";
    })(KeyStatus || (exports.KeyStatus = KeyStatus = {}));
  }
});
var require_src = __commonJS({
  "../types/dist/src/index.js"(exports) {
    "use strict";
    var __createBinding = exports && exports.__createBinding || (Object.create ? function(o, m, k, k2) {
      if (k2 === void 0) k2 = k;
      var desc = Object.getOwnPropertyDescriptor(m, k);
      if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
        desc = { enumerable: true, get: function() {
          return m[k];
        } };
      }
      Object.defineProperty(o, k2, desc);
    } : function(o, m, k, k2) {
      if (k2 === void 0) k2 = k;
      o[k2] = m[k];
    });
    var __exportStar = exports && exports.__exportStar || function(m, exports2) {
      for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports2, p)) __createBinding(exports2, m, p);
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    __exportStar(require_unlockTypes(), exports);
  }
});
var networks_exports = {};
__export(networks_exports, {
  arbitrum: () => arbitrum,
  avalanche: () => avalanche,
  base: () => base,
  baseSepolia: () => baseSepolia,
  bsc: () => bsc,
  celo: () => celo,
  gnosis: () => gnosis,
  linea: () => linea,
  mainnet: () => mainnet,
  optimism: () => optimism,
  polygon: () => polygon,
  scroll: () => scroll,
  sepolia: () => sepolia,
  zkevm: () => zkevm,
  zksync: () => zksync
});
var import_types = __toESM(require_src());
var mainnet = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "ethereum",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0x8898B472C54c31894e3B9bb83cEA802a5d0e63C6",
      domainId: 6648936,
      modules: {
        connextMod: "0xAB6A5080e569A269D8cB54fdD00312A2c4c3a3aa",
        delayMod: "0x8f05058d05C8167132f07aAA5130Ed3F0D78d5aE"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "The original and most secure EVM network. ",
  explorer: {
    name: "Etherscan",
    urls: {
      address: (address) => `https://etherscan.io/address/${address}`,
      base: "https://etherscan.io/",
      token: (address, holder) => `https://etherscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://etherscan.io/tx/${hash}`
    }
  },
  featured: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x936Ed3E71b5990bC9A94074835D08C6ca7bbFad0",
        id: import_types.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xaF96721f8ffc136e4C170446E68Dc6744B6Ee4f4",
        id: import_types.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x1d8DD27432cC0aCB93B93c4486F46b67E2208359",
        id: import_types.HookType.GUILD,
        name: "Guild"
      }
    ]
  },
  id: 1,
  isTestNetwork: false,
  keyManagerAddress: "0x9A1f43090307034DBFBE2ba20320Ce815ff046D4",
  maxFreeClaimCost: 1,
  multisig: "0x9168EABE624e9515f3836bA1716EC1DDd4C461D4",
  name: "Ethereum",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/${lockAddress}`,
    profileUrl: (address) => `https://opensea.io/${address}`,
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/${lockAddress}/${tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 7120795,
      unlockAddress: "0x3d5409CcE1d45233dE1D4eBDEe74b8E004abDD13"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/1",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://cloudflare-eth.com/v1/mainnet",
  startBlock: 16989e3,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/1",
    graphId: "CTj3qyHTnQuTD16RkmV1BK6UmYPADwUD7WNNLEvQuTv9",
    studioName: "unlock-protocol-mainnet"
  },
  tokens: [
    {
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      decimals: 18,
      name: "Matic Token",
      symbol: "MATIC"
    },
    {
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      decimals: 18,
      name: "Basic Attention Token",
      symbol: "BAT"
    },
    {
      address: "0xd7C1EB0fe4A30d3B2a846C04aa6300888f087A5F",
      decimals: 18,
      name: "POINTS",
      symbol: "POINTS"
    },
    {
      address: "0x58b6A8A3302369DAEc383334672404Ee733aB239",
      decimals: 18,
      name: "Livepeer Token",
      symbol: "LPT"
    },
    {
      address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      decimals: 18,
      name: "SHIBA INU",
      symbol: "SHIB"
    },
    {
      address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      decimals: 18,
      name: "ChainLink Token",
      symbol: "LINK"
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      decimals: 18,
      name: "Uniswap",
      symbol: "UNI"
    },
    {
      address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    {
      address: "0x90DE74265a416e1393A450752175AED98fe11517",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x92C9b3A4FFD7D2046132732FedC9f9f25E316F0B",
      3e3: "0x584c5af22DB79a13F4Fb45c66E0ff2311D58d9B2",
      500: "0x2e5F6B31d100C527B782e26953D9509C591aC41d"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    subgraph: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
    universalRouterAddress: "0xEf1c6E67703c7BD7107eed8303Fbe6EC2554BF6B"
  },
  unlockAddress: "0xe79B93f8E22676774F2A8dAd469175ebd00029FA",
  unlockDaoToken: {
    address: "0x90DE74265a416e1393A450752175AED98fe11517"
  },
  url: "https://ethereum.org/en/"
  // universalCard: {
  //   cardPurchaserAddress: '0x49814dd8a03594bE78a18455CC4Df3876ecFbD69',
  //   stripeDestinationNetwork: 'ethereum',
  //   stripeDestinationCurrency: 'usdc',
  // },
};
var import_types2 = __toESM(require_src());
var gnosis = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "xdai",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0x5bB83e95f63217CDa6aE3D181BA580Ef377D2109",
      domainId: 6778479,
      modules: {
        connextMod: "0xdFB9328cF62e3525D355581dE88AeAa330879D12",
        delayMod: "0x6E74DC46EbF2cDB75B72Ab1dCAe3C98c7E9d28a1"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Gnosis Chain is one of the first Ethereum sidechains and has stayed true to its values.",
  explorer: {
    name: "Blockscout",
    urls: {
      address: (address) => `https://gnosisscan.io/address/${address}/transactions`,
      base: `https://gnosisscan.io/`,
      token: (address, holder) => `https://gnosisscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://gnosisscan.io/tx/${hash}`
    }
  },
  featured: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x927D68eAE936Ec0111f01Fc4Ddd9cC57DB3f0Af2",
        id: import_types2.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xFb0657eAE55A4dd3E2317C9eCB311bA5Ecc62C9C",
        id: import_types2.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xe20738d9798B5B5801aEEFDB81d80Fcce3a3Aa95",
        id: import_types2.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x5ff4d5733Cc2b0A069cFF409eCEad3C5C71Ee346",
        id: import_types2.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x111C61BD66a8c4f142e452b9087Dae5893C5C3cF",
        id: import_types2.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0xd6129cAC45a16187F4D09Dd69C512F68F0f2B371",
        id: import_types2.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x936Ed3E71b5990bC9A94074835D08C6ca7bbFad0",
        id: import_types2.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 100,
  isTestNetwork: false,
  keyManagerAddress: "0xBa81C9379AC1221BF8C100800dD0B0b0b048ba14",
  kickbackAddress: "0x247a38358c4d99A29091C1cEadfb8a54B783D438",
  maxFreeClaimCost: 100,
  multisig: "0xfAC611a5b5a578628C28F77cEBDDB8C6159Ae79D",
  name: "Gnosis Chain",
  nativeCurrency: {
    coinbase: "DAI",
    coingecko: "xdai",
    decimals: 18,
    name: "xDAI",
    symbol: "xDAI"
  },
  previousDeploys: [
    {
      startBlock: 14521200,
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/100",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.gnosischain.com",
  startBlock: 19338700,
  // This is used in llama pricing API so can't rename.
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/100",
    graphId: "2Pr7V4721iZj5hRgLmuganYCTRee6fqqfftLCFCd72wG",
    networkName: "gnosis",
    studioName: "unlock-protocol-gnosis"
  },
  tokens: [
    {
      address: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83",
      decimals: 6,
      featured: true,
      name: "USD//C on xDai",
      symbol: "USDC"
    },
    {
      address: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6",
      decimals: 6,
      featured: true,
      name: "Tether USD on xDai",
      symbol: "USDT"
    },
    {
      address: "0x8C84142c4a716a16a89d0e61707164d6107A9811",
      decimals: 18,
      name: "UDT from Ethereum",
      symbol: "UDT"
    }
  ],
  unlockAddress: "0x1bc53f4303c711cc693F6Ec3477B83703DcB317f",
  unlockDaoToken: {
    address: "0x8C84142c4a716a16a89d0e61707164d6107A9811",
    mainnetBridge: "0x41a4ee2855A7Dc328524babB07d7f505B201133e"
  },
  url: "https://www.gnosis.io/"
};
var import_types3 = __toESM(require_src());
var polygon = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "polygon",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0x11984dc4465481512eb5b777E44061C158CF2259",
      domainId: 1886350457,
      modules: {
        connextMod: "0xa8E0FD3D023B1A253eF52B6169851Ee95eF257bE",
        delayMod: "0x31B8bB0BC5ffEe8e476202e9D97b0b8c31aA767d"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Polygon is a side-chain to build and scale your projects on Ethereum, the world's largest blockchain ecosystem.",
  explorer: {
    name: "Polygonscan",
    urls: {
      address: (address) => `https://polygonscan.com/address/${address}`,
      base: `https://polygonscan.com/`,
      token: (address, holder) => `https://polygonscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://polygonscan.com/tx/${hash}`
    }
  },
  featured: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x9F4AE507d7E91Ab37CF35f792940fE079bd4E24d",
        id: import_types3.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x64bDe27046F915e2BAb6339Ce4f737E34474344d",
        id: import_types3.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xBfF080aB4600554c1e8c390d2e070CF423767B64",
        id: import_types3.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x93E160838c529873cB7565106bBb79a3226FE07A",
        id: import_types3.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x25Ec032F38b87295bA43C825993B9F4E1F4065c9",
        id: import_types3.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xc2D767a87d74d82CD4B290a63E2D703Ff1CDf6b9",
        id: import_types3.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ]
  },
  id: 137,
  isTestNetwork: false,
  keyManagerAddress: "0x7111a1aDfbED501beaAd556Bba7cB6dCa3296aa9",
  kickbackAddress: "0x247a38358c4d99A29091C1cEadfb8a54B783D438",
  maxFreeClaimCost: 100,
  multisig: "0x479f3830fbd715342868BA95E438609BCe443DFB",
  name: "Polygon",
  nativeCurrency: {
    coingecko: "matic-network",
    decimals: 18,
    name: "Wrapped Polygon Ecosystem Token",
    symbol: "WPOL",
    wrapped: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/matic/${lockAddress}`,
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/matic/${lockAddress}/${tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 15714206,
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/137",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://polygon-rpc.com/",
  startBlock: 21986688,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/137",
    graphId: "6UrwdJt18yfk4PGzxyeYdH9hUM8PzWvuLbUTKa3T17PD",
    networkName: "matic",
    studioName: "unlock-protocol-polygon"
  },
  tokens: [
    {
      address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      name: "USD Coin (PoS)",
      symbol: "USDC"
    },
    {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      decimals: 18,
      featured: true,
      name: "(PoS) Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
      featured: true,
      name: "(PoS) Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      decimals: 8,
      featured: true,
      name: "(PoS) Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      decimals: 18,
      name: "Wrapped Polygon Ecosystem Token",
      symbol: "WPOL"
    },
    {
      address: "0xE06Bd4F5aAc8D0aA337D13eC88dB6defC6eAEefE",
      decimals: 18,
      name: "PlanetIX",
      symbol: "IXT"
    },
    {
      address: "0xf7E78d9C4c74df889A83C8C8d6D05BF70fF75876",
      decimals: 18,
      name: "Unlock Discount Token (PoS)",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x8c0AC149FabEeC9b759a43fC7d301B1a1D8DE0d0",
      3e3: "0x86399725a83bB14C47bB5ce8311Ed25378BAa162",
      500: "0xfA3F427d2691ce680f96E6916a9Dac6c9042CBd2"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  universalCard: {
    cardPurchaserAddress: "0xAB355a589CFbBA7a21b91E5B6063bF822dCc0016",
    stripeDestinationCurrency: "usdc",
    stripeDestinationNetwork: "polygon"
  },
  unlockAddress: "0xE8E5cd156f89F7bdB267EabD5C43Af3d5AF2A78f",
  unlockDaoToken: {
    address: "0xf7E78d9C4c74df889A83C8C8d6D05BF70fF75876",
    mainnetBridge: "0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf",
    uniswapV3Pool: "0x8B2b66ec1E6C1895eb408F8b8fcd8cD9510F9115"
  },
  url: "https://polygon.technology/"
};
var import_types4 = __toESM(require_src());
var bsc = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "bsc",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0xCd401c10afa37d641d2F594852DA94C700e4F2CE",
      domainId: 6450786,
      modules: {
        connextMod: "0x36b34e10295cCE69B652eEB5a8046041074515Da",
        delayMod: "0xcf07c951C44731f82E548286C7ebeC576149a49e"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "The best-performing EVM compatible layer 1. Fully compatible tooling for EVM with up to 35 times of capacity.",
  explorer: {
    name: "BscScan",
    urls: {
      address: (address) => `https://bscscan.com/address/${address}`,
      base: `https://bscscan.com/`,
      token: (address, holder) => `https://bscscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://bscscan.com/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
        id: import_types4.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x80E085D7591C61153D876b5171dc25756a7A3254",
        id: import_types4.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC",
        id: import_types4.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x1Bc951F8ed90F6c135F01Fe62CA348F4c3F43D00",
        id: import_types4.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xa2abAeaba0ac658A1DF5517B57e45e857E3137Ad",
        id: import_types4.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x5B6C5a766edBc6c7988108A689C96AfCEa95a2f1",
        id: import_types4.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x15922b77301Df5EA532074e9fb30d115FB6A03fE",
        id: import_types4.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 56,
  isTestNetwork: false,
  keyManagerAddress: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
  maxFreeClaimCost: 1,
  multisig: "0x373D7cbc4F2700719DEa237500c7a154310B0F9B",
  name: "BNB Chain",
  nativeCurrency: {
    coingecko: "binancecoin",
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
    wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/bsc/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/bsc/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 12396e3,
      unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/56",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://bsc-dataseed.binance.org/",
  startBlock: 13079e3,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/56",
    graphId: "25Cx5DaCifBCXNVpZXMmzkafbm6KAmKqdjrPu9dT6yQp",
    studioName: "unlock-protocol-bsc"
  },
  tokens: [
    {
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      decimals: 18,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
      featured: true,
      name: "Ethereum Token",
      symbol: "ETH"
    },
    {
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimals: 18,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      decimals: 18,
      featured: true,
      name: "Dai Token",
      symbol: "DAI"
    },
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      name: "Wrapped BNB",
      symbol: "WBNB"
    }
  ],
  uniswapV3: {
    factoryAddress: "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7",
    positionManager: "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613",
    universalRouterAddress: "0x5Dc88340E1c5c6366864Ee415d6034cadd1A9897"
  },
  unlockAddress: "0xeC83410DbC48C7797D2f2AFe624881674c65c856",
  url: "https://www.bnbchain.org/en"
};
var import_types5 = __toESM(require_src());
var optimism = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "optimism",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0x8f7492DE823025b4CfaAB1D34c58963F2af5DEDA",
      domainId: 1869640809,
      modules: {
        connextMod: "0xF241F12506fb6Bf1909c6bC176A199166414007a",
        delayMod: "0xA8BB5AF09B599794136B14B112e137FAf83Acf1f"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Optimism is a Layer 2 Optimistic Rollup network designed to utilize the strong security guarantees of Ethereum while reducing its cost and latency.",
  explorer: {
    name: "Etherscan",
    urls: {
      address: (address) => `https://optimistic.etherscan.io/address/${address}`,
      base: `https://optimistic.etherscan.io/`,
      token: (address, holder) => `https://optimistic.etherscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://optimistic.etherscan.io/tx/${hash}`
    }
  },
  featured: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
        id: import_types5.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xF6a9138b4ebEd2AcF651Cbd40B45584B4c625e87",
        id: import_types5.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x1402D55BF0D6566ca8F569041000a8015b608632",
        id: import_types5.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326",
        id: import_types5.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0xD4385fd4A79B6636828eC8BC6795766a797E9CF5",
        id: import_types5.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x7217b772788374391e890b773e6b8B7101b5Acde",
        id: import_types5.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x6a0971717ABFfCfE08f67b53DeAC5D234A6676Ed",
        id: import_types5.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x5765883E120F707A528F3e476636304De9280b6c",
        id: import_types5.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 10,
  isTestNetwork: false,
  keyManagerAddress: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
  kickbackAddress: "0x981e0Ac8ABde773a72FeD793c1BEF99a53fAC342",
  maxFreeClaimCost: 100,
  multisig: "0x6E78b4447e34e751EC181DCBed63633aA753e145",
  name: "Optimism",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0x4200000000000000000000000000000000000006"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/optimism/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/optimism/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/10",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.optimism.io",
  startBlock: 302400,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/10",
    graphId: "8heasZLjiLcTWtLTb7aFdWFe5yYZTgzLZfW76wrnTwrt",
    studioName: "unlock-protocol-optimism"
  },
  tokens: [
    {
      address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1",
      decimals: 18,
      name: "Worldcoin",
      symbol: "WLD"
    },
    {
      address: "0x4200000000000000000000000000000000000042",
      decimals: 18,
      name: "Optimism",
      symbol: "OP"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      decimals: 8,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0xc709c9116dBf29Da9c25041b13a07A0e68aC5d2D",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0xa55F8Ba16C5Bb580967f7dD94f927B21d0acF86c",
      3e3: "0x1dA6c13515362B42ACb1Ad24a713f74f925F3AEB",
      500: "0xafF14D23630d5A4BF5e36e52847bE593F0f87672"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0xb555edF5dcF85f42cEeF1f3630a52A108E55A654"
  },
  unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c",
  unlockDaoToken: {
    address: "0xc709c9116dBf29Da9c25041b13a07A0e68aC5d2D",
    mainnetBridge: "0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1",
    uniswapV3Pool: "0x98b506bf1916f674a7BEC5284A043a21fCC3d206"
  },
  url: "https://www.optimism.io/"
};
var import_types6 = __toESM(require_src());
var celo = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "celo",
  description: "Celo is the carbon-negative, mobile-first, EVM-compatible blockchain ecosystem leading a thriving new digital economy for all.",
  explorer: {
    name: "Celoscan",
    urls: {
      address: (address) => `https://celoscan.io/address/${address}`,
      base: `https://celoscan.io/`,
      token: (address, holder) => `https://celoscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://celoscan.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0xdc230F9A08918FaA5ae48B8E13647789A8B6dD46",
        id: import_types6.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xe49f5FD63cD7ec130B07dad30f068CC08F201e1e",
        id: import_types6.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x55C7336ea96BABb0bEabD676C07880aa8b76584a",
        id: import_types6.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xC4E6FDfe46CD1DF46801e080000a4897c42Fd75F",
        id: import_types6.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x15922b77301Df5EA532074e9fb30d115FB6A03fE",
        id: import_types6.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 42220,
  isTestNetwork: false,
  keyManagerAddress: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC",
  maxFreeClaimCost: 1,
  multisig: "0xc293E2da9E558bD8B1DFfC4a7b174729fAb2e4E8",
  name: "Celo",
  nativeCurrency: {
    coingecko: "celo",
    decimals: 18,
    name: "CELO",
    symbol: "CELO"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/42220",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://forno.celo.org",
  startBlock: 13994123,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/42220",
    graphId: "7WoP8r47dyfYvQv7tk5wxSYyszENqXz2EATjUT8x7gRm",
    studioName: "unlock-protocol-celo"
  },
  tokens: [
    {
      address: "0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
      decimals: 6,
      featured: true,
      mainnetAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xE4fE50cdD716522A56204352f00AA110F731932d",
      decimals: 18,
      featured: true,
      mainnetAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      name: "Dai Stablecoin",
      symbol: "DAI"
    }
  ],
  uniswapV3: {
    factoryAddress: "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc",
    oracle: {
      100: "0xaB82D702A4e0cD165072C005dc504A21c019718F",
      3e3: "0x5c67AD0CAfe61aF3706347aBc695D7ACcb38EFb3",
      500: "0xc9F29DdBD4D828cFb2EB491E9d48013a9c0E3C89"
    },
    quoterAddress: "0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8",
    universalRouterAddress: "0xC73d61d192FB994157168Fb56730FdEc64C9Cb8F"
  },
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  url: "https://celo.org"
};
var import_types7 = __toESM(require_src());
var arbitrum = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "arbitrum",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0xEE9deC2712cCE65174B561151701Bf54b99C24C8",
      domainId: 1634886255,
      modules: {
        connextMod: "0x4A553635774b3d6fB6273A83b5B49577dF450227",
        delayMod: "0xF241F12506fb6Bf1909c6bC176A199166414007a"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Arbitrum One is a Layer 2 (L2) chain running on top of Ethereum Mainnet that enables high-throughput, low cost smart contracts operations.",
  explorer: {
    name: "Arbitrum",
    urls: {
      address: (address) => `https://arbiscan.io/address/${address}`,
      base: `https://arbiscan.io/`,
      token: (address, holder) => `https://arbiscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://arbiscan.io/tx/${hash}`
    }
  },
  featured: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0xd0b14797b9D08493392865647384974470202A78",
        id: import_types7.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xD925Ac2887Ba4372849F0fd64217A6749552bb21",
        id: import_types7.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x06538095ae3B5123e440D9991377B85C3BC6E6FF",
        id: import_types7.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x1A9E2E085bF4E4fE5eE45C682a3Af26d55FA1431",
        id: import_types7.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xBa81C9379AC1221BF8C100800dD0B0b0b048ba14",
        id: import_types7.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x8e1158A7f9d0905602f90191AC4DFb30DD4Ee04C",
        id: import_types7.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0xA321fEa1E5c772043a466b2969CBEB1c095Cf3BA",
        id: import_types7.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 42161,
  isTestNetwork: false,
  keyManagerAddress: "0x520294E736167303efa038205D4391b58261BC9c",
  kickbackAddress: "0x62f155229fb60d793440b8c733c4C5937C626c1A",
  maxFreeClaimCost: 100,
  multisig: "0x310e9f9E3918a71dB8230cFCF32a083c7D9536d0",
  name: "Arbitrum",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/arbitrum/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/arbitrum/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/42161",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://arb1.arbitrum.io/rpc",
  startBlock: 17429533,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/42161",
    graphId: "9DNHi5TyZkxrAcnmmefRRTDHXDAwKQk7BifVY2FeTTFp",
    networkName: "arbitrum-one",
    studioName: "unlock-protocol-arbitrum"
  },
  tokens: [
    {
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      decimals: 6,
      name: "USD Coin (Arb1)",
      symbol: "USDC"
    },
    {
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      decimals: 18,
      name: "Arbitrum",
      symbol: "ARB"
    },
    {
      address: "0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x1dA6c13515362B42ACb1Ad24a713f74f925F3AEB",
      3e3: "0xa55F8Ba16C5Bb580967f7dD94f927B21d0acF86c",
      500: "0x2D11eEf659801cc13ADAde21667af332E77ca9AD"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  unlockDaoToken: {
    address: "0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90",
    mainnetBridge: "0xa3A7B6F88361F48403514059F1F16C8E78d60EeC",
    uniswapV3Pool: "0xF0498d3BDD28fA39d248aE2c65D7F90A794Ed9fE"
  },
  url: "https://arbitrum.io/"
};
var import_types8 = __toESM(require_src());
var avalanche = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "avax",
  description: "Avalanche is an open, programmable smart contracts platform for decentralized applications.",
  explorer: {
    name: "Snowtrace (Avalanche)",
    urls: {
      address: (address) => `https://snowtrace.io/address/${address}`,
      base: `https://snowtrace.io/`,
      token: (address, holder) => `https://snowtrace.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://snowtrace.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x2499D94880B30fA505543550ac8a1e24cfFeFe78",
        id: import_types8.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x58D86Dc056c442867485941FeBeA8D3bB4657eAC",
        id: import_types8.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x520294E736167303efa038205D4391b58261BC9c",
        id: import_types8.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xa396CFB1fFe3ADD2308dF140aa2d0A520D5c6c31",
        id: import_types8.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x927D68eAE936Ec0111f01Fc4Ddd9cC57DB3f0Af2",
        id: import_types8.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 43114,
  isTestNetwork: false,
  keyManagerAddress: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326",
  maxFreeClaimCost: 1,
  multisig: "0xEc7777C51327917fd2170c62873272ea168120Cb",
  name: "Avalanche (C-Chain)",
  nativeCurrency: {
    coingecko: "avalanche-2",
    decimals: 18,
    name: "AVAX",
    symbol: "AVAX"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/avalanche/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/avalanche/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/43114",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://api.avax.network/ext/bc/C/rpc",
  startBlock: 17188332,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/43114",
    graphId: "8cLuGXsVcgmeEsaekRgKeWwF4nKahugK1VFKkTckTHmj",
    studioName: "unlock-protocol-avalanche"
  },
  tokens: [
    {
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH.e"
    },
    {
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC.e"
    },
    {
      address: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT.e"
    },
    {
      address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI.e"
    },
    {
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC.e"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x740b1c1de25031C31FF4fC9A62f554A55cdC1baD",
    positionManager: "0x655C406EBFa14EE2006250925e54ec43AD184f8B",
    universalRouterAddress: "0x4Dae2f939ACf50408e13d58534Ff8c2776d45265"
  },
  unlockAddress: "0x70cBE5F72dD85aA634d07d2227a421144Af734b3",
  url: "https://www.avalabs.org/"
};
var import_types9 = __toESM(require_src());
var base = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "base",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0xB8448C6f7f7887D36DcA487370778e419e9ebE3F",
      domainId: 1650553709,
      modules: {
        connextMod: "0xfe9fD6af67E48D9f05Aa88679Ac294E3f28532eE",
        delayMod: "0x805C2EbaE1510f59E1D717A1A51aFad335FFAec5"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Base is a secure, low-cost, builder-friendly Ethereum L2 built to bring the next billion users onchain.  ",
  explorer: {
    name: "Basescan",
    urls: {
      address: (address) => `https://basescan.org/address/${address}`,
      base: `https://basescan.org/`,
      token: (address, holder) => `https://basescan.org/token/${address}?a=${holder}`,
      transaction: (hash) => `https://basescan.org/tx/${hash}`
    }
  },
  featured: true,
  fullySubsidizedGas: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x7455DdA870f8421b7C1920Efb84DFF7398c6A73E",
        id: import_types9.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x8c573E1A64D7C6726B3b2E119206e9FD1f5Bc0a0",
        id: import_types9.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0xC2227b9fc792a5933E64FE9F782fdeDDaf49951b",
        id: import_types9.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x87AaA7498Daf5Bb0DB03806fB5389b260E8aCe92",
        id: import_types9.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x64441384DB40F34855b1617C05800bE43bD34709",
        id: import_types9.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0xbBBdD46ef548712c203d306F6587336EC15E0d7f",
        id: import_types9.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x16de050b14B22DC070522b8De134490CB1655B11",
        id: import_types9.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 8453,
  isTestNetwork: false,
  keyManagerAddress: "0xD26c05a33349a6DeD02DD9360e1ef303d1246fb6",
  kickbackAddress: "0xCf5802682F194C2447E92a63283471A99CB792f6",
  maxFreeClaimCost: 100,
  multisig: "0x8149FeaFa41DD1ee3CA62299b9c67e9ac12FA340",
  name: "Base",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x4200000000000000000000000000000000000006"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/base/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/base/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/8453",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.base.org",
  startBlock: 175e4,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/8453",
    graphId: "ECQhJQV8KWMfAAgWf8WV5duy1si9TnZpL4f194oGLrWW",
    networkName: "base",
    studioName: "unlock-protocol-base"
  },
  tokens: [
    {
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      name: "USD Base Coin",
      symbol: "USDbC"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
      decimals: 18,
      name: "Degen",
      symbol: "DEGEN"
    },
    {
      address: "0xaC27fa800955849d6D17cC8952Ba9dD6EAA66187",
      decimals: 18,
      name: "UnlockProtocolToken",
      symbol: "UP"
    },
    {
      address: "0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
    oracle: {
      100: "0x2411336105D4451713d23B5156038A48569EcE3a",
      3e3: "0xfa7AC1c24339f629826C419eC95961Df58563438",
      500: "0xA8BB5AF09B599794136B14B112e137FAf83Acf1f"
    },
    positionManager: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
    universalRouterAddress: "0x198EF79F1F515F02dFE9e3115eD9fC07183f02fC"
  },
  unlockAddress: "0xd0b14797b9D08493392865647384974470202A78",
  unlockDaoToken: {
    address: "0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96",
    mainnetBridge: "0x3154Cf16ccdb4C6d922629664174b904d80F2C35",
    uniswapV3Pool: "0x0a052dAd89F9695A0074958b81c85479bc8844F8"
  },
  url: "https://base.org/"
};
var import_types10 = __toESM(require_src());
var linea = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "linea",
  dao: {
    chainId: 8453,
    governanceBridge: {
      connext: "0xa05eF29e9aC8C75c530c2795Fa6A800e188dE0a9",
      domainId: 1818848877,
      modules: {
        connextMod: "0x1b6ED52be06Cef3b9eD234114843BE79971e51f3",
        delayMod: "0x2D4123dB5A4d3bAA2DbeA4cB10333a9E3271292a"
      }
    },
    governor: "0x65bA0624403Fc5Ca2b20479e9F626eD4D78E0aD9"
  },
  description: "Linea a Layer 2 zk-Rollup EVM-compatible chain powered by ConsenSys.",
  explorer: {
    name: "Linea",
    urls: {
      address: (address) => `https://lineascan.build/address/${address}`,
      base: `https://lineascan.build/`,
      token: (address, holder) => `https://lineascan.build/token/${address}?a=${holder}`,
      transaction: (hash) => `https://lineascan.build/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types10.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
        id: import_types10.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xaE8F3F0826A39122401ED634f0a5C19549331432",
        id: import_types10.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0xCD9C9b40D757b56359e19563203D3bc64089638d",
        id: import_types10.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x04664b4290fa1F4001ED25d9576f7C2d980aC64d",
        id: import_types10.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xD925Ac2887Ba4372849F0fd64217A6749552bb21",
        id: import_types10.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ]
  },
  id: 59144,
  isTestNetwork: false,
  keyManagerAddress: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
  maxFreeClaimCost: 10,
  multisig: "0x0b441f6A255a56670B6fdb37B527e091a394eeB9",
  name: "Linea",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Linea Ether",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.linea.build/",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.linea.build/",
  startBlock: 560908,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/59144",
    graphId: "3G85noAfEa4jYGPmrvzzBTY55abu2kVgXnfWwRq3absq",
    studioName: "unlock-protocol-linea"
  },
  tokens: [
    {
      address: "0x176211869cA2b568f2A7D4EE941E073a821EE1ff",
      decimals: 6,
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xA219439258ca9da29E9Cc4cE5596924745e12B93",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  // uniswapV3: {},
  // universalCard: {},
  unlockAddress: "0x70B3c9Dd9788570FAAb24B92c3a57d99f8186Cc7",
  url: "https://linea.build/"
};
var import_types11 = __toESM(require_src());
var sepolia = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "sepolia",
  description: "Sepolia is the primary testnet recommended by the Ethereum community for dapp development",
  explorer: {
    name: "Sepolia Etherscan",
    urls: {
      address: (address) => `https://sepolia.etherscan.io/address/${address}`,
      base: `https://sepolia.etherscan.io/`,
      token: (address, holder) => `https://sepolia.etherscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://sepolia.etherscan.io/tx/${hash}`
    }
  },
  faucets: [
    {
      name: "Google",
      url: "https://cloud.google.com/application/web3/faucet/ethereum/sepolia"
    },
    {
      name: "Alchemy",
      url: "https://www.alchemy.com/faucets/ethereum-sepolia"
    },
    {
      name: "Infura",
      url: "https://www.infura.io/faucet/sepolia"
    }
  ],
  featured: true,
  fullySubsidizedGas: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
        id: import_types11.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xd0b14797b9D08493392865647384974470202A78",
        id: import_types11.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types11.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x639143cbf90F27eA5Ce4b3A7D869d4D7878009A5",
        id: import_types11.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x0aC1a84AcaB08b630714d59dA74576D7274E68d5",
        id: import_types11.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x499C854E40Ce4056266822c86D1A326f0FE6491a",
        id: import_types11.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x323Fd488F101F2F9252ecE9f9fe3e56C48912880",
        id: import_types11.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x7dB91c34071e7B34656DF9aCBe1542337018D617",
        id: import_types11.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 11155111,
  isTestNetwork: true,
  keyManagerAddress: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
  kickbackAddress: "0x4D2aAeE1F34a9b4dfA57e8A4041BE82C939278dD",
  maxFreeClaimCost: 1e3,
  multisig: "0x95fE514fe7F60722AFF0FD009ebeE4Ba2013924c",
  name: "Sepolia",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x5f207d42F869fd1c71d7f0f81a2A67Fc20FF7323"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://testnets.opensea.io/assets/sepolia/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://testnets.opensea.io/assets/sepolia/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/11155111",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc2.sepolia.org/",
  startBlock: 4381710,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/11155111",
    graphId: "5ZjqtfMTQJTCsHVmY9eXirW5B9SEJnRW7ipg5SzTP29k",
    networkName: "sepolia",
    studioName: "unlock-protocol-sepolia"
  },
  tokens: [
    {
      address: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
      decimals: 6,
      faucet: { name: "Circle", url: "https://faucet.circle.com/" },
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
      decimals: 18,
      featured: true,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      decimals: 18,
      name: "Uniswap",
      symbol: "UNI"
    },
    {
      address: "0x447B1492C5038203f1927eB2a374F5Fcdc25999d",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x0227628f3F023bb0B980b67D528571c95c6DaC1c",
    oracle: {
      100: "0x5D7109aF116eF9D95f107B25c401bCF3965b4027",
      3e3: "0x59E399647F12bDec93875B32376dfBcA2E69d955",
      500: "0x5Ff3C00d851daA690d5fE2a2328A81e4a8219e1a"
    },
    positionManager: "0x1238536071E1c677A632429e3655c799b22cDA52",
    universalRouterAddress: "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD"
  },
  unlockAddress: "0x36b34e10295cCE69B652eEB5a8046041074515Da",
  unlockDaoToken: {
    address: "0x447B1492C5038203f1927eB2a374F5Fcdc25999d"
  },
  url: "https://github.com/eth-clients/sepolia"
};
var zkevm = {
  chain: "zkevm",
  description: "Polygon zkEVM is a Layer 2 network of the Ethereum Virtual Machine (EVM), a zero-knowledge (ZK) rollup scaling solution.",
  explorer: {
    name: "zkEVM (Polygonscan)",
    urls: {
      address: (address) => `https://zkevm.polygonscan.com/address/${address}`,
      base: `https://zkevm.polygonscan.com/`,
      token: (address, holder) => `https://zkevm.polygonscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://zkevm.polygonscan.com/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 1101,
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  multisig: "0xD62EF39c54d9100B17c8fA3C2D15e0262338AED0",
  name: "zkEVM (Polygon)",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/1101",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://polygon-zkevm.drpc.org",
  startBlock: 0,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/1101",
    graphId: "4crwbsig953XR6tpAva9PEshjM7qNujM2jDjqRX2YoWP",
    networkName: "polygon-zkevm",
    studioName: "unlock-protocol-zkevm"
  },
  tokens: [
    {
      address: "0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x744C5860ba161b5316F7E80D9Ec415e2727e5bD5",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  url: "https://polygon.technology/polygon-zkevm"
};
var scroll = {
  chain: "scroll",
  description: "Scroll seamlessly extends Ethereum\u2019s capabilities through zero knowledge tech and EVM compatibility. The L2 network built by Ethereum devs for Ethereum devs.",
  explorer: {
    name: "Scrollscan",
    urls: {
      address: (address) => `https://scrollscan.com/address/${address}`,
      base: "https://scrollscan.com/",
      token: (address, holder) => `https://etherscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://scrollscan.com/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 534352,
  isTestNetwork: false,
  maxFreeClaimCost: 500,
  multisig: "0x0feE9413A626a05a08AcB0E0e5D6A483e6A0a172",
  name: "Scroll",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0x5300000000000000000000000000000000000004"
  },
  provider: "https://rpc.unlock-protocol.com/534352",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.scroll.io",
  startBlock: 2937779,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/534352",
    graphId: "49qmkjKjv9qfvgkCQTbZcG5gYPhwybmMfRhNc8brKf4F",
    studioName: "unlock-protocol-scroll"
  },
  tokens: [
    {
      address: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x5300000000000000000000000000000000000004",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  url: "https://scroll.io"
};
var zksync = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "zksync",
  description: "zkSync is a Layer-2 protocol that scales Ethereum with cutting-edge ZK tech. Our mission is not only to merely increase Ethereum's throughput, but to fully preserve its foundational values \u2013 freedom, self-sovereignty, decentralization \u2013 at scale.",
  explorer: {
    name: "zkSync Era Block Explorer",
    urls: {
      address: (address) => `https://explorer.zksync.io/address/${address}`,
      base: `https://explorer.zksync.io/`,
      token: (address, holder) => `https://explorer.zksync.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://explorer.zksync.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 324,
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  multisig: "0xFa5592CE9C52FA5214ccEa10cB72Faa88eC80a3c",
  name: "zkSync Era",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "zkSync Ether",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/324",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.era.zksync.io",
  startBlock: 25905168,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/324",
    graphId: "Bqo6hTg28TRmzksb6Eg8EPefD4sXBra1ad1WD4oz6c88",
    networkName: "zksync-era",
    studioName: "unlock-protocol-zksync"
  },
  tokens: [
    {
      address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
      decimals: 6,
      name: "Bridged USDC (zkSync)",
      symbol: "USDC.e"
    },
    {
      address: "0x000000000000000000000000000000000000800A",
      decimals: 18,
      name: "Ether",
      symbol: "ETH"
    },
    {
      address: "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
      decimals: 18,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
      decimals: 8,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x32CF553582159F12fBb1Ae1649b3670395610F24",
  url: "https://zksync.io/"
};
var import_types12 = __toESM(require_src());
var baseSepolia = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "base-sepolia",
  description: "A public testnet for Base",
  explorer: {
    name: "Base Sepolia Etherscan",
    urls: {
      address: (address) => `https://sepolia.basescan.org/address/${address}`,
      base: `https://sepolia.basescan.org/`,
      token: (address, holder) => `https://sepolia.basescan.org/token/${address}?a=${holder}`,
      transaction: (hash) => `https://sepolia.basescan.org/tx/${hash}`
    }
  },
  faucets: [
    {
      name: "Coinbase",
      url: "https://portal.cdp.coinbase.com/products/faucet"
    },
    {
      name: "Superchain",
      url: "https://console.optimism.io/faucet"
    },
    {
      name: "Alchemy",
      url: "https://basefaucet.com/"
    }
  ],
  featured: false,
  fullySubsidizedGas: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
        id: import_types12.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types12.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 84532,
  isTestNetwork: true,
  keyManagerAddress: "",
  kickbackAddress: "0x930730F962133216353A989d9b6cfACb19FFB49D",
  maxFreeClaimCost: 1e3,
  multisig: "0x68F2c5D9009dc4d553f814D689102a53B2b349Cc",
  name: "Base Sepolia",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/84532",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://sepolia.base.org",
  startBlock: 7889118,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/84532",
    graphId: "FxGJ2eFse3yhWpUMrBc4VzMqAgYamn49y1JQjZNugzZf",
    networkName: "base-sepolia",
    studioName: "unlock-protocol-base-sepolia"
  },
  tokens: [
    {
      address: "0x036CbD53842c5426634e7929541eC2318f3dCF7e",
      decimals: 6,
      faucet: { name: "Circle", url: "https://faucet.circle.com/" },
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      featured: true,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "Wrapped Ether",
      symbol: "WETH"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  unlockDaoToken: {
    address: "0x68a8011d72E6D41bf7CE9dC49De0aeaEBAAC9b39"
  }
};
var networks = {};
Object.keys(networks_exports).forEach((networkName) => {
  const network = networks_exports[networkName];
  networks[network.id] = network;
});

// src/PublicLock/v9/setGasRefundValue.js
import { ethers as ethers4 } from "ethers";
async function setMaxNumberOfKeys({ lockAddress, gasRefundValue }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const { chainId: network } = await this.provider.getNetwork();
  let decimals = this.networks[network].nativeCurrency.decimals;
  const erc20Address = await lockContract.tokenAddress();
  if (erc20Address !== ethers4.ZeroAddress) {
    decimals = await getErc20Decimals(erc20Address, this.provider);
  }
  const refundValue = ethers4.parseUnits(gasRefundValue, decimals);
  const transactionPromise = lockContract.setGasRefundValue(refundValue);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}
var setGasRefundValue_default = setMaxNumberOfKeys;

// src/PublicLock/v9/setKeyManagerOf.js
async function setKeyManagerOf_default({ lockAddress, managerAddress, tokenId }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setKeyManagerOf(
    tokenId,
    managerAddress
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  const tx = await this.provider.waitForTransaction(hash);
  return tx;
}

// src/PublicLock/v9/setMaxNumberOfKeys.js
async function setMaxNumberOfKeys2({ lockAddress, maxNumberOfKeys }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setMaxNumberOfKeys(maxNumberOfKeys);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}
var setMaxNumberOfKeys_default = setMaxNumberOfKeys2;

// src/PublicLock/v9/shareKey.js
async function shareKey_default({ lockAddress, tokenId, recipient, duration }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!tokenId) {
    const owner = await this.signer.getAddress();
    tokenId = await lockContract.getTokenIdFor(owner);
  }
  if (!duration) {
    const keyOwner = await lockContract.ownerOf(tokenId);
    const expiration = await lockContract.keyExpirationTimestampFor(keyOwner);
    duration = Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3) - expiration;
  }
  const transactionPromise = lockContract.shareKey(
    recipient,
    tokenId,
    duration,
    transactionOptions
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  return await this.provider.waitForTransaction(hash);
}

// src/PublicLock/v9/totalKeys.js
async function totalKeys_default(lockAddres, owner, provider) {
  const lockContract = await this.getLockContract(lockAddres, provider);
  return lockContract.balanceOf(owner);
}

// src/PublicLock/v9/transferFrom.js
async function transferFrom_default({ lockAddress, keyOwner, to, tokenId }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.transferFrom(keyOwner, to, tokenId);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  const tx = await this.provider.waitForTransaction(hash);
  return tx;
}

// src/PublicLock/v9/updateKeyPrice.js
async function updateKeyPrice_default({ lockAddress, keyPrice, decimals, erc20Address }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address) {
    erc20Address = await lockContract.tokenAddress();
  }
  if (decimals == null) {
    if (erc20Address !== ZERO) {
      decimals = await getErc20Decimals(erc20Address, this.provider);
    } else {
      decimals = 18;
    }
  }
  const actualAmount = utils_default.toDecimal(keyPrice, decimals);
  const transactionPromise = lockContract.updateKeyPricing(
    actualAmount,
    erc20Address || ZERO
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  const parser = lockContract.interface;
  const priceChangedEvent = receipt.logs.map((log) => {
    return parser.parseLog(log);
  }).find(({ fragment }) => fragment && fragment.name === "PricingChanged");
  if (priceChangedEvent) {
    return utils_default.fromDecimal(priceChangedEvent.args.keyPrice, decimals);
  }
  return null;
}

// src/PublicLock/v9/updateLockName.js
async function updateLockName_default({ lockAddress, name: name2 }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.updateLockName(name2);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return name2;
}

// src/PublicLock/v9/updateLockSymbol.js
async function updateLockSymbol_default({ lockAddress, symbol }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.updateLockSymbol(symbol);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return symbol;
}

// src/PublicLock/v9/updateRefundPenalty.js
async function updateRefundPenalty_default({ lockAddress, freeTrialLength, refundPenaltyBasisPoints }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.updateRefundPenalty(
    freeTrialLength,
    refundPenaltyBasisPoints
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return name;
}

// src/PublicLock/v9/updateTransferFee.js
async function updateTransferFee_default({ lockAddress, transferFeeBasisPoints }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.updateTransferFee(
    transferFeeBasisPoints
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v9/withdrawFromLock.js
async function withdrawFromLock_default({ erc20Address, lockAddress, amount = "0", decimals }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address || erc20Address !== ZERO) {
    erc20Address = await lockContract.tokenAddress();
  }
  if (decimals == null) {
    if (erc20Address !== ZERO) {
      decimals = await getErc20Decimals(erc20Address, this.provider);
    } else {
      decimals = 18;
    }
  }
  const actualAmount = utils_default.toDecimal(amount, decimals);
  const transactionPromise = lockContract.withdraw(erc20Address, actualAmount);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  const parser = lockContract.interface;
  const withdrawalEvent = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  }).find(({ fragment }) => fragment && fragment.name === "Withdrawal");
  if (withdrawalEvent) {
    return utils_default.fromWei(withdrawalEvent.args.amount.toString(), "ether");
  }
  return null;
}

// src/PublicLock/v9/index.js
var v9_default = {
  version: "v9",
  Unlock: abis_default.Unlock.v9,
  PublicLock: abis_default.PublicLock.v9,
  addKeyGranter: addKeyGranter_default,
  addLockManager: addLockManager_default,
  approveBeneficiary: approveBeneficiary_default,
  cancelAndRefund: cancelAndRefund_default,
  expireAndRefundFor: expireAndRefundFor_default,
  getCancelAndRefundValueFor: getCancelAndRefundValueFor_default,
  getKeyExpirationByLockForOwner: getKeyExpirationByLockForOwner_default,
  getLock: getLock_default,
  getTokenIdForOwner: getTokenIdForOwner_default,
  grantKey: grantKey_default,
  grantKeys: grantKeys_default,
  initializeTemplate: initializeTemplate_default,
  isKeyGranter: isKeyGranter_default,
  isLockManager: isLockManager_default,
  keyManagerOf: keyManagerOf_default,
  purchaseKey: purchaseKey_default,
  purchaseKeys: purchaseKeys_default,
  removeKeyGranter: removeKeyGranter_default,
  renounceLockManager: renounceLockManager_default,
  setBaseTokenURI: setBaseTokenURI_default,
  setEventHooks: setEventHooks_default,
  setExpirationDuration: setExpirationDuration_default,
  setGasRefundValue: setGasRefundValue_default,
  setKeyManagerOf: setKeyManagerOf_default,
  setMaxNumberOfKeys: setMaxNumberOfKeys_default,
  shareKey: shareKey_default,
  totalKeys: totalKeys_default,
  transferFrom: transferFrom_default,
  updateKeyPrice: updateKeyPrice_default,
  updateLockName: updateLockName_default,
  updateLockSymbol: updateLockSymbol_default,
  updateRefundPenalty: updateRefundPenalty_default,
  updateTransferFee: updateTransferFee_default,
  withdrawFromLock: withdrawFromLock_default
};

// src/PublicLock/v10/getPurchaseKeysArguments.js
async function getPurchaseKeysArguments({
  owners: _owners,
  keyManagers: _keyManagers,
  keyPrices: _keyPrices,
  referrers: _referrers,
  lockAddress,
  erc20Address,
  recurringPayments,
  decimals,
  totalApproval,
  // explicit approval amount
  data: _data
}) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address) {
    erc20Address = await lockContract.tokenAddress();
  }
  const defaultOwner = await this.signer.getAddress();
  const owners = _owners || [defaultOwner];
  const defaultArray = Array(owners.length).fill(null);
  const data = (_data || defaultArray).map((d) => d || "0x");
  const keyPrices = await Promise.all(
    Array.from({ length: owners.length }).map(async (_, index) => {
      const keyPrice = _keyPrices && _keyPrices[index];
      const owner = owners[index];
      let referrer = _referrers && _referrers[index];
      let dataValue = data[index];
      if (!referrer) {
        referrer = ZERO;
      }
      if (!dataValue) {
        dataValue = "0x";
      }
      if (!keyPrice) {
        if (owner) {
          return await lockContract.purchasePriceFor(owner, referrer, dataValue);
        } else {
          return await lockContract.keyPrice();
        }
      }
      return formatKeyPrice_default(keyPrice, erc20Address, decimals, this.provider);
    })
  );
  const keyManagers = (_keyManagers || defaultArray).map((km) => km || ZERO);
  const referrers = (_referrers || defaultArray).map((km) => km || ZERO);
  if (!(keyManagers.length === owners.length && keyPrices.length === owners.length && referrers.length === owners.length && data.length === owners.length)) {
    throw new Error(
      "Params mismatch. All purchaseKeys params array should have the same length"
    );
  }
  const totalPrice = keyPrices.reduce((total, kp) => total + kp, BigInt(0));
  let totalAmountToApprove = totalApproval;
  if (!totalAmountToApprove) {
    totalAmountToApprove = keyPrices.map((keyPrice, i) => {
      if (keyPrice > 0) {
        const recurringPayment = recurringPayments && recurringPayments[i];
        if (!recurringPayment) {
          return keyPrice;
        } else if (recurringPayment === Infinity) {
          return MAX_UINT;
        } else {
          return keyPrice * BigInt(recurringPayments);
        }
      }
      return BigInt(0);
    }).reduce((total, approval) => {
      if (total === MAX_UINT || approval === MAX_UINT) {
        return MAX_UINT;
      }
      return total + approval;
    }, BigInt(0));
  }
  return {
    owners,
    keyPrices,
    keyManagers,
    referrers,
    data,
    totalPrice,
    erc20Address,
    totalAmountToApprove
  };
}

// src/PublicLock/v10/purchaseKeys.js
async function purchaseKeys_default2(options, transactionOptions = {}, callback) {
  const { lockAddress } = options;
  const lockContract = await this.getLockContract(lockAddress);
  const {
    owners,
    keyPrices,
    keyManagers,
    referrers,
    data,
    totalPrice,
    erc20Address,
    totalAmountToApprove
  } = await getPurchaseKeysArguments.bind(this)(options);
  const purchaseArgs = [keyPrices, owners, referrers, keyManagers, data];
  const callData = lockContract.interface.encodeFunctionData(
    "purchase",
    purchaseArgs
  );
  if (!erc20Address || erc20Address === ZERO) {
    transactionOptions.value = totalPrice;
  }
  const approvalOptions = {
    erc20Address,
    totalAmountToApprove,
    address: lockAddress
  };
  if (approvalOptions.erc20Address && approvalOptions.erc20Address !== ZERO && totalAmountToApprove > 0) {
    await approveAllowance.bind(this)(approvalOptions);
  }
  if (!transactionOptions.gasLimit) {
    const preserveGasSettings = transactionOptions.maxFeePerGas || transactionOptions.gasPrice;
    try {
      if (!preserveGasSettings) {
        const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = await this.provider.getFeeData();
        if (maxFeePerGas && maxPriorityFeePerGas) {
          transactionOptions.maxFeePerGas = maxFeePerGas;
          transactionOptions.maxPriorityFeePerGas = maxPriorityFeePerGas;
        } else {
          transactionOptions.gasPrice = gasPrice;
        }
      }
      const gasLimitPromise = lockContract.purchase.estimateGas(
        keyPrices,
        owners,
        referrers,
        keyManagers,
        data,
        transactionOptions
      );
      const gasLimit = await gasLimitPromise;
      transactionOptions.gasLimit = gasLimit * 13n / 10n;
    } catch (error) {
      console.error(
        "We could not estimate gas ourselves. Let wallet do it.",
        error
      );
    }
    if (!preserveGasSettings) {
      delete transactionOptions.maxFeePerGas;
      delete transactionOptions.maxPriorityFeePerGas;
      delete transactionOptions.gasPrice;
    }
  }
  const transactionRequestPromise = lockContract.purchase.populateTransaction(
    keyPrices,
    owners,
    referrers,
    keyManagers,
    data,
    transactionOptions
  );
  const transactionRequest = await transactionRequestPromise;
  if (transactionOptions.runEstimate) {
    const estimate = this.signer.estimateGas(transactionRequest);
    return {
      transactionRequest,
      estimate
    };
  }
  const transactionPromise = this.signer.sendTransaction(transactionRequest);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  if (receipt.status === 0) {
    throw new Error("Transaction failed");
  }
  const transferEvents = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return lockContract.interface.parseLog(log);
  }).filter((evt) => evt?.fragment && evt.fragment?.name === "Transfer");
  if (transferEvents && transferEvents.length) {
    return transferEvents.map((v) => v.args.tokenId.toString());
  }
  return null;
}

// src/PublicLock/v10/purchaseKey.js
async function purchaseKey_default2({
  lockAddress,
  owner,
  keyManager,
  keyPrice,
  erc20Address,
  decimals,
  referrer,
  recurringPayments,
  // nb of reccuring payments to approve,
  totalApproval,
  // Explicit approval amount
  data
}, transactionOptions = {}, callback) {
  const keys = await purchaseKeys_default2.bind(this)(
    {
      owners: owner ? [owner] : null,
      keyManagers: keyManager ? [keyManager] : null,
      keyPrices: keyPrice ? [keyPrice] : null,
      referrers: referrer ? [referrer] : null,
      data: data ? [data] : null,
      recurringPayments: recurringPayments ? [recurringPayments] : null,
      lockAddress,
      erc20Address,
      totalApproval,
      decimals
    },
    transactionOptions,
    callback
  );
  return keys[0];
}

// src/PublicLock/v10/extendKey.js
async function extendKey_default({
  lockAddress,
  tokenId,
  owner,
  keyPrice,
  erc20Address,
  decimals,
  referrer,
  data,
  totalApproval,
  recurringPayments
}, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!tokenId) {
    throw new Error("Missing tokenId.");
  }
  if (!referrer) {
    referrer = ZERO;
  }
  if (!data) {
    data = "0x";
  }
  if (!erc20Address) {
    erc20Address = await lockContract.tokenAddress();
  }
  let actualAmount;
  const actualOwner = await lockContract.ownerOf(tokenId);
  if (!keyPrice) {
    if (actualOwner) {
      actualAmount = await lockContract.purchasePriceFor(
        actualOwner,
        referrer,
        data
      );
    } else {
      actualAmount = await lockContract.keyPrice();
    }
  } else {
    actualAmount = await formatKeyPrice_default(
      keyPrice,
      erc20Address,
      decimals,
      this.provider
    );
  }
  const extendArgs = [actualAmount, tokenId, referrer, data];
  const callData = lockContract.interface.encodeFunctionData(
    "extend",
    extendArgs
  );
  if (!erc20Address || erc20Address === ZERO) {
    transactionOptions.value = actualAmount;
  }
  let totalAmountToApprove = totalApproval || 0;
  if (!totalAmountToApprove && actualAmount > 0) {
    if (!recurringPayments) {
      totalAmountToApprove = actualAmount;
    } else if (recurringPayments === Infinity) {
      totalAmountToApprove = MAX_UINT;
    } else {
      totalAmountToApprove = actualAmount * BigInt(recurringPayments);
    }
  }
  const approvalOptions = {
    erc20Address,
    totalAmountToApprove,
    address: lockAddress
  };
  if (approvalOptions.erc20Address && approvalOptions.erc20Address !== ZERO && totalAmountToApprove > 0) {
    await approveAllowance.bind(this)(approvalOptions);
  }
  if (!transactionOptions.gasLimit) {
    try {
      const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = await this.provider.getFeeData();
      if (maxFeePerGas && maxPriorityFeePerGas) {
        transactionOptions.maxFeePerGas = maxFeePerGas;
        transactionOptions.maxPriorityFeePerGas = maxPriorityFeePerGas;
      } else {
        transactionOptions.gasPrice = gasPrice;
      }
      const gasLimitPromise = lockContract.extend.estimateGas(
        actualAmount,
        tokenId,
        referrer,
        data,
        transactionOptions
      );
      const gasLimit = await gasLimitPromise;
      delete transactionOptions.maxFeePerGas;
      delete transactionOptions.maxPriorityFeePerGas;
      delete transactionOptions.gasPrice;
      transactionOptions.gasLimit = gasLimit * 13n / 10n;
    } catch (error) {
      console.error(
        "We could not estimate gas ourselves. Let wallet do it.",
        error
      );
      delete transactionOptions.maxFeePerGas;
      delete transactionOptions.maxPriorityFeePerGas;
      delete transactionOptions.gasPrice;
    }
  }
  const transactionRequestpromise = lockContract.extend.populateTransaction(
    actualAmount,
    tokenId,
    referrer,
    data,
    transactionOptions
  );
  const transactionRequest = await transactionRequestpromise;
  if (transactionOptions.runEstimate) {
    const estimate = this.signer.estimateGas(transactionRequest);
    return {
      transactionRequest,
      estimate
    };
  }
  const transactionPromise = this.signer.sendTransaction(transactionRequest);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  return hash;
}

// src/PublicLock/v10/mergeKeys.js
async function mergeKeys_default({ lockAddress, tokenIdFrom, tokenIdTo, amount }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!tokenIdFrom) {
    throw new Error("Missing tokenId from");
  }
  if (!tokenIdTo) {
    throw new Error("Missing tokenId to");
  }
  if (!amount) {
    const blockNumber = await this.provider.getBlockNumber();
    const { timestamp } = await this.provider.getBlock(blockNumber);
    const expiration = await lockContract.keyExpirationTimestampFor(tokenIdFrom);
    amount = utils_default.bigNumberify(expiration) - BigInt(timestamp + 3);
  }
  const transactionPromise = lockContract.mergeKeys(
    tokenIdFrom,
    tokenIdTo,
    amount
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v10/shareKey.js
async function shareKey_default2({ lockAddress, tokenId, recipient, duration }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!tokenId) {
    throw new Error("shareKey: Missing token id from");
  }
  if (!duration) {
    const expiration = await lockContract.keyExpirationTimestampFor(tokenId);
    duration = Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3) - expiration;
  }
  const transactionPromise = lockContract.shareKey(
    recipient,
    tokenId,
    duration,
    transactionOptions
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  if (receipt.status === 0) {
    throw new Error("Transaction failed");
  }
  const parser = lockContract.interface;
  const transferEvent = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  }).find(({ fragment }) => fragment && fragment.name === "Transfer");
  if (transferEvent) {
    return transferEvent.args.tokenId.toString();
  }
  return null;
}

// src/PublicLock/v10/setMaxKeysPerAddress.js
async function setMaxKeysPerAddress_default({ lockAddress, maxKeysPerAddress }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setMaxKeysPerAddress(maxKeysPerAddress);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}

// src/PublicLock/v10/expireAndRefundFor.js
async function expireAndRefundFor_default2({ lockAddress, tokenId, amount = "0", decimals, erc20Address }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!erc20Address || erc20Address !== ZERO) {
    erc20Address = await lockContract.tokenAddress();
  }
  if (decimals == null) {
    if (erc20Address !== ZERO) {
      decimals = await getErc20Decimals(erc20Address, this.provider);
    } else {
      decimals = 18;
    }
  }
  const actualAmount = utils_default.toDecimal(amount, decimals);
  const transactionPromise = lockContract.expireAndRefundFor(
    tokenId,
    actualAmount
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v10/getTokenIdForOwner.js
async function getTokenIdForOwner_default2(lockAddress, owner, network) {
  const lockContract = await this.getLockContract(
    lockAddress,
    this.providerForNetwork(network)
  );
  const validTokens = [];
  const allTokens = [];
  const balanceOfTokens = await lockContract.balanceOf(owner);
  let i = 0;
  while (i < balanceOfTokens) {
    let tokenId = await lockContract.tokenOfOwnerByIndex(owner, i);
    if (tokenId) {
      allTokens.push(tokenId);
      let expiration = await lockContract.keyExpirationTimestampFor(tokenId);
      if (expiration > (/* @__PURE__ */ new Date()).getTime() * 1e3) {
        validTokens.push(tokenId);
      }
    }
    i++;
  }
  return validTokens[0] || allTokens[0] || 0;
}

// src/PublicLock/v10/getKeyExpirationByLockForOwner.js
async function getKeyExpirationByLockForOwner_default2(lockAddress, owner, network) {
  const lockContract = await this.getLockContract(
    lockAddress,
    this.providerForNetwork(network)
  );
  const tokenId = await getTokenIdForOwner_default2.bind(this)(
    lockAddress,
    owner,
    network
  );
  const expiration = await lockContract.keyExpirationTimestampFor(tokenId);
  if (expiration === ETHERS_MAX_UINT) {
    return -1;
  }
  return parseInt(expiration, 10);
}

// src/PublicLock/v10/getCancelAndRefundValueFor.js
async function getCancelAndRefundValueFor2({ lockAddress, tokenAddress, tokenId }, transactionOptions = {}, provider) {
  const lockContract = await this.getLockContract(lockAddress, provider);
  const value = await lockContract.getCancelAndRefundValue(tokenId);
  let refundValue;
  if (!tokenAddress || tokenAddress === ZERO) {
    refundValue = utils_default.fromWei(value, "ether");
  } else {
    const decimals = await getErc20Decimals(tokenAddress, this.provider);
    refundValue = utils_default.fromDecimal(value, decimals);
  }
  return refundValue;
}
var getCancelAndRefundValueFor_default2 = getCancelAndRefundValueFor2;

// src/PublicLock/v10/getLock.js
async function getLock_default2(address, provider, options = {}) {
  const update = await parseLockGetters_default.bind(this)(address, provider, options);
  if (update.currencyContractAddress) {
    const erc20LockAllowance = await getAllowance(
      update.currencyContractAddress,
      address,
      provider,
      address
    );
    update.selfAllowance = erc20LockAllowance.toString();
  }
  return update;
}

// src/PublicLock/v10/renewMembershipFor.js
async function renewMembershipFor_default({ lockAddress, referrer, tokenId }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  if (!referrer) {
    referrer = ZERO;
  }
  if (!transactionOptions?.gasLimit) {
    try {
      const gasLimit = await lockContract.renewMembershipFor.estimateGas(
        tokenId,
        referrer,
        transactionOptions
      );
      transactionOptions.gasLimit = gasLimit * 13 / 10;
    } catch (error) {
      console.error(
        "We could not estimate gas ourselves. Let wallet do it.",
        error
      );
    }
  }
  const transactionPromise = lockContract.renewMembershipFor(
    tokenId,
    referrer,
    transactionOptions
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  if (receipt.status === 0) {
    throw new Error("Transaction failed");
  }
  return tokenId;
}

// src/PublicLock/v10/index.js
var {
  grantKey,
  grantKeys,
  updateKeyPrice,
  withdrawFromLock,
  initializeTemplate,
  isLockManager,
  isKeyGranter,
  addKeyGranter,
  removeKeyGranter,
  cancelAndRefund,
  keyManagerOf,
  setMaxNumberOfKeys: setMaxNumberOfKeys3,
  setExpirationDuration: setExpirationDuration2,
  approveBeneficiary,
  totalKeys,
  updateLockName,
  updateLockSymbol,
  setBaseTokenURI,
  addLockManager,
  renounceLockManager,
  updateRefundPenalty,
  setEventHooks,
  updateTransferFee,
  setKeyManagerOf,
  transferFrom,
  setGasRefundValue
} = v9_default;
var v10_default = {
  version: "v10",
  PublicLock: abis_default.PublicLock.v10,
  grantKey,
  grantKeys,
  getPurchaseKeysArguments,
  extendKey: extendKey_default,
  updateKeyPrice,
  withdrawFromLock,
  initializeTemplate,
  isLockManager,
  isKeyGranter,
  addKeyGranter,
  removeKeyGranter,
  expireAndRefundFor: expireAndRefundFor_default2,
  cancelAndRefund,
  shareKey: shareKey_default2,
  getLock: getLock_default2,
  purchaseKey: purchaseKey_default2,
  purchaseKeys: purchaseKeys_default2,
  mergeKeys: mergeKeys_default,
  keyManagerOf,
  setMaxNumberOfKeys: setMaxNumberOfKeys3,
  setExpirationDuration: setExpirationDuration2,
  setMaxKeysPerAddress: setMaxKeysPerAddress_default,
  getTokenIdForOwner: getTokenIdForOwner_default2,
  getKeyExpirationByLockForOwner: getKeyExpirationByLockForOwner_default2,
  getCancelAndRefundValueFor: getCancelAndRefundValueFor_default2,
  approveBeneficiary,
  totalKeys,
  renewMembershipFor: renewMembershipFor_default,
  updateLockName,
  updateLockSymbol,
  setBaseTokenURI,
  addLockManager,
  renounceLockManager,
  updateRefundPenalty,
  setEventHooks,
  updateTransferFee,
  setKeyManagerOf,
  transferFrom,
  setGasRefundValue
};

// src/PublicLock/v11/grantKeyExtension.js
async function grantKeyExtension_default({ lockAddress, tokenId, duration = 0 }, transactionOptions = {}, callback) {
  if (!tokenId) {
    throw new Error("Missing tokenId.");
  }
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.grantKeyExtension(tokenId, duration);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v11/totalKeys.js
async function totalKeys_default2(lockAddres, owner, provider) {
  const lockContract = await this.getLockContract(lockAddres, provider);
  return lockContract.totalKeys(owner);
}

// src/PublicLock/v11/setEventHooks.js
async function setEventHooks_default2({
  lockAddress,
  keyPurchase = ZERO,
  keyCancel = ZERO,
  validKey = ZERO,
  tokenURI = ZERO,
  keyTransfer = ZERO
}, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setEventHooks(
    keyPurchase,
    keyCancel,
    validKey,
    tokenURI,
    keyTransfer
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v11/setReferrerFee.js
async function setReferrerFee_default({ lockAddress, address, feeBasisPoint }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.setReferrerFee(address, feeBasisPoint);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v11/getTokenIdForOwner.js
async function getTokenIdForOwner_default3(lockAddress, owner, network) {
  const lockContract = await this.getLockContract(
    lockAddress,
    this.providerForNetwork(network)
  );
  const validTokens = [];
  const allTokens = [];
  const balanceOfTokens = await lockContract.totalKeys(owner);
  let i = 0;
  while (i < balanceOfTokens) {
    let tokenId = await lockContract.tokenOfOwnerByIndex(owner, i);
    if (tokenId) {
      allTokens.push(tokenId);
      let expiration = await lockContract.keyExpirationTimestampFor(tokenId);
      if (expiration > (/* @__PURE__ */ new Date()).getTime() * 1e3) {
        validTokens.push(tokenId);
      }
    }
    i++;
  }
  return validTokens[0] || allTokens[0] || 0;
}

// src/PublicLock/v11/lendKey.js
async function lendKey_default({ lockAddress, from, to, tokenId }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress, this.provider);
  const transactionPromise = lockContract.lendKey(from, to, tokenId);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return true;
}

// src/PublicLock/v11/index.js
var v11_default = {
  ...v10_default,
  totalKeys: totalKeys_default2,
  grantKeyExtension: grantKeyExtension_default,
  setEventHooks: setEventHooks_default2,
  setReferrerFee: setReferrerFee_default,
  getTokenIdForOwner: getTokenIdForOwner_default3,
  lendKey: lendKey_default,
  version: "v11",
  PublicLock: abis_default.PublicLock.v11
};

// src/PublicLock/v12/setMaxKeysPerAddress.js
async function setMaxKeysPerAddress_default2({ lockAddress, maxKeysPerAddress }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const expirationDuration = await lockContract.expirationDuration();
  const maxNumberOfKeys = await lockContract.maxNumberOfKeys();
  const supply = await lockContract.totalSupply();
  const transactionPromise = lockContract.updateLockConfig(
    expirationDuration,
    supply < maxNumberOfKeys ? maxNumberOfKeys : supply,
    maxKeysPerAddress
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}

// src/PublicLock/v12/setMaxNumberOfKeys.js
async function setMaxNumberOfKeys4({ lockAddress, maxNumberOfKeys }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const maxKeysPerAddress = await lockContract.maxKeysPerAddress();
  const expirationDuration = await lockContract.expirationDuration();
  const supply = await lockContract.totalSupply();
  const transactionPromise = lockContract.updateLockConfig(
    expirationDuration,
    supply < maxNumberOfKeys ? maxNumberOfKeys : supply,
    maxKeysPerAddress
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}
var setMaxNumberOfKeys_default2 = setMaxNumberOfKeys4;

// src/PublicLock/v12/setExpirationDuration.js
async function setExpirationDuration3({ lockAddress, expirationDuration }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const maxKeysPerAddress = await lockContract.maxKeysPerAddress();
  const maxNumberOfKeys = await lockContract.maxNumberOfKeys();
  const supply = await lockContract.totalSupply();
  const transactionPromise = lockContract.updateLockConfig(
    expirationDuration,
    supply < maxNumberOfKeys ? maxNumberOfKeys : supply,
    maxKeysPerAddress
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
}
var setExpirationDuration_default2 = setExpirationDuration3;

// src/PublicLock/v12/setBaseTokenURI.js
async function setBaseTokenURI2({ lockAddress, baseTokenURI }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const lockName = await lockContract.name();
  const lockSymbol = await lockContract.symbol();
  const transactionPromise = lockContract.setLockMetadata(
    lockName,
    lockSymbol,
    baseTokenURI
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return baseTokenURI;
}
var setBaseTokenURI_default2 = setBaseTokenURI2;

// src/PublicLock/v12/updateLockName.js
async function updateLockName2({ lockAddress, name: name2 }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const lockSymbol = await lockContract.symbol();
  const baseTokenURI = await lockContract.tokenURI(0);
  const transactionPromise = lockContract.setLockMetadata(
    name2,
    lockSymbol,
    baseTokenURI
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return name2;
}
var updateLockName_default2 = updateLockName2;

// src/PublicLock/v12/updateLockSymbol.js
async function updateLockSymbol2({ lockAddress, symbol }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const lockName = await lockContract.name();
  const baseTokenURI = await lockContract.tokenURI(0);
  const transactionPromise = lockContract.setLockMetadata(
    lockName,
    symbol,
    baseTokenURI
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return symbol;
}
var updateLockSymbol_default2 = updateLockSymbol2;

// src/PublicLock/v12/approveBeneficiary.js
async function approveBeneficiary_default2() {
  throw Error(
    "The concept of beneficiary has been deprecated in PublicLock v12"
  );
}

// src/PublicLock/v12/withdrawFromLock.js
async function withdrawFromLock_default2({ lockAddress, beneficiary, amount = "0", decimals, erc20Address }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const tokenAddress = erc20Address || await lockContract.tokenAddress();
  if (decimals == null) {
    if (tokenAddress && tokenAddress !== ZERO) {
      decimals = await getErc20Decimals(tokenAddress, this.provider);
    } else {
      decimals = 18;
    }
  }
  if (!beneficiary) {
    beneficiary = await this.signer.getAddress();
  }
  const actualAmount = utils_default.toDecimal(amount, decimals);
  const transactionPromise = lockContract["withdraw(address,address,uint256)"](
    tokenAddress,
    beneficiary,
    actualAmount
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  const parser = lockContract.interface;
  const withdrawalEvent = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  }).find(({ fragment }) => fragment && fragment.name === "Withdrawal");
  if (withdrawalEvent) {
    return utils_default.fromWei(withdrawalEvent.args.amount.toString(), "ether");
  }
  return null;
}

// src/PublicLock/v12/setEventHooks.js
async function setEventHooks_default3({
  lockAddress,
  keyPurchase = ZERO,
  keyCancel = ZERO,
  validKey = ZERO,
  tokenURI = ZERO,
  keyTransfer = ZERO,
  keyExtend = ZERO,
  keyGrant = ZERO
}, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const transactionPromise = lockContract.setEventHooks(
    keyPurchase,
    keyCancel,
    validKey,
    tokenURI,
    keyTransfer,
    keyExtend,
    keyGrant
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash);
  }
  await this.provider.waitForTransaction(hash);
  return null;
}

// src/PublicLock/v12/index.js
var v12_default = {
  ...v11_default,
  approveBeneficiary: approveBeneficiary_default2,
  setMaxKeysPerAddress: setMaxKeysPerAddress_default2,
  setExpirationDuration: setExpirationDuration_default2,
  setMaxNumberOfKeys: setMaxNumberOfKeys_default2,
  setBaseTokenURI: setBaseTokenURI_default2,
  updateLockName: updateLockName_default2,
  updateLockSymbol: updateLockSymbol_default2,
  withdrawFromLock: withdrawFromLock_default2,
  setEventHooks: setEventHooks_default3,
  version: "v12",
  PublicLock: abis_default.PublicLock.v12
};

// src/PublicLock/v13/addKeyGranter.js
import { ethers as ethers5 } from "ethers";
async function addKeyGranter_default2({ lockAddress, keyGranter }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const keyGranterRole = ethers5.keccak256(ethers5.toUtf8Bytes("KEY_GRANTER"));
  const transactionPromise = lockContract.grantRole(keyGranterRole, keyGranter);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return true;
}

// src/PublicLock/v13/removeKeyGranter.js
import { ethers as ethers6 } from "ethers";
async function removeKeyGranter_default2({ lockAddress, keyGranter }, transactionOptions = {}, callback) {
  const lockContract = await this.getLockContract(lockAddress);
  const keyGranterRole = ethers6.keccak256(ethers6.toUtf8Bytes("KEY_GRANTER"));
  const transactionPromise = lockContract.revokeRole(keyGranterRole, keyGranter);
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  await this.provider.waitForTransaction(hash);
  return true;
}

// src/PublicLock/v13/isKeyGranter.js
import { ethers as ethers7 } from "ethers";
async function isKeyGranter_default2(lockAddress, address, provider) {
  const lockContract = await this.getLockContract(lockAddress, provider);
  const keyGranterRole = ethers7.keccak256(ethers7.toUtf8Bytes("KEY_GRANTER"));
  return lockContract.hasRole(keyGranterRole, address);
}

// src/PublicLock/v13/index.js
var v13_default = {
  ...v12_default,
  version: "v13",
  addKeyGranter: addKeyGranter_default2,
  removeKeyGranter: removeKeyGranter_default2,
  isKeyGranter: isKeyGranter_default2,
  PublicLock: abis_default.PublicLock.v13
};

// src/PublicLock/v14/index.js
var v14_default = {
  ...v13_default,
  version: "v14",
  PublicLock: abis_default.PublicLock.v14
};

// src/PublicLock/index.ts
var PublicLock_default = {
  v8: v9_default,
  v9: v9_default,
  v10: v10_default,
  v11: v11_default,
  v12: v12_default,
  v13: v13_default,
  v14: v14_default
};

// src/Unlock/v11/createLock.js
import { ethers as ethers8 } from "ethers";

// src/Unlock/utils.ts
async function _getKeyPrice(lock, provider) {
  const currencyContractAddress = lock.currencyContractAddress || ZERO;
  if (typeof lock.keyPrice === "number") {
    lock.keyPrice = lock.keyPrice.toString();
  }
  if (currencyContractAddress !== ZERO) {
    const erc20Decimals = await getErc20Decimals(
      currencyContractAddress,
      provider
    );
    return utils_default.toDecimal(lock.keyPrice, erc20Decimals);
  }
  return utils_default.toWei(lock.keyPrice, "ether");
}

// src/Unlock/v11/createLock.js
async function createLock_default(lock, transactionOptions = {}, callback) {
  const unlockContract = await this.getUnlockContract();
  const lockVersion = lock.publicLockVersion || await unlockContract.publicLockLatestVersion();
  let { maxNumberOfKeys, expirationDuration } = lock;
  if (typeof maxNumberOfKeys !== "number" || maxNumberOfKeys === UNLIMITED_KEYS_COUNT) {
    maxNumberOfKeys = ETHERS_MAX_UINT;
  }
  if (expirationDuration === -1) {
    expirationDuration = ETHERS_MAX_UINT;
  }
  const decimalKeyPrice = await _getKeyPrice(lock, this.provider);
  const currencyContractAddress = lock.currencyContractAddress || ZERO;
  const lockName = lock.name;
  const signerAddress = await this.signer.getAddress();
  const lockCreator = lock.creator || signerAddress;
  if (!lockCreator) {
    throw new Error("No lock creator passed or found.");
  }
  const { abi: lockAbi } = abis_default.PublicLock[`v${lockVersion}`];
  const lockInterface = new ethers8.Interface(lockAbi);
  const calldata = lockInterface.encodeFunctionData(
    "initialize(address,uint256,address,uint256,uint256,string)",
    [
      lockCreator,
      expirationDuration,
      currencyContractAddress,
      decimalKeyPrice,
      maxNumberOfKeys,
      lockName
    ]
  );
  const transactionPromise = unlockContract.createUpgradeableLockAtVersion(
    calldata,
    lockVersion,
    transactionOptions
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const { logs } = await this.provider.waitForTransaction(hash);
  const parsedLogs = logs.map((log) => unlockContract.interface.parseLog(log)).map((log) => log || {});
  const newLockEvent = parsedLogs.find(
    ({ fragment }) => fragment && fragment.name === "NewLock"
  );
  if (newLockEvent) {
    return newLockEvent.args.newLockAddress;
  }
  return null;
}

// src/Unlock/v11/upgradeLock.js
async function upgradeLock_default(lockAddress, lockVersion, callback) {
  if (typeof lockVersion !== "bigint")
    throw Error("lockVersion should be a bigint");
  const unlockContract = await this.getUnlockContract();
  const transactionPromise = unlockContract.upgradeLock(
    lockAddress,
    lockVersion
  );
  const hash = await this._handleMethodCall(transactionPromise);
  if (callback) {
    callback(null, hash, await transactionPromise);
  }
  const receipt = await this.provider.waitForTransaction(hash);
  const parser = unlockContract.interface;
  const unlockAddress = await unlockContract.getAddress();
  const logs = receipt.logs.map((log) => {
    if (log.address.toLowerCase() !== unlockAddress.toLowerCase()) return;
    return parser.parseLog(log);
  });
  const upgradeLockEvent = logs.find(
    (event) => event?.fragment && event.fragment.name === "LockUpgraded"
  );
  if (upgradeLockEvent) {
    return upgradeLockEvent.args.version;
  }
  return null;
}

// src/Unlock/v11/index.js
var v11_default2 = {
  createLock: createLock_default,
  upgradeLock: upgradeLock_default,
  version: "v11",
  Unlock: abis_default.Unlock.v11
};

// src/Unlock/v12/index.ts
var { upgradeLock, createLock } = v11_default2;
var v12_default2 = {
  createLock,
  upgradeLock,
  version: "v12",
  Unlock: abis_default.Unlock.v12
};

// src/Unlock/v13/index.ts
var { upgradeLock: upgradeLock2, createLock: createLock2 } = v12_default2;
var v13_default2 = {
  createLock: createLock2,
  upgradeLock: upgradeLock2,
  version: "v13",
  Unlock: abis_default.Unlock.v13
};

// src/Unlock/index.ts
var Unlock_default = {
  v11: v11_default2,
  v12: v12_default2,
  v13: v13_default2
};

// src/unlockService.ts
var UnlockService = class {
  constructor(networks2) {
    this.networks = networks2;
    this.versionForAddress = {};
  }
  providerForNetwork(networkId) {
    if (!this.networks[networkId]) {
      throw new Error(`Missing config for ${networkId}`);
    }
    const ethersProvider = this.networks[networkId].ethersProvider;
    if (ethersProvider) {
      return ethersProvider;
    }
    return new ethers9.JsonRpcProvider(
      this.networks[networkId].provider,
      networkId
    );
  }
  /**
   * Checks if the contract has been deployed at the address.
   * Invokes the callback with the result.
   * Addresses which do not have a contract attached will return 0x
   */
  async isUnlockContractDeployed(network) {
    if (!this.networks[network]) {
      throw new Error(`Missing config for ${network}`);
    }
    const opCode = await this.providerForNetwork(network).getCode(this.networks[network].unlockAddress);
    return opCode !== "0x";
  }
  /**
   * @param {string} address contract address
   * @param {string} versionRetrievalMethodName the method to call to retrieve the contract version
   */
  async contractAbiVersion(address, versionRetrievalMethodName, provider) {
    const contractAddress = address.toLowerCase();
    let version = this.versionForAddress[contractAddress];
    if (version === void 0) {
      version = await this[versionRetrievalMethodName](
        contractAddress,
        provider
      );
      this.versionForAddress[contractAddress] = version;
    }
    const contractName = versionRetrievalMethodName.includes("PublicLock") ? "PublicLock" : "Unlock";
    if (contractName === "PublicLock" && PublicLock_default[`v${version}`]) {
      return PublicLock_default[`v${version}`];
    }
    if (contractName === "Unlock" && Unlock_default[`v${version}`]) {
      return Unlock_default[`v${version}`];
    }
    throw new Error(
      `Contract ${address} (${contractName}) not deployed on ${(await provider.getNetwork()).chainId}, or unknown version ${version}`
    );
  }
  async unlockContractAbiVersion(address, provider) {
    return this.contractAbiVersion(
      address,
      "_getUnlockVersionFromContract",
      provider
    );
  }
  /**
   * Returns the ABI for the Lock contract deployed at the provided address
   * @param {*} address
   */
  async lockContractAbiVersion(address, provider) {
    return this.contractAbiVersion(
      address,
      "_getPublicLockVersionFromContract",
      provider
    );
  }
  /**
   * Private method, which given an address will query the lock and return the version of the lock
   * @param {*} address
   */
  async _getPublicLockVersionFromContract(address, provider) {
    const contract = new ethers9.Contract(
      address,
      ["function publicLockVersion() view returns (uint8)"],
      provider
    );
    let version = 0;
    try {
      const contractVersion = await contract.publicLockVersion();
      version = parseInt(contractVersion, 10) || 0;
    } catch (error) {
      console.error(error);
      console.error(
        `We could not retrieve the version of the Unlock contract ${address} on this network.`
      );
    }
    return version;
  }
  /**
   * Private method, which given an address will query the unlock contract to get its version
   * @param {*} address
   */
  async _getUnlockVersionFromContract(address, provider) {
    const contract = new ethers9.Contract(
      address,
      ["function unlockVersion() view returns (uint8)"],
      provider
    );
    let version = 0;
    try {
      const contractVersion = await contract.unlockVersion();
      version = parseInt(contractVersion, 10) || 0;
    } catch (error) {
      const opCode = await provider.getCode(address);
      const hash = ethers9.sha256(opCode);
      if (hash === "0x886b9da11c0a665e98fd914bc79908925a4f6a549286de92ee6825e441a26309") {
        version = 1;
      }
    }
    return version;
  }
  getContract(address, contractAbi, provider) {
    return new ethers9.Contract(
      address,
      new ethers9.Interface(contractAbi),
      provider
    );
  }
  async getLockContract(lockAddress, provider) {
    const version = await this.lockContractAbiVersion(lockAddress, provider);
    return this.getContract(lockAddress, version.PublicLock.abi, provider);
  }
  async getUnlockContract(unlockAddress, provider) {
    const version = await this.unlockContractAbiVersion(unlockAddress, provider);
    return this.getContract(unlockAddress, version.Unlock.abi, provider);
  }
  async getHookContract({
    network,
    address,
    abi
  }) {
    const provider = this.providerForNetwork(network);
    return new ethers9.Contract(address, abi, provider);
  }
};

// src/abis/passwordHookAbi.ts
var passwordHookAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  { inputs: [], name: "NOT_AUTHORIZED", type: "error" },
  { inputs: [], name: "WRONG_PASSWORD", type: "error" },
  {
    inputs: [
      { internalType: "string", name: "message", type: "string" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "getSigner",
    outputs: [
      { internalType: "address", name: "recoveredAddress", type: "address" }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "keyPurchasePrice",
    outputs: [
      { internalType: "uint256", name: "minKeyPrice", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "", type: "bytes" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "onKeyPurchase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "lock", type: "address" },
      { internalType: "address", name: "signer", type: "address" }
    ],
    name: "setSigner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "signers",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "value", type: "uint256" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes", name: "data", type: "bytes" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "value", type: "bytes32" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  }
];

// src/abis/discountCodeHookAbi.ts
var discountCodeHookAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  { inputs: [], name: "NOT_AUTHORIZED", type: "error" },
  { inputs: [], name: "TOO_BIG", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "discounts",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "string", name: "message", type: "string" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "getSigner",
    outputs: [
      { internalType: "address", name: "recoveredAddress", type: "address" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "keyPurchasePrice",
    outputs: [
      { internalType: "uint256", name: "minKeyPrice", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "", type: "bytes" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "onKeyPurchase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "lock", type: "address" },
      { internalType: "address", name: "signer", type: "address" },
      { internalType: "uint256", name: "discount", type: "uint256" }
    ],
    name: "setDiscountForLock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "value", type: "uint256" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes", name: "data", type: "bytes" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "value", type: "bytes32" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  }
];

// src/abis/passwordCapHookAbi.ts
var passwordCapHookAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  { inputs: [], name: "NOT_AUTHORIZED", type: "error" },
  { inputs: [], name: "WRONG_PASSWORD", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "counters",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "string", name: "message", type: "string" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "getSigner",
    outputs: [
      { internalType: "address", name: "recoveredAddress", type: "address" }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "keyPurchasePrice",
    outputs: [
      { internalType: "uint256", name: "minKeyPrice", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "onKeyPurchase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "lock", type: "address" },
      { internalType: "address", name: "signer", type: "address" },
      { internalType: "uint256", name: "usages", type: "uint256" }
    ],
    name: "setSigner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "signers",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  }
];

// src/abis/discountCodeWithCapHookAbi.ts
var discountCodeWithCapHookAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  { inputs: [], name: "NOT_AUTHORIZED", type: "error" },
  { inputs: [], name: "TOO_BIG", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "caps",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "counters",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" }
    ],
    name: "discounts",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "string", name: "message", type: "string" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "getSigner",
    outputs: [
      { internalType: "address", name: "recoveredAddress", type: "address" }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" }
    ],
    name: "keyPurchasePrice",
    outputs: [
      { internalType: "uint256", name: "minKeyPrice", type: "uint256" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "bytes", name: "signature", type: "bytes" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" }
    ],
    name: "onKeyPurchase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "lock", type: "address" },
      { internalType: "address", name: "signer", type: "address" },
      { internalType: "uint256", name: "discount", type: "uint256" },
      { internalType: "uint256", name: "cap", type: "uint256" }
    ],
    name: "setSigner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "value", type: "uint256" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes", name: "data", type: "bytes" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "value", type: "bytes32" }],
    name: "toString",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "pure",
    type: "function"
  }
];

// src/web3Service.ts
var Web3Service = class extends UnlockService {
  /**
   * Method which returns the create2 address based on the factory contract (unlock), the lock template,
   * the account and lock salt (both used to create a unique salt)
   * 0x3d602d80600a3d3981f3363d3d373d3d3d363d73 and 5af43d82803e903d91602b57fd5bf3 are the
   * bytecode for eip-1167 (which defines proxies for locks).
   * @private
   */
  _create2Address(unlockAddress, templateAddress, account, lockSalt) {
    const saltHex = `${account}${lockSalt}`;
    const byteCode = `0x3d602d80600a3d3981f3363d3d373d3d3d363d73${templateAddress.replace(
      /0x/,
      ""
    )}5af43d82803e903d91602b57fd5bf3`;
    const byteCodeHash = utils_default.sha3(byteCode);
    const seed = ["ff", unlockAddress, saltHex, byteCodeHash].map((x) => x.replace(/0x/, "")).join("");
    const address = utils_default.sha3(`0x${seed}`).slice(-40);
    return utils_default.toChecksumAddress(`0x${address}`);
  }
  /**
   * Returns details about a transaction
   * @param {*} hash
   * @param {*} network
   * @returns
   */
  async getTransaction(hash, network) {
    return await this.providerForNetwork(network).getTransaction(hash);
  }
  /**
   * This retrieves the balance of an address (contract or account)
   * and formats it to a string of ether.
   * Returns a promise with the balance
   */
  async getAddressBalance(address, network, tokenAddress) {
    const provider = this.providerForNetwork(network);
    if (!tokenAddress) {
      const balance = await provider.getBalance(address);
      return utils_default.fromWei(balance, "ether");
    } else {
      const balancePromise = getErc20BalanceForAddress(
        tokenAddress,
        address,
        provider
      );
      const decimalsPromise = getErc20Decimals(tokenAddress, provider);
      const [balance, decimals] = await Promise.all([
        balancePromise,
        decimalsPromise
      ]);
      return utils_default.fromDecimal(balance, decimals);
    }
  }
  /**
   * Refresh the lock's data.
   * We use the block version
   * @return Promise<Lock>
   */
  async getLock(address, network, options = {
    fields: []
  }) {
    const networkConfig = this.networks[network];
    if (!(networkConfig && networkConfig.unlockAddress)) {
      throw new Error(
        "No unlock factory contract address found in the networks config."
      );
    }
    const provider = this.providerForNetwork(network);
    const version = await this.lockContractAbiVersion(
      address,
      this.providerForNetwork(network)
    );
    const lock = await version.getLock.bind(this)(
      address,
      this.providerForNetwork(network),
      options
    );
    lock.address = address;
    lock.network = network;
    lock.unlockContractAddress = ethers10.getAddress(lock.unlockContractAddress);
    const previousDeployAddresses = (networkConfig.previousDeploys || []).map(
      (d) => ethers10.getAddress(d.unlockAddress)
    );
    const isPreviousUnlockContract = previousDeployAddresses.includes(
      lock.unlockContractAddress
    );
    const isUnlockContract = ethers10.getAddress(networkConfig.unlockAddress) === lock.unlockContractAddress;
    if (!isUnlockContract && !isPreviousUnlockContract) {
      throw new Error(
        "This contract is not deployed from Unlock factory contract."
      );
    }
    const unlockContract = await this.getUnlockContract(
      lock.unlockContractAddress,
      provider
    );
    const response = await unlockContract.locks(address);
    if (!response.deployed) {
      throw new Error(
        "This contract is not deployed from Unlock factory contract."
      );
    }
    return lock;
  }
  /**
   * Tell whether a user is a manager for the lock
   * @param {string} lockAddress
   * @param {string} manager
   * @return Promise<boolean>
   */
  async isLockManager(lockAddress, manager, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!version.isLockManager) {
      throw new Error("Lock version not supported");
    }
    return version.isLockManager.bind(this)(
      lockAddress,
      manager,
      this.providerForNetwork(network)
    );
  }
  /**
   * Returns the key to the lock by the token Id.
   * @param {PropTypes.string} lockAddress
   * @param {PropTypes.number} tokenId
   */
  async getKeyByTokenId(lockAddress, tokenId, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (await lockContract.publicLockVersion() < 10) {
      throw new Error("Only available for Lock v10+");
    }
    const expirationPromise = this.getKeyExpirationByTokenId(
      lockAddress,
      tokenId,
      network
    );
    const ownerPromise = this.ownerOf(lockAddress, tokenId, network);
    const [owner, expiration] = await Promise.all([
      ownerPromise,
      expirationPromise
    ]);
    const keyPayload = {
      tokenId,
      lock: lockAddress,
      owner,
      expiration
    };
    return keyPayload;
  }
  /**
   * Returns the key expiration to the lock by the account.
   * @private
   * @param {PropTypes.string} lockAddress
   * @param {number} tokenId
   * @return Promise<>
   */
  async getKeyExpirationByTokenId(lockAddress, tokenId, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (await lockContract.publicLockVersion() < 10) {
      throw new Error("Only available for Lock v10+");
    }
    try {
      const expiration = await lockContract.keyExpirationTimestampFor(tokenId);
      if (expiration == "3963877391197344453575983046348115674221700746820753546331534351508065746944") {
        return 0;
      }
      if (expiration === ETHERS_MAX_UINT) {
        return -1;
      }
      return parseInt(expiration, 10);
    } catch (error) {
      return 0;
    }
  }
  /**
   * Returns the key to the lock by the account.
   * @param {PropTypes.string} lockAddress
   * @param {PropTypes.string} owner
   */
  async getKeyByLockForOwner(lockAddress, owner, network) {
    const keyPayload = {
      lock: lockAddress,
      owner,
      expiration: 0,
      tokenId: 0
    };
    const tokenIdPromise = this.getTokenIdForOwner(lockAddress, owner, network);
    const expirationPromise = this.getKeyExpirationByLockForOwner(
      lockAddress,
      owner,
      network
    );
    const [tokenId, expiration] = await Promise.all([
      tokenIdPromise,
      expirationPromise
    ]);
    keyPayload.tokenId = tokenId;
    keyPayload.expiration = expiration;
    return keyPayload;
  }
  /**
   * Returns true if the address has a valid key (will call the hook when applicable!)
   * @param lockAddress
   * @param owner
   * @param network
   */
  async getHasValidKey(lockAddress, owner, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return lockContract.getHasValidKey(owner);
  }
  /**
   * Returns the key expiration to the lock by the account.
   * @private
   * @param {PropTypes.string} lockAddress
   * @param {PropTypes.string} owner
   * @return Promise<>
   */
  async getKeyExpirationByLockForOwner(lockAddress, owner, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    return version.getKeyExpirationByLockForOwner.bind(this)(
      lockAddress,
      owner,
      network
    );
  }
  /**
   * Returns the key expiration to the lock by the account.
   * @private
   * @param {PropTypes.string} lockAddress
   * @param {PropTypes.string} owner
   * @return Promise<>
   */
  async getTokenIdForOwner(lockAddress, owner, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    return version.getTokenIdForOwner.bind(this)(lockAddress, owner, network);
  }
  /**
   * Given some data and a signed version of the same, returns the address of the account that signed it
   * @param data
   * @param signedData
   * @returns {Promise<*>}
   */
  async recoverAccountFromSignedData(data, signedData) {
    return utils_default.verifyMessage(data, signedData);
  }
  /**
   * Given an ERC20 token contract address, resolve with the symbol that identifies that token.
   * @param {string} contractAddress
   * @returns {Promise<string>}
   */
  async getTokenSymbol(contractAddress, network) {
    const symbolPromise = getErc20TokenSymbol(
      contractAddress,
      this.providerForNetwork(network)
    );
    return symbolPromise;
  }
  /**
   * Given an ERC20 token contract address, resolve with the provided user's balance of that token.
   * @param {string} contractAddress
   * @param {string} userWalletAddress
   * @returns {Promise<string>}
   */
  async getTokenBalance(contractAddress, userWalletAddress, network) {
    const balancePromise = getErc20BalanceForAddress(
      contractAddress,
      userWalletAddress,
      this.providerForNetwork(network)
    );
    const decimalsPromise = getErc20Decimals(
      contractAddress,
      this.providerForNetwork(network)
    );
    const [balance, decimals] = await Promise.all([
      balancePromise,
      decimalsPromise
    ]);
    return utils_default.fromDecimal(balance, decimals);
  }
  async getTokenDecimals(contractAddress, network) {
    const provider = this.providerForNetwork(network);
    if (contractAddress !== ZERO) {
      const decimals = await getErc20Decimals(contractAddress, provider);
      return decimals;
    } else {
      return DEFAULT_TOKEN_DECIMALS;
    }
  }
  /**
   * Yields true if an address is key granter on a lock
   */
  async isKeyGranter(lockAddress, address, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!version.isKeyGranter) {
      throw new Error("Lock version not supported");
    }
    return version.isKeyGranter.bind(this)(
      lockAddress,
      address,
      this.providerForNetwork(network)
    );
  }
  /**
   * Retrieves the key manager for a key
   * @param {*} lockAddress
   * @param {*} tokenId
   * @param {*} network
   */
  async keyManagerOf(lockAddress, tokenId, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!version.keyManagerOf) {
      throw new Error("Lock version not supported");
    }
    return version.keyManagerOf.bind(this)(
      lockAddress,
      tokenId,
      this.providerForNetwork(network)
    );
  }
  /**
   * Returns the owner of a key
   * @param {*} lockAddress
   * @param {*} tokenId
   * @param {*} network
   */
  async ownerOf(lockAddress, tokenId, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return lockContract.ownerOf(tokenId);
  }
  /**
   * Returns id a key is valid or not
   * @param {*} lockAddress
   * @param {*} tokenId
   * @param {*} network
   */
  async isValidKey(lockAddress, tokenId, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return lockContract.isValidKey(tokenId);
  }
  /**
   * Returns the Ethers contract 'connected' (should be used with care)
   * @param {*} lockAddress
   * @param {*} network
   */
  async lockContract(lockAddress, network) {
    return await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
  }
  /**
   * Returns numbers of owners for a specific lock
   * @param {*} lockAddress
   * @param {*} network
   */
  async numberOfOwners(lockAddress, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return await lockContract.numberOfOwners();
  }
  /**
   * Returns transfer fee for lock
   * @param {*} lockAddress
   * @param {*} network
   */
  async transferFeeBasisPoints(lockAddress, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!lockContract.transferFeeBasisPoints) {
      throw new Error("Lock version is not supported");
    }
    return await lockContract.transferFeeBasisPoints();
  }
  /**
   * Returns total of key for a specific address
   * @param {String} lockAddress
   * @param {String} address
   * @param {Number} network
   */
  async totalKeys(lockAddress, owner, network) {
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!version.totalKeys) {
      throw new Error("Lock version not supported");
    }
    const count = await version.totalKeys.bind(this)(
      lockAddress,
      owner,
      this.providerForNetwork(network)
    );
    return count;
  }
  /**
   * Returns lock version
   * @param {String} lockAddress
   * @param {Number} network
   */
  async publicLockVersion(lockAddress, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return await lockContract.publicLockVersion();
  }
  async tokenURI(lockAddress, tokenId, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return await lockContract.tokenURI(tokenId);
  }
  /**
   * Returns the number of keys available for sale
   * @param lockAddress
   * @param network
   * @returns
   */
  async keysAvailable(lockAddress, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const totalSupplyPromise = lockContract.totalSupply();
    const maxNumberOfKeysPromise = lockContract.maxNumberOfKeys();
    const [totalSupply, maxNumberOfKeys] = await Promise.all([
      totalSupplyPromise,
      maxNumberOfKeysPromise
    ]);
    return maxNumberOfKeys - totalSupply;
  }
  /**
   * Returns how much of a refund a key owner would receive
   * @param lockAddress
   * @param network
   * @param owner
   * @param tokenAddress
   * @param tokenId
   * @returns
   */
  async getCancelAndRefundValueFor(params, transactionOptions, callback) {
    const { lockAddress, network } = params;
    const version = await this.lockContractAbiVersion(
      lockAddress,
      this.providerForNetwork(network)
    );
    if (!version.getCancelAndRefundValueFor) {
      throw new Error("Lock version not supported");
    }
    return await version.getCancelAndRefundValueFor.bind(this)(
      params,
      transactionOptions,
      this.providerForNetwork(network)
    );
  }
  // For <= v10, it returns the total number of keys.
  // Starting with v11, it returns the total number of valid
  async balanceOf(lockAddress, owner, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const balance = await lockContract.balanceOf(owner);
    return balance;
  }
  // Return key ID of owner at the specified index.
  // If a owner has multiple keys, you can iterate over all of them starting from 0 as index until you hit a zero value which implies no more.
  async tokenOfOwnerByIndex(lockAddress, owner, index, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const id = await lockContract.tokenOfOwnerByIndex(owner, index);
    return id;
  }
  // Return the latest key ID of owner.
  async latestTokenOfOwner(lockAddress, owner, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const totalKeys2 = await lockContract.totalKeys(owner);
    if (totalKeys2 > 0) {
      const id = await lockContract.tokenOfOwnerByIndex(owner, totalKeys2 - 1n);
      return id;
    }
    return null;
  }
  /**
   * Returns the number of keys already sold
   * @param lockAddress
   * @param network
   * @returns
   */
  async totalSupply(lockAddress, network) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    return await lockContract.totalSupply();
  }
  /**
   * Returns the purchase price for the user on the lock
   */
  async purchasePriceFor({
    lockAddress,
    userAddress,
    data,
    referrer,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const price = await lockContract.purchasePriceFor(
      userAddress,
      referrer,
      data
    );
    return price;
  }
  /**
   * Returns freeTrialLength value
   */
  async freeTrialLength({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const freeTrialLength = await lockContract.freeTrialLength();
    return freeTrialLength;
  }
  /**
   * Returns refundPenaltyBasisPoints value
   */
  async refundPenaltyBasisPoints({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const refundPenaltyBasisPoints = await lockContract.refundPenaltyBasisPoints();
    return refundPenaltyBasisPoints;
  }
  /**
   * Returns onKeyCancelHook value
   */
  async onKeyCancelHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onKeyCancelHook();
    return address;
  }
  /**
   * Returns onKeyPurchaseHook value
   */
  async onKeyPurchaseHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onKeyPurchaseHook();
    return address;
  }
  /**
   * Returns onKeyTransferHook value
   */
  async onKeyTransferHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onKeyTransferHook();
    return address;
  }
  /**
   * Returns onTokenURIHook value
   */
  async onTokenURIHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onTokenURIHook();
    return address;
  }
  /**
   * Returns onValidKeyHook value
   */
  async onValidKeyHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onValidKeyHook();
    return address;
  }
  /**
   * Returns onKeyExtendHook value
   */
  async onKeyExtendHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onKeyExtendHook();
    return address;
  }
  /**
   * Returns onKeyGrantHook value
   */
  async onKeyGrantHook({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const address = await lockContract.onKeyGrantHook();
    return address;
  }
  /**
   * Returns last lock version
   * @param {Number} network
   */
  async publicLockLatestVersion(network) {
    const provider = this.providerForNetwork(network);
    const networkConfig = this.networks[network];
    const unlockAddress = networkConfig.unlockAddress;
    if (!unlockAddress) {
      throw new Error("unlockAddress is not defined for the provided network. ");
    }
    const unlockContract = await this.getUnlockContract(unlockAddress, provider);
    return await unlockContract.publicLockLatestVersion();
  }
  /**
   * Returns referrer fees
   */
  async referrerFees({
    lockAddress,
    network,
    address
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const referrerFees = await lockContract.referrerFees(address);
    return referrerFees;
  }
  async getBaseTokenURI({
    lockAddress,
    network
  }) {
    const lockContract = await this.getLockContract(
      lockAddress,
      this.providerForNetwork(network)
    );
    const tokenURI = await lockContract.tokenURI(1);
    const baseTokenURI = tokenURI.substring(0, tokenURI.lastIndexOf("/") + 1);
    return baseTokenURI;
  }
  /**
   * Returns an object the contains the resolved address or ens
   * name of the input address with it's type
   */
  async resolveName(addressOrEns) {
    const provider = this.providerForNetwork(1);
    try {
      const address = addressOrEns.trim();
      const isNotENS = ethers10.isAddress(address);
      if (isNotENS) {
        return {
          input: address,
          address,
          name: address,
          type: "address"
        };
      } else {
        const name2 = address;
        const resolvedName = await provider.resolveName(address);
        if (resolvedName) {
          return {
            input: address,
            name: name2,
            address: resolvedName,
            type: "name"
          };
        } else {
          return {
            input: address,
            name: name2,
            address: resolvedName,
            type: "error"
          };
        }
      }
    } catch (err) {
      console.error(err);
      return "";
    }
  }
  /**
   * Get signer for `Password hook contract`
   */
  async getPasswordHookSigners(params) {
    const { lockAddress, contractAddress, network } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers10.Interface(passwordHookAbi)
    });
    return contract.signers(lockAddress);
  }
  async getTokenIdsFromTx({
    params: { hash, network, lockAddress }
  }) {
    const provider = this.providerForNetwork(network);
    const lockContract = await this.getLockContract(lockAddress, provider);
    const txReceipt = await provider.getTransactionReceipt(hash);
    const events = txReceipt?.logs.map((log) => {
      if (log.address.toLowerCase() !== lockAddress.toLowerCase()) return;
      return lockContract.interface.parseLog(log);
    });
    const purchaseItems = events?.filter(
      (event) => event?.fragment && event?.fragment.name === "Transfer"
    );
    if (purchaseItems?.length) {
      return purchaseItems.map((item) => item?.args?.tokenId?.toString());
    }
    const extendItems = events?.filter(
      (event) => event?.fragment && event?.fragment.name === "KeyExtended"
    );
    if (extendItems?.length) {
      return extendItems.map((item) => item?.args?.tokenId?.toString());
    }
    return null;
  }
  async getGasRefundValue({
    network,
    lockAddress
  }) {
    const provider = this.providerForNetwork(network);
    const lockContract = await this.getLockContract(lockAddress, provider);
    if (!lockContract.gasRefundValue) {
      return "0";
    }
    const gasRefund = await lockContract.gasRefundValue();
    let decimals = this.networks[network].nativeCurrency.decimals;
    const erc20Address = await lockContract.tokenAddress();
    if (erc20Address !== ethers10.ZeroAddress) {
      decimals = await getErc20Decimals(erc20Address, provider);
    }
    return ethers10.formatUnits(gasRefund, decimals);
  }
  /**
   * Get signer for `Password hook contract`
   */
  async getDiscountHookValues(params) {
    const { lockAddress, contractAddress, network, signerAddress } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers10.Interface(discountCodeHookAbi)
    });
    const discountForSigner = await contract.discounts(
      lockAddress,
      signerAddress
    );
    return discountForSigner;
  }
  /**
   * Get signer for `Password hook contract`
   */
  async getDiscountHookWithCapValues(params) {
    const { lockAddress, contractAddress, network, signerAddress } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers10.Interface(discountCodeWithCapHookAbi)
    });
    const discount = await contract.discounts(lockAddress, signerAddress);
    const cap = await contract.caps(lockAddress, signerAddress);
    const count = await contract.counters(lockAddress, signerAddress);
    return {
      discount,
      cap,
      count
    };
  }
  /**
   * Get signer for `Password hook contract`
   */
  async getPasswordHookWithCapValues(params) {
    const { lockAddress, contractAddress, network, signerAddress } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers10.Interface(passwordCapHookAbi)
    });
    const cap = await contract.signers(lockAddress, signerAddress);
    const count = await contract.counters(lockAddress, signerAddress);
    return {
      cap,
      count
    };
  }
};

// src/walletService.ts
import { ethers as ethers12 } from "ethers";

// src/CardPurchaser.ts
import { ethers as ethers11 } from "ethers";

// src/abis/CardPurchaserABI.ts
var CardPurchaserABI = [
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address", name: "_unlockAddress", type: "address" },
      { internalType: "address", name: "_usdc", type: "address" }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  { inputs: [], name: "LOCK_CALL_FAILED", type: "error" },
  { inputs: [], name: "MISSING_LOCK", type: "error" },
  { inputs: [], name: "PURCHASER_DOES_NOT_MATCH_PAYER", type: "error" },
  { inputs: [], name: "SIGNER_DOES_NOT_MATCH", type: "error" },
  { inputs: [], name: "TOO_LATE", type: "error" },
  { inputs: [], name: "TOO_MUCH_SPENT", type: "error" },
  { inputs: [], name: "WITHDRAW_FAILED", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "from", type: "address" },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "value", type: "uint256" },
          { internalType: "uint256", name: "validAfter", type: "uint256" },
          { internalType: "uint256", name: "validBefore", type: "uint256" },
          { internalType: "bytes32", name: "nonce", type: "bytes32" }
        ],
        internalType: "struct CardPurchaser.ApprovalMessage",
        name: "approvalMessage",
        type: "tuple"
      },
      { internalType: "bytes", name: "approvalSignature", type: "bytes" },
      {
        components: [
          { internalType: "address", name: "lock", type: "address" },
          { internalType: "address", name: "sender", type: "address" },
          { internalType: "uint256", name: "expiration", type: "uint256" }
        ],
        internalType: "struct CardPurchaser.PurchaseMessage",
        name: "purchaseMessage",
        type: "tuple"
      },
      { internalType: "bytes", name: "purchaseSignature", type: "bytes" },
      { internalType: "bytes", name: "callData", type: "bytes" }
    ],
    name: "purchase",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "unlockAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "usdc",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" }
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/CardPurchaser.ts
var PurchaseTypes = {
  Purchase: [
    { name: "lock", type: "address" },
    { name: "sender", type: "address" },
    { name: "expiration", type: "uint256" }
  ]
};
var CardPurchaser = class {
  constructor(networks2) {
    this.networks = networks2 || networks;
  }
  providerForNetwork(network) {
    if (!this.networks[network]) {
      throw new Error(`Missing config for ${network}`);
    }
    return new ethers11.JsonRpcProvider(this.networks[network].provider, network);
  }
  /**
   * Returns the contract
   * @param param0
   * @returns
   */
  getContract({ network, signer }) {
    const networkConfig = this.networks[network];
    const cardPurchaserContractAddress = networkConfig?.universalCard?.cardPurchaserAddress;
    if (!cardPurchaserContractAddress) {
      throw new Error("No card purchaser contract address found for network");
    }
    const provider = this.providerForNetwork(network);
    const cardPurchaserContract = new ethers11.Contract(
      cardPurchaserContractAddress,
      CardPurchaserABI,
      provider
    );
    if (signer) {
      return cardPurchaserContract.connect(signer);
    }
    return cardPurchaserContract;
  }
  async getDomain(network) {
    const contract = this.getContract({ network });
    const [name2, version] = await Promise.all([
      contract.getFunction("name")(),
      contract.getFunction("version")()
    ]);
    return {
      name: name2,
      version,
      chainId: network,
      verifyingContract: await contract.getAddress()
    };
  }
  /**
   * Returns a message and corresponding signature to perform a purchase
   * These will be submitted to the purchase function on the CardPurchaser contract
   * @param network
   * @param lockAddress
   * @param signer
   * @returns
   */
  async getPurchaseAuthorizationSignature(network, lockAddress, signer) {
    const networkConfig = this.networks[network];
    const cardPurchaserAddress = networkConfig?.universalCard?.cardPurchaserAddress;
    if (!cardPurchaserAddress) {
      throw new Error("Card Purchaser not available for this network");
    }
    const domain = await this.getDomain(network);
    const now = Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3);
    const message = {
      sender: await signer.getAddress(),
      lock: lockAddress,
      expiration: now + 60 * 60
      // 1 hour!
    };
    const signature = await signer.signTypedData(domain, PurchaseTypes, message);
    return { signature, message };
  }
  /**
   *
   * @param network
   * @param transfer
   * @param purchase
   * @param callData
   * @param signer
   */
  async purchase(network, transfer, purchase, callData, signer) {
    const contract = this.getContract({ network });
    return contract.connect(signer).purchase(
      transfer.message,
      transfer.signature,
      purchase.message,
      purchase.signature,
      callData
    );
  }
};

// src/walletService.ts
var WalletService = class extends UnlockService {
  /**
   * This needs to be called with a ethers.providers which includes a signer or with a signer
   */
  async connect(provider, signer) {
    this.provider = provider;
    if (signer) {
      this.signer = signer;
    } else {
      this.signer = await this.provider.getSigner();
    }
    const { chainId: networkId } = await this.provider.getNetwork();
    if (this.networkId !== networkId) {
      this.networkId = networkId;
    }
    if (this.networks[networkId]?.unlockAddress) {
      this.unlockAddress = this.networks[networkId].unlockAddress;
    }
    return networkId;
  }
  /**
   * Function which yields the address of the account on the provider
   */
  async getAccount() {
    const account = await this.provider.getSigner(0);
    const address = await account.getAddress();
    if (!address) {
      return null;
    }
    return address;
  }
  /**
   * This function submits a web3 transaction and will trigger an event as soon as it receives its
   * hash. We then use the web3Service to handle the ongoing transaction (watch for confirmation
   * receipt... etc)
   * A the moment the dispatcher relies on the strict emission, it is imperitive that the emission
   * of these fields not change for the time being!
   * @private
   * @param {Promise} the result of calling a contract method (ethersjs contract)
   * @param {string} the Unlock protocol transaction type
   * @param {Function} a standard node callback that accepts the transaction hash
   */
  // TODO: Do we need this???
  async _handleMethodCall(methodCall) {
    const transaction = await methodCall;
    if (transaction.hash) {
      return transaction.hash;
    }
    const finalTransaction = await transaction.wait();
    return finalTransaction.hash;
  }
  /**
   * Updates the key price on a lock
   * @param {PropTypes.address} lockAddress : address of the lock for which we update the price
   * @param {string} price : new price for the lock
   * @param {function} callback : callback invoked with the transaction hash
   * @return Promise<PropTypes.number> newKeyPrice
   */
  async updateKeyPrice(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.updateKeyPrice.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Creates a lock on behalf of the user.
   * @param {PropTypes.lock} lock
   * @param {function} callback : callback invoked with the transaction hash
   * @return Promise<PropTypes.address> lockAddress
   */
  async createLock(lock, transactionOptions, callback) {
    const version = await this.unlockContractAbiVersion();
    if (lock && typeof lock.publicLockVersion !== "undefined" && version < 11) {
      throw new Error("Lock creation at version only available for lock v11+");
    }
    return version.createLock.bind(this)(lock, transactionOptions, callback);
  }
  async unlockContractAbiVersion() {
    return super.unlockContractAbiVersion(this.unlockAddress, this.provider);
  }
  async lockContractAbiVersion(address) {
    return super.lockContractAbiVersion(address, this.provider);
  }
  async getUnlockContract() {
    const contract = await super.getUnlockContract(
      this.unlockAddress,
      this.provider
    );
    return contract.connect(this.signer);
  }
  async getLockContract(address) {
    const contract = await super.getLockContract(address, this.provider);
    return contract.connect(this.signer);
  }
  /**
   *  Then we need to call initialize on it. This is critical because otherwise anyone can claim it and then self-destruct it, killing all locks which use the same contract after this.
   * @param {*} params
   * @param {*} callback
   */
  async initializeTemplate(params, transactionOptions, callback) {
    if (!params.templateAddress) throw new Error("Missing templateAddress");
    const version = await this.lockContractAbiVersion(params.templateAddress);
    return version.initializeTemplate.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Purchase a key to a lock by account.
   * The key object is passed so we can keep track of it from the application
   * - {PropTypes.address} lockAddress
   * - {PropTypes.address} owner
   * - {string} keyPrice
   * - {string} data
   * - {PropTypes.address} erc20Address
   * - {number} decimals
   * @param {function} callback : callback invoked with the transaction hash
   */
  async purchaseKey(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.purchaseKey.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Purchase several keys to a lock by account.
   * The key object is passed so we can keep track of it from the application
   * - {PropTypes.address} lockAddress
   * - {PropTypes.arrayOf(PropTypes.address)} owners
   * - {PropTypes.arrayOf(string)} keyPrices
   * - {PropTypes.arrayOf(string)} data
   * - {PropTypes.address} erc20Address
   * - {number} decimals
   * @param {function} callback : callback invoked with the transaction hash
   */
  async purchaseKeys(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.purchaseKeys.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Function to renew a membership, callable by anyone.
   * This is only useful for ERC20 locks for which the key owner has approved
   * a large enough token amount!
   * @param params
   * @param callback
   * @returns
   */
  async renewMembershipFor(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.tokenId) throw new Error("Missing tokenId");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.renewMembershipFor.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Extends an expired key
   * @param {*} params
   * @param {*} callback
   */
  async extendKey(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    const lockContract = await this.getLockContract(params.lockAddress);
    if (!(params.tokenId || params.owner)) {
      throw new Error("Missing tokenId or owner");
    }
    if (!version.extendKey) {
      const owner = params.owner ? params.owner : await lockContract.ownerOf(params.tokenId);
      return this.purchaseKey.bind(this)(
        {
          owner,
          ...params
        },
        transactionOptions,
        callback
      );
    }
    if (!params.tokenId && params.owner) {
      const id = await lockContract.tokenOfOwnerByIndex(params.owner, 0);
      params.tokenId = id.toString();
    }
    return version.extendKey.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Purchase key function. This implementation requires the following
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {number} tokenIdFrom
   * - {number} tokenIdTo
   * - {number} amount if null, will take the entire remaining time of the from key
   * @param {function} callback invoked with the transaction hash
   */
  async mergeKeys(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.mergeKeys) {
      throw new Error("Lock version not supported");
    }
    return version.mergeKeys.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Set ERC20 allowance to the beneficary
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {string} spender the address of the spender
   * - {number} amount the amount to approve
   * @param {function} callback invoked with the transaction hash
   */
  async approveBeneficiary(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.approveBeneficiary) {
      throw new Error("Lock version not supported");
    }
    return version.approveBeneficiary.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Grants permission to grant keys to address
   * @param {*} params
   * @param {*} callback
   */
  async addKeyGranter(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.keyGranter) throw new Error("Missing account");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.addKeyGranter) {
      throw new Error("Lock version not supported");
    }
    return version.addKeyGranter.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Revokes permission to grant keys to an address
   * @param {*} params
   * @param {*} callback
   */
  async removeKeyGranter(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.keyGranter) throw new Error("Missing account");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.removeKeyGranter) {
      throw new Error("Lock version not supported");
    }
    return version.removeKeyGranter.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Expire and refunds (optional) a key by lock manager
   * @param {*} params
   * @param {*} callback
   */
  async expireAndRefundFor(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.keyOwner) throw new Error("Missing keyOwner");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.expireAndRefundFor) {
      throw new Error("Lock version not supported");
    }
    return version.expireAndRefundFor.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Cancels a membership and receive a refund (called by key manager)
   * @param {*} params
   * @param {*} callback
   */
  async cancelAndRefund(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.cancelAndRefund) {
      throw new Error("Lock version not supported");
    }
    return version.cancelAndRefund.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Shares a key by transfering time from key to another key
   * @param {*} params
   * - {PropTypes.address} lockAddress
   * - {PropTypes.address } recipient
   * - {string}: tokenId the token to share time from
   * - {string}: duration time to share in seconds
   * @param {*} callback
   */
  async shareKey(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.recipient) throw new Error("Missing recipient");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.shareKey) {
      throw new Error("Lock version not supported");
    }
    return version.shareKey.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Lends a key to another address
   * @param {function} callback : callback invoked with the transaction hash
   */
  async lendKey(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.lendKey) {
      throw new Error("Lock version not supported");
    }
    return version.lendKey.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Grants a key to an address
   * @param {function} callback : callback invoked with the transaction hash
   */
  async grantKey(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.grantKey.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Grant keys to multiple recipient addresses with custom expiration
   * @param {function} callback : callback invoked with the transaction hash
   */
  async grantKeys(params, transactionOptions, callback) {
    if (!params.lockAddress) {
      throw new Error("Missing lockAddress");
    }
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.grantKeys.bind(this)(params, transactionOptions, callback);
  }
  /**
   * Grant key extension. This implementation requires the following
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {number} tokenId
   * - {number} duration default to 0, which will extend the key by the
   *  default duration of the lock
   * @param {function} callback invoked with the transaction hash
   */
  async grantKeyExtension(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.grantKeyExtension) {
      throw new Error("Lock version not supported");
    }
    return version.grantKeyExtension.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Update the name of a lock
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {string} name the new name of the lock
   * @param {function} callback invoked with the transaction hash
   */
  async updateLockName(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.updateLockName) {
      throw new Error("Lock version not supported");
    }
    return version.updateLockName.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Update the symbol of a lock
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {string} symbol the new symbol of the lock
   * @param {function} callback invoked with the transaction hash
   */
  async updateLockSymbol(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.updateLockSymbol) {
      throw new Error("Lock version not supported");
    }
    return version.updateLockSymbol.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Update the base URI used to parse the tokenURI
   * @param {object} params:
   * - {PropTypes.address} lockAddress
   * - {string} baseTokenURI the new baseTokenURI of the lock
   * @param {function} callback invoked with the transaction hash
   */
  async setBaseTokenURI(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setBaseTokenURI) {
      throw new Error("Lock version not supported");
    }
    return version.setBaseTokenURI.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Triggers a transaction to withdraw funds from the lock and assign them to the owner.
   * @param {object} params
   * - {PropTypes.address} lockAddress
   * - {string} amount the amount to withdraw (0 to withdraw the entire balance)
   * - {string} Erc20Address the address of the ERC20 currency to withdraw
   * - {number} decimals the number of decimals of the ERC20
   * - {string} beneficary the address that will receive the funds (only v12+)
   * @param {function} callback : callback invoked with the transaction hash
   */
  async withdrawFromLock(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    return version.withdrawFromLock.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Tries multiple approaches for eth_signTypedData
   * @param {*} account
   * @param {*} data
   */
  async unformattedSignTypedData(account, data) {
    const methods = {
      eth_signTypedData: (data2) => data2,
      eth_signTypedData_v3: (data2) => JSON.stringify(data2),
      eth_signTypedData_v4: (data2) => JSON.stringify(data2)
    };
    const toTry = Object.keys(methods);
    return new Promise((resolve, reject) => {
      const tryNext = async (tries) => {
        const method = tries.shift();
        if (!method) {
          return reject(new Error("All signing method failed"));
        }
        try {
          const sendData = methods[method](data);
          const result = await this.provider.send(method, [account, sendData]);
          if (result) {
            return resolve(result);
          }
        } catch (error) {
          console.error(`Method ${method} not supported by provider.`);
          console.error(error);
        }
        return tryNext(tries);
      };
      tryNext(toTry);
    });
  }
  async signMessage(data, method) {
    const dataHash = utils_default.utf8ToHex(data);
    const addr = await this.signer.getAddress();
    let firstParam = dataHash;
    let secondParam = addr.toLowerCase();
    if (method === "eth_sign") {
      ;
      [firstParam, secondParam] = [secondParam, firstParam];
    }
    return this.provider.send(method, [firstParam, secondParam]);
  }
  async setMaxNumberOfKeys(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setMaxNumberOfKeys) {
      throw new Error("Lock version not supported");
    }
    return version.setMaxNumberOfKeys.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  async setMaxKeysPerAddress(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setMaxKeysPerAddress) {
      throw new Error("Lock version not supported");
    }
    return version.setMaxKeysPerAddress.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  async setExpirationDuration(params, transactionOptions, callback) {
    if (!params.lockAddress) {
      throw new Error("Missing lockAddress");
    }
    if (params.expirationDuration && typeof params.expirationDuration === "number" && params.expirationDuration < 1) {
      throw new Error("Expiration duration must be greater than 0");
    }
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setExpirationDuration) {
      throw new Error("Lock version not supported");
    }
    return version.setExpirationDuration.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Add lock manager to Contact
   * @param {*} params
   * @param {*} callback
   */
  async addLockManager(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.userAddress) throw new Error("Missing userAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.addLockManager) {
      throw new Error("Lock version not supported");
    }
    return version.addLockManager.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Renounce lock manager status for Contract
   * @param {*} params
   * @param {*} callback
   */
  async renounceLockManager(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.renounceLockManager) {
      throw new Error("Lock version not supported");
    }
    return version.renounceLockManager.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Update Refund Penalty: Allow the owner to change the refund penalty.
   * @param {*} params
   * @param {*} callback
   */
  async updateRefundPenalty(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.updateRefundPenalty) {
      throw new Error("Lock version not supported");
    }
    return version.updateRefundPenalty.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Allows a Lock manager to update or remove an event hook
   * @param {*} params
   * @param {*} callback
   */
  async setEventHooks(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setEventHooks) {
      throw new Error("Lock version not supported");
    }
    return version.setEventHooks.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Allow a Lock manager to change the transfer fee.
   * @param {*} params
   * @param {*} callback
   */
  async updateTransferFee(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.updateTransferFee) {
      throw new Error("Lock version not supported");
    }
    return version.updateTransferFee.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /* Upgrade a lock to a specific version
   * @param {*} params
   * @param {*} callback
   */
  async upgradeLock(params, transactionOptions, callback) {
    const version = await this.unlockContractAbiVersion();
    if (version <= 10) {
      throw new Error("Upgrade lock only available for lock v10+");
    }
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    return version.upgradeLock.bind(this)(
      params.lockAddress,
      params.lockVersion,
      callback
    );
  }
  /**
   * Update referrer fee
   * @param {*} params
   * @param {*} callback
   */
  async setReferrerFee(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setReferrerFee) {
      throw new Error("Lock version not supported");
    }
    return version.setReferrerFee.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Set signer for `Password hook contract`
   */
  async setPasswordHookSigner(params, signer) {
    const { lockAddress, signerAddress, contractAddress, network } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers12.Interface(passwordHookAbi)
    });
    const tx = await contract.connect(this.signer).getFunction("setSigner")(
      lockAddress,
      signerAddress
    );
    return tx;
  }
  /**
   * Set signer for `Password with cap hook contract`
   */
  async setPasswordWithCapHookSigner(params) {
    const { lockAddress, signerAddress, contractAddress, network, cap } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers12.Interface(passwordCapHookAbi)
    });
    const tx = await contract.connect(this.signer).getFunction("setSigner")(
      lockAddress,
      signerAddress,
      cap
    );
    return tx.wait();
  }
  /**
   * Change lock manager for a specific key
   * @param {*} params
   * @param {*} callback
   */
  async setKeyManagerOf(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.managerAddress) throw new Error("Missing managerAddress");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setKeyManagerOf) {
      throw new Error("Lock version not supported");
    }
    return version.setKeyManagerOf.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * This yields the authorizations required to spend USDC on behalf of the user
   * to buy a key from a specific lock. This is mostly used for the universal
   * card support!
   * @param param0
   * @returns
   */
  async getAndSignAuthorizationsForTransferAndPurchase({
    amount,
    lockAddress,
    network
  }) {
    const networkConfig = this.networks[this.networkId];
    const cardPurchaserAddress = networkConfig?.universalCard?.cardPurchaserAddress;
    if (!cardPurchaserAddress) {
      throw new Error("CardPurchaser not available for this network");
    }
    let usdcContractAddress;
    if (networkConfig?.tokens) {
      usdcContractAddress = networkConfig.tokens.find(
        (token) => token.symbol === "USDC"
      )?.address;
    }
    if (!usdcContractAddress) {
      throw new Error("USDC not available for this network");
    }
    const value = `0x${ethers12.parseUnits(amount, 4).toString(16)}`;
    const now = Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3);
    const transferMessage = {
      from: await this.signer.getAddress(),
      to: ethers12.getAddress(cardPurchaserAddress),
      value,
      validAfter: 0,
      validBefore: now + 60 * 60 * 24,
      // Valid for 1 day (TODO: how do we handle funds when they are stuck?)
      nonce: ethers12.hexlify(ethers12.randomBytes(32))
      // 32 byte hex string
    };
    const transferSignature = await signTransferAuthorization(
      usdcContractAddress,
      transferMessage,
      this.provider,
      this.signer
    );
    const cardPurchaser = new CardPurchaser();
    const { signature: purchaseSignature, message: purchaseMessage } = await cardPurchaser.getPurchaseAuthorizationSignature(
      network,
      lockAddress,
      this.signer
    );
    return {
      transferSignature,
      transferMessage,
      purchaseSignature,
      purchaseMessage
    };
  }
  /**
   * Performs a purchase using the CardPurchaser contract
   * @param param0
   * @returns
   */
  async purchaseWithCardPurchaser({
    transfer,
    purchase,
    callData
  }) {
    const networkConfig = this.networks[this.networkId];
    const cardPurchaserAddress = networkConfig?.universalCard?.cardPurchaserAddress;
    if (!cardPurchaserAddress) {
      throw new Error("CardPurchaser not available for this network");
    }
    const cardPurchaser = new CardPurchaser();
    return cardPurchaser.purchase(
      this.networkId,
      transfer,
      purchase,
      callData,
      this.signer
    );
  }
  /**
   * Transfers a specific NFT  from one account to another
   * @returns
   */
  async transferFrom(params, transactionOptions, callback) {
    if (!params.lockAddress) throw new Error("Missing lockAddress");
    if (!params.keyOwner) throw new Error("Missing keyOwner");
    if (!params.to) throw new Error("Missing to");
    if (!params.tokenId) throw new Error("Missing tokenId");
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.transferFrom) {
      throw new Error("Lock version not supported");
    }
    return version.transferFrom.bind(this)(params, transactionOptions, callback);
  }
  async setGasRefund(params, transactionOptions, callback) {
    const version = await this.lockContractAbiVersion(params.lockAddress);
    if (!version.setGasRefundValue) {
      throw new Error("Lock version not supported");
    }
    return version.setGasRefundValue.bind(this)(
      params,
      transactionOptions,
      callback
    );
  }
  /**
   * Set signer for `Discount code` hook contract
   */
  async setDiscountCodeHookSigner(params) {
    const {
      lockAddress,
      signerAddress,
      contractAddress,
      network,
      discountPercentage = 0
    } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers12.Interface(discountCodeHookAbi)
    });
    const discountBasisPoints = discountPercentage * 100;
    return contract.connect(this.signer).getFunction("setDiscountForLock")(
      lockAddress,
      signerAddress,
      discountBasisPoints
    );
  }
  /**
   * Set signer for `Discount code` hook contract with cap
   */
  async setDiscountCodeWithCapHookSigner(params) {
    const {
      lockAddress,
      signerAddress,
      contractAddress,
      network,
      discountPercentage = 0,
      cap = ethers12.MaxUint256
    } = params ?? {};
    const contract = await this.getHookContract({
      network,
      address: contractAddress,
      abi: new ethers12.Interface(discountCodeWithCapHookAbi)
    });
    const discountBasisPoints = discountPercentage * 100;
    const transaction = await contract.connect(this.signer).getFunction("setSigner")(
      lockAddress,
      signerAddress,
      discountBasisPoints,
      cap
    );
    return transaction.wait();
  }
};

// src/providers.ts
function getCurrentProvider(environment) {
  if (environment.ethereum && environment.ethereum.constructor.name === "Object")
    return "Opera";
  if (environment.web3.currentProvider.isMetaMask) return "Metamask";
  if (environment.web3.currentProvider.isTrust) return "Trust";
  if (environment.web3.currentProvider.isToshi) return "Coinbase Wallet";
  if (environment.web3.currentProvider.isCipher) return "Cipher";
  if (environment.web3.currentProvider.constructor.name === "EthereumProvider")
    return "Mist";
  if (environment.web3.currentProvider.constructor.name === "Web3FrameProvider")
    return "Parity";
  if (environment.web3.currentProvider.host && environment.web3.currentProvider.host.indexOf("infura") !== -1)
    return "Infura";
  if (environment.web3.currentProvider.host && environment.web3.currentProvider.host.indexOf("localhost") !== -1)
    return "localhost";
  return "UnknownProvider";
}
function getWeb3Provider(url) {
  return url;
}

// src/locksmithService.ts
import { SiweMessage } from "siwe";

// src/@generated/client/api.ts
import globalAxios2 from "axios";

// src/@generated/client/base.ts
import globalAxios from "axios";
var BASE_PATH = "https://locksmith.unlock-protocol.com".replace(/\/+$/, "");
var BaseAPI = class {
  constructor(configuration, basePath = BASE_PATH, axios = globalAxios) {
    this.basePath = basePath;
    this.axios = axios;
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
};
var RequiredError = class extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
    this.name = "RequiredError";
  }
};

// src/@generated/client/common.ts
var DUMMY_BASE_URL = "https://example.com";
var assertParamExists = function(functionName, paramName, paramValue) {
  if (paramValue === null || paramValue === void 0) {
    throw new RequiredError(paramName, `Required parameter ${paramName} was null or undefined when calling ${functionName}.`);
  }
};
var setApiKeyToObject = async function(object, keyParamName, configuration) {
  if (configuration && configuration.apiKey) {
    const localVarApiKeyValue = typeof configuration.apiKey === "function" ? await configuration.apiKey(keyParamName) : await configuration.apiKey;
    object[keyParamName] = localVarApiKeyValue;
  }
};
var setBearerAuthToObject = async function(object, configuration) {
  if (configuration && configuration.accessToken) {
    const accessToken = typeof configuration.accessToken === "function" ? await configuration.accessToken() : await configuration.accessToken;
    object["Authorization"] = "Bearer " + accessToken;
  }
};
var setSearchParams = function(url, ...objects) {
  const searchParams = new URLSearchParams(url.search);
  for (const object of objects) {
    for (const key in object) {
      if (Array.isArray(object[key])) {
        searchParams.delete(key);
        for (const item of object[key]) {
          searchParams.append(key, item);
        }
      } else {
        searchParams.set(key, object[key]);
      }
    }
  }
  url.search = searchParams.toString();
};
var serializeDataIfNeeded = function(value, requestOptions, configuration) {
  const nonString = typeof value !== "string";
  const needsSerialization = nonString && configuration && configuration.isJsonMime ? configuration.isJsonMime(requestOptions.headers["Content-Type"]) : nonString;
  return needsSerialization ? JSON.stringify(value !== void 0 ? value : {}) : value || "";
};
var toPathString = function(url) {
  return url.pathname + url.search + url.hash;
};
var createRequestFunction = function(axiosArgs, globalAxios3, BASE_PATH2, configuration) {
  return (axios = globalAxios3, basePath = BASE_PATH2) => {
    const axiosRequestArgs = { ...axiosArgs.options, url: (configuration?.basePath || basePath) + axiosArgs.url };
    return axios.request(axiosRequestArgs);
  };
};

// src/@generated/client/api.ts
var ApprovedRefunds200ResponseFormatEnum = {
  StandardV1: "standard-v1"
};
var CapturePurchaseRequestPurchaseTypeEnum = {
  Extend: "extend",
  Purchase: "purchase"
};
var CreateDownloadReceiptsRequest200ResponseStatusEnum = {
  Pending: "pending"
};
var GetReceiptsStatus200ResponseItemsInnerPayloadStatusEnum = {
  Pending: "pending",
  Success: "success",
  Failed: "failed"
};
var GetSubscription200ResponseSubscriptionsInnerTypeEnum = {
  Crypto: "crypto",
  Fiat: "fiat"
};
var GetUserAccountType200ResponseUserAccountTypeEnum = {
  UnlockAccount: "UNLOCK_ACCOUNT",
  GoogleAccount: "GOOGLE_ACCOUNT",
  PasskeyAccount: "PASSKEY_ACCOUNT",
  EmailCode: "EMAIL_CODE"
};
var DefaultApiAxiosParamCreator = function(configuration) {
  return {
    /**
     * Add an event to a collection
     * @param {string} slug 
     * @param {AddEventToCollectionBody} addEventToCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEventToCollection: async (slug, addEventToCollectionBody, options = {}) => {
      assertParamExists("addEventToCollection", "slug", slug);
      assertParamExists("addEventToCollection", "addEventToCollectionBody", addEventToCollectionBody);
      const localVarPath = `/v2/event-collection/{slug}/events`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addEventToCollectionBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Adds a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEventVerifier: async (slug, verifierAddress, createVerifierRequest, options = {}) => {
      assertParamExists("addEventVerifier", "slug", slug);
      assertParamExists("addEventVerifier", "verifierAddress", verifierAddress);
      const localVarPath = `/v2/events/{slug}/verifiers/{verifierAddress}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug))).replace(`{${"verifierAddress"}}`, encodeURIComponent(String(verifierAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createVerifierRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Add a new manager address to an event collection
     * @param {string} slug 
     * @param {AddManagerBody} addManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addManagerToEventCollection: async (slug, addManagerBody, options = {}) => {
      assertParamExists("addManagerToEventCollection", "slug", slug);
      assertParamExists("addManagerToEventCollection", "addManagerBody", addManagerBody);
      const localVarPath = `/v2/event-collection/{slug}/managers`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addManagerBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List applications created the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applications: async (options = {}) => {
      const localVarPath = `/v2/applications/list`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Approves users to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveAttendeesRsvp: async (network, lockAddress, approveAttendeesRsvpRequest, options = {}) => {
      assertParamExists("approveAttendeesRsvp", "network", network);
      assertParamExists("approveAttendeesRsvp", "lockAddress", lockAddress);
      assertParamExists("approveAttendeesRsvp", "approveAttendeesRsvpRequest", approveAttendeesRsvpRequest);
      const localVarPath = `/v2/rsvp/{network}/{lockAddress}/approve`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(approveAttendeesRsvpRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Approve a single event within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {ApproveEventBody} approveEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveEvent: async (slug, approveEventBody, options = {}) => {
      assertParamExists("approveEvent", "slug", slug);
      assertParamExists("approveEvent", "approveEventBody", approveEventBody);
      const localVarPath = `/v2/event-collection/{slug}/events/approve`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(approveEventBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Approves refunds for an event.
     * @param {string} slug Slug
     * @param {ApproveRefundsRequest} approveRefundsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRefunds: async (slug, approveRefundsRequest, options = {}) => {
      assertParamExists("approveRefunds", "slug", slug);
      assertParamExists("approveRefunds", "approveRefundsRequest", approveRefundsRequest);
      const localVarPath = `/v2/events/{slug}/approve-refunds`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(approveRefundsRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Approves a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    approveRsvp: async (network, lockAddress, userAddress, options = {}) => {
      assertParamExists("approveRsvp", "network", network);
      assertParamExists("approveRsvp", "lockAddress", lockAddress);
      assertParamExists("approveRsvp", "userAddress", userAddress);
      const localVarPath = `/v2/rsvp/{network}/{lockAddress}/approve/{userAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"userAddress"}}`, encodeURIComponent(String(userAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Returns the merkle proof of an approved refund.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approvedRefunds: async (slug, options = {}) => {
      assertParamExists("approvedRefunds", "slug", slug);
      const localVarPath = `/v2/events/approved-refunds/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance: async (options = {}) => {
      const localVarPath = `/purchase`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Bulk approve multiple events within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkApproveEventsBody} bulkApproveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkApproveEvents: async (slug, bulkApproveEventsBody, options = {}) => {
      assertParamExists("bulkApproveEvents", "slug", slug);
      assertParamExists("bulkApproveEvents", "bulkApproveEventsBody", bulkApproveEventsBody);
      const localVarPath = `/v2/event-collection/{slug}/events/bulk-approve`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(bulkApproveEventsBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Bulk remove multiple events from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkRemoveEventsBody} bulkRemoveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkRemoveEvents: async (slug, bulkRemoveEventsBody, options = {}) => {
      assertParamExists("bulkRemoveEvents", "slug", slug);
      assertParamExists("bulkRemoveEvents", "bulkRemoveEventsBody", bulkRemoveEventsBody);
      const localVarPath = `/v2/event-collection/{slug}/events/bulk-remove`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(bulkRemoveEventsBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Cancel a fiat subscription.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("cancelSubscription", "network", network);
      assertParamExists("cancelSubscription", "lockAddress", lockAddress);
      assertParamExists("cancelSubscription", "keyId", keyId);
      const localVarPath = `/v2/subscriptions/{network}/locks/{lockAddress}/keys/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Capture a purchase
     * @param {CapturePurchaseRequest} [capturePurchaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    capturePurchase: async (capturePurchaseRequest, options = {}) => {
      const localVarPath = `/purchase/capture`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(capturePurchaseRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if claim is possible for a given purchase
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {CheckClaimRequest} [checkClaimRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkClaim: async (network, lockAddress, checkClaimRequest, options = {}) => {
      assertParamExists("checkClaim", "network", network);
      assertParamExists("checkClaim", "lockAddress", lockAddress);
      const localVarPath = `/claim/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(checkClaimRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Mark an event ticket as checked in.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEventTicket: async (slug, network, lockAddress, keyId, options = {}) => {
      assertParamExists("checkEventTicket", "slug", slug);
      assertParamExists("checkEventTicket", "network", network);
      assertParamExists("checkEventTicket", "lockAddress", lockAddress);
      assertParamExists("checkEventTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{slug}/{network}/lock/{lockAddress}/key/{keyId}/check`.replace(`{${"slug"}}`, encodeURIComponent(String(slug))).replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if a user exists in Privy by email or wallet address
     * @param {PrivyUserCheckBody} privyUserCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPrivyUser: async (privyUserCheckBody, options = {}) => {
      assertParamExists("checkPrivyUser", "privyUserCheckBody", privyUserCheckBody);
      const localVarPath = `/v2/privy/check`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(privyUserCheckBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Mark a lock ticket as checked in.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    checkTicket: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("checkTicket", "network", network);
      assertParamExists("checkTicket", "lockAddress", lockAddress);
      assertParamExists("checkTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/lock/{lockAddress}/key/{keyId}/check`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Claim a membership key for a lock. If not authenticated, requires an email in the body.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {ClaimRequest} claimRequest 
     * @param {string} [recipient] Recipient address for key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claim: async (network, lockAddress, captcha, claimRequest, recipient, options = {}) => {
      assertParamExists("claim", "network", network);
      assertParamExists("claim", "lockAddress", lockAddress);
      assertParamExists("claim", "captcha", captcha);
      assertParamExists("claim", "claimRequest", claimRequest);
      const localVarPath = `/v2/claim/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (captcha !== void 0 && captcha !== null) {
        localVarHeaderParameter["captcha"] = String(captcha);
      }
      if (recipient !== void 0 && recipient !== null) {
        localVarHeaderParameter["recipient"] = String(recipient);
      }
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(claimRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Connect a stripe account to a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ConnectStripeAccountRequest} [connectStripeAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectStripeAccount: async (network, lockAddress, connectStripeAccountRequest, options = {}) => {
      assertParamExists("connectStripeAccount", "network", network);
      assertParamExists("connectStripeAccount", "lockAddress", lockAddress);
      const localVarPath = `/v2/stripe/connect/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(connectStripeAccountRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create application associated with user wallet.
     * @param {ApplicationBody} applicationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplication: async (applicationBody, options = {}) => {
      assertParamExists("createApplication", "applicationBody", applicationBody);
      const localVarPath = `/v2/applications`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(applicationBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Request the creation of a ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDownloadReceiptsRequest: async (network, lockAddress, options = {}) => {
      assertParamExists("createDownloadReceiptsRequest", "network", network);
      assertParamExists("createDownloadReceiptsRequest", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts/all/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a new event collection
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventCollection: async (eventCollectionBody, options = {}) => {
      assertParamExists("createEventCollection", "eventCollectionBody", eventCollectionBody);
      const localVarPath = `/v2/event-collection`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventCollectionBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {string} captcha Recaptcha value to pass.
     * @param {LockContractOptions} lockContractOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLockContract: async (network, captcha, lockContractOptions, options = {}) => {
      assertParamExists("createLockContract", "network", network);
      assertParamExists("createLockContract", "captcha", captcha);
      assertParamExists("createLockContract", "lockContractOptions", lockContractOptions);
      const localVarPath = `/v2/api/contracts/{network}/lock`.replace(`{${"network"}}`, encodeURIComponent(String(network)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setApiKeyToObject(localVarQueryParameter, "api-key", configuration);
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (captcha !== void 0 && captcha !== null) {
        localVarHeaderParameter["captcha"] = String(captcha);
      }
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(lockContractOptions, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a transfer code to transfer an airdropped key to an email address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransferCode: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("createTransferCode", "network", network);
      assertParamExists("createTransferCode", "lockAddress", lockAddress);
      assertParamExists("createTransferCode", "keyId", keyId);
      const localVarPath = `/v2/transfer/{network}/locks/{lockAddress}/keys/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create verifier. (deprecated in favor of addEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createVerifier: async (network, lockAddress, verifierAddress, createVerifierRequest, options = {}) => {
      assertParamExists("createVerifier", "network", network);
      assertParamExists("createVerifier", "lockAddress", lockAddress);
      assertParamExists("createVerifier", "verifierAddress", verifierAddress);
      const localVarPath = `/v2/api/verifier/{network}/{lockAddress}/{verifierAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"verifierAddress"}}`, encodeURIComponent(String(verifierAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createVerifierRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Delete application by id.
     * @param {string} id Application id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplication: async (id, options = {}) => {
      assertParamExists("deleteApplication", "id", id);
      const localVarPath = `/v2/applications/:id`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Delete a checkout config
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCheckoutConfig: async (id, options = {}) => {
      assertParamExists("deleteCheckoutConfig", "id", id);
      const localVarPath = `/v2/checkout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Removes a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventVerifier: async (slug, verifierAddress, options = {}) => {
      assertParamExists("deleteEventVerifier", "slug", slug);
      assertParamExists("deleteEventVerifier", "verifierAddress", verifierAddress);
      const localVarPath = `/v2/events/{slug}/verifiers/{verifierAddress}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug))).replace(`{${"verifierAddress"}}`, encodeURIComponent(String(verifierAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Remove verifier. (deprecated in favor of deleteEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    deleteVerifier: async (network, lockAddress, verifierAddress, options = {}) => {
      assertParamExists("deleteVerifier", "network", network);
      assertParamExists("deleteVerifier", "lockAddress", lockAddress);
      assertParamExists("deleteVerifier", "verifierAddress", verifierAddress);
      const localVarPath = `/v2/api/verifier/{network}/{lockAddress}/{verifierAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"verifierAddress"}}`, encodeURIComponent(String(verifierAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Denies users to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    denyAttendeesRsvp: async (network, lockAddress, approveAttendeesRsvpRequest, options = {}) => {
      assertParamExists("denyAttendeesRsvp", "network", network);
      assertParamExists("denyAttendeesRsvp", "lockAddress", lockAddress);
      assertParamExists("denyAttendeesRsvp", "approveAttendeesRsvpRequest", approveAttendeesRsvpRequest);
      const localVarPath = `/v2/rsvp/{network}/{lockAddress}/deny`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(approveAttendeesRsvpRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Denies a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    denyRsvp: async (network, lockAddress, userAddress, options = {}) => {
      assertParamExists("denyRsvp", "network", network);
      assertParamExists("denyRsvp", "lockAddress", lockAddress);
      assertParamExists("denyRsvp", "userAddress", userAddress);
      const localVarPath = `/v2/rsvp/{network}/{lockAddress}/deny/{userAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"userAddress"}}`, encodeURIComponent(String(userAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Disconnect stripe account.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disconnectStripe: async (network, lockAddress, options = {}) => {
      assertParamExists("disconnectStripe", "network", network);
      assertParamExists("disconnectStripe", "lockAddress", lockAddress);
      const localVarPath = `/{network}/lock/{lockAddress}/stripe`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Download the ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadReceipts: async (network, lockAddress, options = {}) => {
      assertParamExists("downloadReceipts", "network", network);
      assertParamExists("downloadReceipts", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts/download/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Ejects a user given their public key. In the case of failure, a rejected promise is returned to the caller.
     * @param {string} publicKey 
     * @param {{ [key: string]: any; }} requestBody Structured data used to generate the signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ejectUser: async (publicKey, requestBody, options = {}) => {
      assertParamExists("ejectUser", "publicKey", publicKey);
      assertParamExists("ejectUser", "requestBody", requestBody);
      const localVarPath = `/users/{publicKey}/eject`.replace(`{${"publicKey"}}`, encodeURIComponent(String(publicKey)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Send QR code by email.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailTicket: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("emailTicket", "network", network);
      assertParamExists("emailTicket", "lockAddress", lockAddress);
      assertParamExists("emailTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/{lockAddress}/{keyId}/email`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List of verifiers for an event.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventVerifiers: async (slug, options = {}) => {
      assertParamExists("eventVerifiers", "slug", slug);
      const localVarPath = `/v2/events/{slug}/verifiers`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts Job process to get all the keys for the given lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportKeys: async (network, lockAddress, query, filterKey, expiration, approval, options = {}) => {
      assertParamExists("exportKeys", "network", network);
      assertParamExists("exportKeys", "lockAddress", lockAddress);
      assertParamExists("exportKeys", "query", query);
      assertParamExists("exportKeys", "filterKey", filterKey);
      const localVarPath = `/v2/api/{network}/locks/{lockAddress}/export-keys`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setApiKeyToObject(localVarQueryParameter, "api-key", configuration);
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (query !== void 0) {
        localVarQueryParameter["query"] = query;
      }
      if (filterKey !== void 0) {
        localVarQueryParameter["filterKey"] = filterKey;
      }
      if (expiration !== void 0) {
        localVarQueryParameter["expiration"] = expiration;
      }
      if (approval !== void 0) {
        localVarQueryParameter["approval"] = approval;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Generate and return an Apple Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAppleWalletPass: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("generateAppleWalletPass", "network", network);
      assertParamExists("generateAppleWalletPass", "lockAddress", lockAddress);
      assertParamExists("generateAppleWalletPass", "keyId", keyId);
      const localVarPath = `/v2/pass/{network}/{lockAddress}/{keyId}/ios`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Generate certificate for specific key
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateCertificate: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("generateCertificate", "network", network);
      assertParamExists("generateCertificate", "lockAddress", lockAddress);
      assertParamExists("generateCertificate", "keyId", keyId);
      const localVarPath = `/v2/certificate/{network}/lock/{lockAddress}/key/{keyId}/generate`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Generate and return a Google Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGoogleWalletPass: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("generateGoogleWalletPass", "network", network);
      assertParamExists("generateGoogleWalletPass", "lockAddress", lockAddress);
      assertParamExists("generateGoogleWalletPass", "keyId", keyId);
      const localVarPath = `/v2/pass/{network}/{lockAddress}/{keyId}/android`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Generate a ticket for a key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTicket: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("generateTicket", "network", network);
      assertParamExists("generateTicket", "lockAddress", lockAddress);
      assertParamExists("generateTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/lock/{lockAddress}/key/{keyId}/generate`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the price breakdown for recipients & total charges with fees for a specific lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {Array<string>} purchaseData The purchase data passed to the purchase function
     * @param {Array<string>} recipients The recipients passed to the purchase function
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesForLock: async (network, lockAddress, purchaseData, recipients, options = {}) => {
      assertParamExists("getChargesForLock", "network", network);
      assertParamExists("getChargesForLock", "lockAddress", lockAddress);
      assertParamExists("getChargesForLock", "purchaseData", purchaseData);
      assertParamExists("getChargesForLock", "recipients", recipients);
      const localVarPath = `/v2/api/charges/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (purchaseData) {
        localVarQueryParameter["purchaseData[]"] = purchaseData;
      }
      if (recipients) {
        localVarQueryParameter["recipients[]"] = recipients;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * 
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCheckoutConfig: async (id, options = {}) => {
      assertParamExists("getCheckoutConfig", "id", id);
      const localVarPath = `/v2/checkout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get custom email content for a specific template and lock details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomEmailContent: async (network, lockAddress, template, options = {}) => {
      assertParamExists("getCustomEmailContent", "network", network);
      assertParamExists("getCustomEmailContent", "lockAddress", lockAddress);
      assertParamExists("getCustomEmailContent", "template", template);
      const localVarPath = `/v2/email/{network}/locks/{lockAddress}/custom/{template}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"template"}}`, encodeURIComponent(String(template)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves data for recipients and captcha.
     * @param {Array<string>} recipients 
     * @param {string} captchaValue 
     * @param {string} lockAddress 
     * @param {number} network 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndCaptcha: async (recipients, captchaValue, lockAddress, network, options = {}) => {
      assertParamExists("getDataForRecipientsAndCaptcha", "recipients", recipients);
      assertParamExists("getDataForRecipientsAndCaptcha", "captchaValue", captchaValue);
      assertParamExists("getDataForRecipientsAndCaptcha", "lockAddress", lockAddress);
      assertParamExists("getDataForRecipientsAndCaptcha", "network", network);
      const localVarPath = `/api/captcha`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (recipients) {
        localVarQueryParameter["recipients[]"] = recipients;
      }
      if (captchaValue !== void 0) {
        localVarQueryParameter["captchaValue"] = captchaValue;
      }
      if (lockAddress !== void 0) {
        localVarQueryParameter["lockAddress"] = lockAddress;
      }
      if (network !== void 0) {
        localVarQueryParameter["network"] = network;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the data for recipients and gitcoin passport. This is to be used in conjunction with the Gitcoin passport scorer.
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndGitcoinPassport: async (network, lockAddress, recipients, options = {}) => {
      assertParamExists("getDataForRecipientsAndGitcoinPassport", "network", network);
      const localVarPath = `/v2/hooks/gitcoin`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (network !== void 0) {
        localVarQueryParameter["network"] = network;
      }
      if (lockAddress !== void 0) {
        localVarQueryParameter["lockAddress"] = lockAddress;
      }
      if (recipients) {
        localVarQueryParameter["recipients"] = recipients;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the data for recipients and guild. This is to be used in conjunction with the Guild
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndGuild: async (network, lockAddress, recipients, options = {}) => {
      assertParamExists("getDataForRecipientsAndGuild", "network", network);
      const localVarPath = `/v2/hooks/guild`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (network !== void 0) {
        localVarQueryParameter["network"] = network;
      }
      if (lockAddress !== void 0) {
        localVarQueryParameter["lockAddress"] = lockAddress;
      }
      if (recipients) {
        localVarQueryParameter["recipients"] = recipients;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves an event based on its slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvent: async (slug, options = {}) => {
      assertParamExists("getEvent", "slug", slug);
      const localVarPath = `/v2/events/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get details of an event collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventCollection: async (slug, options = {}) => {
      assertParamExists("getEventCollection", "slug", slug);
      const localVarPath = `/v2/event-collection/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the event details from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getEventDetails: async (network, lockAddress, options = {}) => {
      assertParamExists("getEventDetails", "network", network);
      assertParamExists("getEventDetails", "lockAddress", lockAddress);
      const localVarPath = `/v2/events/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * get a ticket for a key by event.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTicket: async (slug, network, lockAddress, keyId, options = {}) => {
      assertParamExists("getEventTicket", "slug", slug);
      assertParamExists("getEventTicket", "network", network);
      assertParamExists("getEventTicket", "lockAddress", lockAddress);
      assertParamExists("getEventTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{slug}/{network}/lock/{lockAddress}/key/{keyId}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug))).replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get events in a collection
     * @param {string} slug 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventsInCollection: async (slug, page, pageSize, options = {}) => {
      assertParamExists("getEventsInCollection", "slug", slug);
      const localVarPath = `/v2/event-collection/{slug}/events`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (page !== void 0) {
        localVarQueryParameter["page"] = page;
      }
      if (pageSize !== void 0) {
        localVarQueryParameter["pageSize"] = pageSize;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get job result by job id
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} jobId Id of the exported job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportedKeys: async (network, lockAddress, jobId, options = {}) => {
      assertParamExists("getExportedKeys", "network", network);
      assertParamExists("getExportedKeys", "lockAddress", lockAddress);
      assertParamExists("getExportedKeys", "jobId", jobId);
      const localVarPath = `/v2/api/{network}/locks/{lockAddress}/export-keys/{jobId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setApiKeyToObject(localVarQueryParameter, "api-key", configuration);
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get saved lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockSettings: async (network, lockAddress, options = {}) => {
      assertParamExists("getLockSettings", "network", network);
      assertParamExists("getLockSettings", "lockAddress", lockAddress);
      const localVarPath = `/v2/lock-settings/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get lock setting by slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockSettingsBySlug: async (slug, options = {}) => {
      assertParamExists("getLockSettingsBySlug", "slug", slug);
      const localVarPath = `/v2/lock-settings/slug/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Returns Stripe connection details
     * @param {string} lockAddress Lock address.
     * @param {number} chain Lock network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockStripeConnectionDetails: async (lockAddress, chain, options = {}) => {
      assertParamExists("getLockStripeConnectionDetails", "lockAddress", lockAddress);
      assertParamExists("getLockStripeConnectionDetails", "chain", chain);
      const localVarPath = `/lock/{lockAddress}/stripe-connected`.replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (chain !== void 0) {
        localVarQueryParameter["chain"] = chain;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get receipt details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceipt: async (network, lockAddress, hash, options = {}) => {
      assertParamExists("getReceipt", "network", network);
      assertParamExists("getReceipt", "lockAddress", lockAddress);
      assertParamExists("getReceipt", "hash", hash);
      const localVarPath = `/v2/receipts/{network}/{lockAddress}/{hash}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all receipts for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceipts: async (network, lockAddress, options = {}) => {
      assertParamExists("getReceipts", "network", network);
      assertParamExists("getReceipts", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts/{network}/{lockAddress}/all`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get supplier details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceiptsBase: async (network, lockAddress, options = {}) => {
      assertParamExists("getReceiptsBase", "network", network);
      assertParamExists("getReceiptsBase", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts-base/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve the status of receipt generation for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceiptsStatus: async (network, lockAddress, options = {}) => {
      assertParamExists("getReceiptsStatus", "network", network);
      assertParamExists("getReceiptsStatus", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts/all/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Lists the locks connected to Stripe for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStripeConnections: async (options = {}) => {
      const localVarPath = `/v2/stripe/connections`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get an active subscription associated with the key. It can be fiat or crypto.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("getSubscription", "network", network);
      assertParamExists("getSubscription", "lockAddress", lockAddress);
      assertParamExists("getSubscription", "keyId", keyId);
      const localVarPath = `/v2/subscriptions/{network}/locks/{lockAddress}/keys/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * get a ticket for a key by lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTicket: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("getTicket", "network", network);
      assertParamExists("getTicket", "lockAddress", lockAddress);
      assertParamExists("getTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/lock/{lockAddress}/key/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get unapproved events for a collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnapprovedEventsForCollection: async (slug, options = {}) => {
      assertParamExists("getUnapprovedEventsForCollection", "slug", slug);
      const localVarPath = `/v2/event-collection/{slug}/unapproved-events`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets User account type by email.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAccountType: async (emailAddress, options = {}) => {
      assertParamExists("getUserAccountType", "emailAddress", emailAddress);
      const localVarPath = `/v2/api/users/{emailAddress}/existNextAuth`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * get user metadata for a lock.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserMetadata: async (lockAddress, userAddress, options = {}) => {
      assertParamExists("getUserMetadata", "lockAddress", lockAddress);
      assertParamExists("getUserMetadata", "userAddress", userAddress);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}/users/{userAddress}`.replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"userAddress"}}`, encodeURIComponent(String(userAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Given a user\'s email address, retrieves their private key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPrivateKey: async (emailAddress, options = {}) => {
      assertParamExists("getUserPrivateKey", "emailAddress", emailAddress);
      const localVarPath = `/users/{emailAddress}/privatekey`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Given a user\'s email address, retrieves their recovery phrase. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRecoveryPhrase: async (emailAddress, options = {}) => {
      assertParamExists("getUserRecoveryPhrase", "emailAddress", emailAddress);
      const localVarPath = `/users/{emailAddress}/recoveryphrase`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets Coinbase WAAS token for user by email and selected provider.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {string} selectedProvider 
     * @param {GetWaasTokenRequest} getWaasTokenRequest JSON object containing sign in token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWaasToken: async (captcha, emailAddress, selectedProvider, getWaasTokenRequest, options = {}) => {
      assertParamExists("getWaasToken", "captcha", captcha);
      assertParamExists("getWaasToken", "emailAddress", emailAddress);
      assertParamExists("getWaasToken", "selectedProvider", selectedProvider);
      assertParamExists("getWaasToken", "getWaasTokenRequest", getWaasTokenRequest);
      const localVarPath = `/v2/api/users/{emailAddress}/{selectedProvider}/waas`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress))).replace(`{${"selectedProvider"}}`, encodeURIComponent(String(selectedProvider)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (captcha !== void 0 && captcha !== null) {
        localVarHeaderParameter["captcha"] = String(captcha);
      }
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(getWaasTokenRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Returns if credit card payment are enable for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isCardPaymentEnabledForLock: async (network, lockAddress, options = {}) => {
      assertParamExists("isCardPaymentEnabledForLock", "network", network);
      assertParamExists("isCardPaymentEnabledForLock", "lockAddress", lockAddress);
      const localVarPath = `/v2/api/credit-card-details/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get key and user metadata.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    keyMetadata: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("keyMetadata", "network", network);
      assertParamExists("keyMetadata", "lockAddress", lockAddress);
      assertParamExists("keyMetadata", "keyId", keyId);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}/keys/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager (deprecated, use keys-by-page)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    keys: async (network, lockAddress, query, filterKey, expiration, approval, page, max, options = {}) => {
      assertParamExists("keys", "network", network);
      assertParamExists("keys", "lockAddress", lockAddress);
      assertParamExists("keys", "query", query);
      assertParamExists("keys", "filterKey", filterKey);
      const localVarPath = `/v2/api/{network}/locks/{lockAddress}/keys`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setApiKeyToObject(localVarQueryParameter, "api-key", configuration);
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (query !== void 0) {
        localVarQueryParameter["query"] = query;
      }
      if (filterKey !== void 0) {
        localVarQueryParameter["filterKey"] = filterKey;
      }
      if (expiration !== void 0) {
        localVarQueryParameter["expiration"] = expiration;
      }
      if (approval !== void 0) {
        localVarQueryParameter["approval"] = approval;
      }
      if (page !== void 0) {
        localVarQueryParameter["page"] = page;
      }
      if (max !== void 0) {
        localVarQueryParameter["max"] = max;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    keysByPage: async (network, lockAddress, query, filterKey, expiration, approval, page, max, options = {}) => {
      assertParamExists("keysByPage", "network", network);
      assertParamExists("keysByPage", "lockAddress", lockAddress);
      assertParamExists("keysByPage", "query", query);
      assertParamExists("keysByPage", "filterKey", filterKey);
      const localVarPath = `/v2/api/{network}/locks/{lockAddress}/keys-by-page`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setApiKeyToObject(localVarQueryParameter, "api-key", configuration);
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (query !== void 0) {
        localVarQueryParameter["query"] = query;
      }
      if (filterKey !== void 0) {
        localVarQueryParameter["filterKey"] = filterKey;
      }
      if (expiration !== void 0) {
        localVarQueryParameter["expiration"] = expiration;
      }
      if (approval !== void 0) {
        localVarQueryParameter["approval"] = approval;
      }
      if (page !== void 0) {
        localVarQueryParameter["page"] = page;
      }
      if (max !== void 0) {
        localVarQueryParameter["max"] = max;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * A list of all checkout configs created by the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCheckoutConfigs: async (options = {}) => {
      const localVarPath = `/v2/checkout/list`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List payment methods for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPaymentMethods: async (options = {}) => {
      const localVarPath = `/v2/purchase/list`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockMetadata: async (network, lockAddress, options = {}) => {
      assertParamExists("lockMetadata", "network", network);
      assertParamExists("lockMetadata", "lockAddress", lockAddress);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Login as a user using Sign in with Ethereum message signed by the wallet address.
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (loginRequest, options = {}) => {
      assertParamExists("login", "loginRequest", loginRequest);
      const localVarPath = `/v2/auth/login`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Verify an access token using Privy\'s library and authenticate the user
     * @param {LoginWithPrivyRequest} loginWithPrivyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginWithPrivy: async (loginWithPrivyRequest, options = {}) => {
      assertParamExists("loginWithPrivy", "loginWithPrivyRequest", loginWithPrivyRequest);
      const localVarPath = `/v2/auth/privy`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginWithPrivyRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Logout from locksmith. This will invalidate all your session tokens.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options = {}) => {
      const localVarPath = `/v2/auth/logout`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Provide a unique nonce for using in sign in with ethereum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonce: async (options = {}) => {
      const localVarPath = `/v2/auth/nonce`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {number} [amount] Amount of tokens you want to get the price for in USD.
     * @param {string} [address] The erc20 address you want to get the price for. If nothing is passed, the API will return the native token price.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    price: async (network, amount, address, options = {}) => {
      assertParamExists("price", "network", network);
      const localVarPath = `/v2/api/{network}/price`.replace(`{${"network"}}`, encodeURIComponent(String(network)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (amount !== void 0) {
        localVarQueryParameter["amount"] = amount;
      }
      if (address !== void 0) {
        localVarQueryParameter["address"] = address;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create purchase intent for stripe.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchase: async (network, lockAddress, purchaseRequest, options = {}) => {
      assertParamExists("purchase", "network", network);
      assertParamExists("purchase", "lockAddress", lockAddress);
      assertParamExists("purchase", "purchaseRequest", purchaseRequest);
      const localVarPath = `/v2/purchase/intent/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(purchaseRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * resubscribe emails from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reSubscribeEmail: async (network, lockAddress, options = {}) => {
      assertParamExists("reSubscribeEmail", "network", network);
      assertParamExists("reSubscribeEmail", "lockAddress", lockAddress);
      const localVarPath = `/v2/email-subscriptions/subscribe/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Remove a single event from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {RemoveEventBody} removeEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeEventFromCollection: async (slug, removeEventBody, options = {}) => {
      assertParamExists("removeEventFromCollection", "slug", slug);
      assertParamExists("removeEventFromCollection", "removeEventBody", removeEventBody);
      const localVarPath = `/v2/event-collection/{slug}/events`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(removeEventBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Remove a manager address from an event collection
     * @param {string} slug 
     * @param {RemoveManagerBody} removeManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeManagerFromEventCollection: async (slug, removeManagerBody, options = {}) => {
      assertParamExists("removeManagerFromEventCollection", "slug", slug);
      assertParamExists("removeManagerFromEventCollection", "removeManagerBody", removeManagerBody);
      const localVarPath = `/v2/event-collection/{slug}/managers`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(removeManagerBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Remove all payment methods for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removePaymentMethods: async (options = {}) => {
      const localVarPath = `/v2/purchase/payment-methods`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Invalidate a session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revoke: async (options = {}) => {
      const localVarPath = `/v2/auth/revoke`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * User applies to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {RsvpRequest} [rsvpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rsvp: async (network, lockAddress, captcha, rsvpRequest, options = {}) => {
      assertParamExists("rsvp", "network", network);
      assertParamExists("rsvp", "lockAddress", lockAddress);
      assertParamExists("rsvp", "captcha", captcha);
      const localVarPath = `/v2/rsvp/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (captcha !== void 0 && captcha !== null) {
        localVarHeaderParameter["captcha"] = String(captcha);
      }
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(rsvpRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Save custom email content for a specif lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCustomEmailContent: async (network, lockAddress, template, options = {}) => {
      assertParamExists("saveCustomEmailContent", "network", network);
      assertParamExists("saveCustomEmailContent", "lockAddress", lockAddress);
      assertParamExists("saveCustomEmailContent", "template", template);
      const localVarPath = `/v2/email/{network}/locks/{lockAddress}/custom/{template}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"template"}}`, encodeURIComponent(String(template)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Save event data
     * @param {SaveEventDataRequest} [saveEventDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventData: async (saveEventDataRequest, options = {}) => {
      const localVarPath = `/v2/events/save`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveEventDataRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Save lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {LockSettings} [lockSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLockSetting: async (network, lockAddress, lockSettings, options = {}) => {
      assertParamExists("saveLockSetting", "network", network);
      assertParamExists("saveLockSetting", "lockAddress", lockAddress);
      const localVarPath = `/v2/lock-settings/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(lockSettings, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create purchaser details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReceipt: async (network, lockAddress, hash, options = {}) => {
      assertParamExists("saveReceipt", "network", network);
      assertParamExists("saveReceipt", "lockAddress", lockAddress);
      assertParamExists("saveReceipt", "hash", hash);
      const localVarPath = `/v2/receipts/{network}/{lockAddress}/{hash}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create supplier details.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReceiptsBase: async (network, lockAddress, options = {}) => {
      assertParamExists("saveReceiptsBase", "network", network);
      assertParamExists("saveReceiptsBase", "lockAddress", lockAddress);
      const localVarPath = `/v2/receipts-base/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Send custom email to recipients
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {SendCustomEmailRequest} [sendCustomEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendCustomEmail: async (network, lockAddress, sendCustomEmailRequest, options = {}) => {
      assertParamExists("sendCustomEmail", "network", network);
      assertParamExists("sendCustomEmail", "lockAddress", lockAddress);
      const localVarPath = `/v2/email/{network}/locks/{lockAddress}/custom/send`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sendCustomEmailRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Send event invites to a list of recipients
     * @param {string} slug Slug
     * @param {SendEventInvitesRequest} [sendEventInvitesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEventInvites: async (slug, sendEventInvitesRequest, options = {}) => {
      assertParamExists("sendEventInvites", "slug", slug);
      const localVarPath = `/v2/email/{slug}/invite`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sendEventInvitesRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Sends a verification code to the user\'s email if the current code is expired or does not exist.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerificationCode: async (captcha, emailAddress, options = {}) => {
      assertParamExists("sendVerificationCode", "captcha", captcha);
      assertParamExists("sendVerificationCode", "emailAddress", emailAddress);
      const localVarPath = `/v2/api/users/{emailAddress}/send-verification-code`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (captcha !== void 0 && captcha !== null) {
        localVarHeaderParameter["captcha"] = String(captcha);
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Setup a payment method for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setupPayment: async (options = {}) => {
      const localVarPath = `/v2/purchase/setup`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Generate signature to prove validity of token.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signTicket: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("signTicket", "network", network);
      assertParamExists("signTicket", "lockAddress", lockAddress);
      assertParamExists("signTicket", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/{lockAddress}/{keyId}/sign`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get QR code for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ticketQRCode: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("ticketQRCode", "network", network);
      assertParamExists("ticketQRCode", "lockAddress", lockAddress);
      assertParamExists("ticketQRCode", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/{lockAddress}/{keyId}/qr`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the verification URL for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ticketVerificationUrl: async (network, lockAddress, keyId, options = {}) => {
      assertParamExists("ticketVerificationUrl", "network", network);
      assertParamExists("ticketVerificationUrl", "lockAddress", lockAddress);
      assertParamExists("ticketVerificationUrl", "keyId", keyId);
      const localVarPath = `/v2/api/ticket/{network}/{lockAddress}/{keyId}/verification`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Finalize the transfer of a key to a recipient. This is to copy over any locksmith related data to the recipient\'s account.
     * @param {TransferDoneRequest} [transferDoneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferDone: async (transferDoneRequest, options = {}) => {
      const localVarPath = `/v2/transfer/done`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transferDoneRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Unsubscribe email from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsubscribeEmail: async (network, lockAddress, options = {}) => {
      assertParamExists("unsubscribeEmail", "network", network);
      assertParamExists("unsubscribeEmail", "lockAddress", lockAddress);
      const localVarPath = `/v2/email-subscriptions/unsubscribe/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update application by id.
     * @param {string} id Application id.
     * @param {ApplicationBody} [applicationBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplication: async (id, applicationBody, options = {}) => {
      assertParamExists("updateApplication", "id", id);
      const localVarPath = `/v2/applications/:id`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(applicationBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * A new checkout config is created or updated if it already exists
     * @param {string} id This is optional. If you don\&#39;t pass an ID, a new config will be created. If you pass an ID, the config will be created.
     * @param {UpdateCheckoutConfigRequest} [updateCheckoutConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCheckoutConfig: async (id, updateCheckoutConfigRequest, options = {}) => {
      assertParamExists("updateCheckoutConfig", "id", id);
      const localVarPath = `/v2/checkout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateCheckoutConfigRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update an existing event collection
     * @param {string} slug 
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventCollection: async (slug, eventCollectionBody, options = {}) => {
      assertParamExists("updateEventCollection", "slug", slug);
      assertParamExists("updateEventCollection", "eventCollectionBody", eventCollectionBody);
      const localVarPath = `/v2/event-collection/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventCollectionBody, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update or create metadata for key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKeyMetadata: async (network, lockAddress, keyId, updateLockMetadataRequest, options = {}) => {
      assertParamExists("updateKeyMetadata", "network", network);
      assertParamExists("updateKeyMetadata", "lockAddress", lockAddress);
      assertParamExists("updateKeyMetadata", "keyId", keyId);
      assertParamExists("updateKeyMetadata", "updateLockMetadataRequest", updateLockMetadataRequest);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}/keys/{keyId}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateLockMetadataRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update or create metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLockMetadata: async (network, lockAddress, updateLockMetadataRequest, options = {}) => {
      assertParamExists("updateLockMetadata", "network", network);
      assertParamExists("updateLockMetadata", "lockAddress", lockAddress);
      assertParamExists("updateLockMetadata", "updateLockMetadataRequest", updateLockMetadataRequest);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateLockMetadataRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Updates a user\'s private key, using their email address as key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {UpdateUserEncryptedPrivateKeyRequest} updateUserEncryptedPrivateKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserEncryptedPrivateKey: async (emailAddress, updateUserEncryptedPrivateKeyRequest, options = {}) => {
      assertParamExists("updateUserEncryptedPrivateKey", "emailAddress", emailAddress);
      assertParamExists("updateUserEncryptedPrivateKey", "updateUserEncryptedPrivateKeyRequest", updateUserEncryptedPrivateKeyRequest);
      const localVarPath = `/users/{emailAddress}/passwordEncryptedPrivateKey`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserEncryptedPrivateKeyRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update or create user metadata for lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {UpdateUserMetadataRequest} updateUserMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserMetadata: async (network, lockAddress, userAddress, updateUserMetadataRequest, options = {}) => {
      assertParamExists("updateUserMetadata", "network", network);
      assertParamExists("updateUserMetadata", "lockAddress", lockAddress);
      assertParamExists("updateUserMetadata", "userAddress", userAddress);
      assertParamExists("updateUserMetadata", "updateUserMetadataRequest", updateUserMetadataRequest);
      const localVarPath = `/v2/api/metadata/{network}/locks/{lockAddress}/users/{userAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"userAddress"}}`, encodeURIComponent(String(userAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserMetadataRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update or create user metadata for lock.
     * @param {UpdateUsersMetadataRequest} updateUsersMetadataRequest users array containing user metadata.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsersMetadata: async (updateUsersMetadataRequest, options = {}) => {
      assertParamExists("updateUsersMetadata", "updateUsersMetadataRequest", updateUsersMetadataRequest);
      const localVarPath = `/v2/api/metadata/users`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUsersMetadataRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Upload images to locksmith
     * @param {Array<any>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImages: async (images, options = {}) => {
      const localVarPath = `/v2/images/upload`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      const localVarFormParams = new (configuration && configuration.formDataCtor || FormData)();
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      if (images) {
        images.forEach((element) => {
          localVarFormParams.append("images", element);
        });
      }
      localVarHeaderParameter["Content-Type"] = "multipart/form-data";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get user details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    user: async (options = {}) => {
      const localVarPath = `/v2/auth/user`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if an address is a verifier of the lock. (deprecated in favor of eventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    verifier: async (network, lockAddress, verifierAddress, options = {}) => {
      assertParamExists("verifier", "network", network);
      assertParamExists("verifier", "lockAddress", lockAddress);
      assertParamExists("verifier", "verifierAddress", verifierAddress);
      const localVarPath = `/v2/api/verifier/{network}/lock/{lockAddress}/address/{verifierAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress))).replace(`{${"verifierAddress"}}`, encodeURIComponent(String(verifierAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List of verifiers for the lock address. (deprecated in favor of eventVerifiers)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    verifiers: async (network, lockAddress, options = {}) => {
      assertParamExists("verifiers", "network", network);
      assertParamExists("verifiers", "lockAddress", lockAddress);
      const localVarPath = `/v2/api/verifier/list/{network}/{lockAddress}`.replace(`{${"network"}}`, encodeURIComponent(String(network))).replace(`{${"lockAddress"}}`, encodeURIComponent(String(lockAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      await setBearerAuthToObject(localVarHeaderParameter, configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Verifies the email code sent to the users email address.
     * @param {string} emailAddress 
     * @param {VerifyEmailCodeRequest} verifyEmailCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailCode: async (emailAddress, verifyEmailCodeRequest, options = {}) => {
      assertParamExists("verifyEmailCode", "emailAddress", emailAddress);
      assertParamExists("verifyEmailCode", "verifyEmailCodeRequest", verifyEmailCodeRequest);
      const localVarPath = `/v2/api/users/{emailAddress}/verify-email-code`.replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter["Content-Type"] = "application/json";
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailCodeRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};
var DefaultApiFp = function(configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * Add an event to a collection
     * @param {string} slug 
     * @param {AddEventToCollectionBody} addEventToCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEventToCollection(slug, addEventToCollectionBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEventToCollection(slug, addEventToCollectionBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Adds a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEventVerifier(slug, verifierAddress, createVerifierRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEventVerifier(slug, verifierAddress, createVerifierRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Add a new manager address to an event collection
     * @param {string} slug 
     * @param {AddManagerBody} addManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addManagerToEventCollection(slug, addManagerBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addManagerToEventCollection(slug, addManagerBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List applications created the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applications(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.applications(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Approves users to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Approve a single event within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {ApproveEventBody} approveEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveEvent(slug, approveEventBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveEvent(slug, approveEventBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Approves refunds for an event.
     * @param {string} slug Slug
     * @param {ApproveRefundsRequest} approveRefundsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveRefunds(slug, approveRefundsRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveRefunds(slug, approveRefundsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Approves a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async approveRsvp(network, lockAddress, userAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveRsvp(network, lockAddress, userAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Returns the merkle proof of an approved refund.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approvedRefunds(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approvedRefunds(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async balance(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.balance(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Bulk approve multiple events within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkApproveEventsBody} bulkApproveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkApproveEvents(slug, bulkApproveEventsBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkApproveEvents(slug, bulkApproveEventsBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Bulk remove multiple events from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkRemoveEventsBody} bulkRemoveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkRemoveEvents(slug, bulkRemoveEventsBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRemoveEvents(slug, bulkRemoveEventsBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Cancel a fiat subscription.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelSubscription(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Capture a purchase
     * @param {CapturePurchaseRequest} [capturePurchaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async capturePurchase(capturePurchaseRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.capturePurchase(capturePurchaseRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Check if claim is possible for a given purchase
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {CheckClaimRequest} [checkClaimRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkClaim(network, lockAddress, checkClaimRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkClaim(network, lockAddress, checkClaimRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Mark an event ticket as checked in.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkEventTicket(slug, network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkEventTicket(slug, network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Check if a user exists in Privy by email or wallet address
     * @param {PrivyUserCheckBody} privyUserCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkPrivyUser(privyUserCheckBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkPrivyUser(privyUserCheckBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Mark a lock ticket as checked in.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async checkTicket(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkTicket(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Claim a membership key for a lock. If not authenticated, requires an email in the body.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {ClaimRequest} claimRequest 
     * @param {string} [recipient] Recipient address for key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async claim(network, lockAddress, captcha, claimRequest, recipient, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.claim(network, lockAddress, captcha, claimRequest, recipient, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Connect a stripe account to a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ConnectStripeAccountRequest} [connectStripeAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create application associated with user wallet.
     * @param {ApplicationBody} applicationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApplication(applicationBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(applicationBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Request the creation of a ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDownloadReceiptsRequest(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDownloadReceiptsRequest(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create a new event collection
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEventCollection(eventCollectionBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEventCollection(eventCollectionBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {string} captcha Recaptcha value to pass.
     * @param {LockContractOptions} lockContractOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLockContract(network, captcha, lockContractOptions, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLockContract(network, captcha, lockContractOptions, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create a transfer code to transfer an airdropped key to an email address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransferCode(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTransferCode(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create verifier. (deprecated in favor of addEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Delete application by id.
     * @param {string} id Application id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApplication(id, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplication(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Delete a checkout config
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCheckoutConfig(id, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCheckoutConfig(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Removes a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventVerifier(slug, verifierAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventVerifier(slug, verifierAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Remove verifier. (deprecated in favor of deleteEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async deleteVerifier(network, lockAddress, verifierAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVerifier(network, lockAddress, verifierAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Denies users to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Denies a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async denyRsvp(network, lockAddress, userAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.denyRsvp(network, lockAddress, userAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Disconnect stripe account.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disconnectStripe(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectStripe(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Download the ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadReceipts(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReceipts(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Ejects a user given their public key. In the case of failure, a rejected promise is returned to the caller.
     * @param {string} publicKey 
     * @param {{ [key: string]: any; }} requestBody Structured data used to generate the signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ejectUser(publicKey, requestBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ejectUser(publicKey, requestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Send QR code by email.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emailTicket(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.emailTicket(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List of verifiers for an event.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventVerifiers(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventVerifiers(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Starts Job process to get all the keys for the given lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportKeys(network, lockAddress, query, filterKey, expiration, approval, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportKeys(network, lockAddress, query, filterKey, expiration, approval, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Generate and return an Apple Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateAppleWalletPass(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateAppleWalletPass(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Generate certificate for specific key
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateCertificate(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateCertificate(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Generate and return a Google Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateGoogleWalletPass(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateGoogleWalletPass(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Generate a ticket for a key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateTicket(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateTicket(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get the price breakdown for recipients & total charges with fees for a specific lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {Array<string>} purchaseData The purchase data passed to the purchase function
     * @param {Array<string>} recipients The recipients passed to the purchase function
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChargesForLock(network, lockAddress, purchaseData, recipients, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChargesForLock(network, lockAddress, purchaseData, recipients, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * 
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCheckoutConfig(id, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCheckoutConfig(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get custom email content for a specific template and lock details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomEmailContent(network, lockAddress, template, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomEmailContent(network, lockAddress, template, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Retrieves data for recipients and captcha.
     * @param {Array<string>} recipients 
     * @param {string} captchaValue 
     * @param {string} lockAddress 
     * @param {number} network 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get the data for recipients and gitcoin passport. This is to be used in conjunction with the Gitcoin passport scorer.
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get the data for recipients and guild. This is to be used in conjunction with the Guild
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataForRecipientsAndGuild(network, lockAddress, recipients, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataForRecipientsAndGuild(network, lockAddress, recipients, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Retrieves an event based on its slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvent(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get details of an event collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventCollection(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCollection(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get the event details from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getEventDetails(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventDetails(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * get a ticket for a key by event.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventTicket(slug, network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTicket(slug, network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get events in a collection
     * @param {string} slug 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventsInCollection(slug, page, pageSize, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsInCollection(slug, page, pageSize, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get job result by job id
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} jobId Id of the exported job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExportedKeys(network, lockAddress, jobId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExportedKeys(network, lockAddress, jobId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get saved lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLockSettings(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLockSettings(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get lock setting by slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLockSettingsBySlug(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLockSettingsBySlug(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Returns Stripe connection details
     * @param {string} lockAddress Lock address.
     * @param {number} chain Lock network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLockStripeConnectionDetails(lockAddress, chain, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLockStripeConnectionDetails(lockAddress, chain, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get receipt details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReceipt(network, lockAddress, hash, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReceipt(network, lockAddress, hash, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get all receipts for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReceipts(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReceipts(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get supplier details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReceiptsBase(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptsBase(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Retrieve the status of receipt generation for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReceiptsStatus(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptsStatus(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Lists the locks connected to Stripe for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStripeConnections(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeConnections(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get an active subscription associated with the key. It can be fiat or crypto.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscription(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscription(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * get a ticket for a key by lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTicket(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get unapproved events for a collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnapprovedEventsForCollection(slug, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnapprovedEventsForCollection(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Gets User account type by email.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserAccountType(emailAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccountType(emailAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * get user metadata for a lock.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserMetadata(lockAddress, userAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMetadata(lockAddress, userAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Given a user\'s email address, retrieves their private key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserPrivateKey(emailAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPrivateKey(emailAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Given a user\'s email address, retrieves their recovery phrase. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserRecoveryPhrase(emailAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRecoveryPhrase(emailAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Gets Coinbase WAAS token for user by email and selected provider.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {string} selectedProvider 
     * @param {GetWaasTokenRequest} getWaasTokenRequest JSON object containing sign in token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Returns if credit card payment are enable for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isCardPaymentEnabledForLock(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isCardPaymentEnabledForLock(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get key and user metadata.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async keyMetadata(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.keyMetadata(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager (deprecated, use keys-by-page)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * A list of all checkout configs created by the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCheckoutConfigs(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCheckoutConfigs(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List payment methods for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPaymentMethods(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listPaymentMethods(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockMetadata(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockMetadata(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Login as a user using Sign in with Ethereum message signed by the wallet address.
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(loginRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Verify an access token using Privy\'s library and authenticate the user
     * @param {LoginWithPrivyRequest} loginWithPrivyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginWithPrivy(loginWithPrivyRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithPrivy(loginWithPrivyRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Logout from locksmith. This will invalidate all your session tokens.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Provide a unique nonce for using in sign in with ethereum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonce(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nonce(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {number} [amount] Amount of tokens you want to get the price for in USD.
     * @param {string} [address] The erc20 address you want to get the price for. If nothing is passed, the API will return the native token price.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async price(network, amount, address, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.price(network, amount, address, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create purchase intent for stripe.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchase(network, lockAddress, purchaseRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchase(network, lockAddress, purchaseRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * resubscribe emails from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reSubscribeEmail(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reSubscribeEmail(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Remove a single event from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {RemoveEventBody} removeEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeEventFromCollection(slug, removeEventBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeEventFromCollection(slug, removeEventBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Remove a manager address from an event collection
     * @param {string} slug 
     * @param {RemoveManagerBody} removeManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeManagerFromEventCollection(slug, removeManagerBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeManagerFromEventCollection(slug, removeManagerBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Remove all payment methods for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removePaymentMethods(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removePaymentMethods(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Invalidate a session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revoke(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.revoke(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * User applies to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {RsvpRequest} [rsvpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rsvp(network, lockAddress, captcha, rsvpRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rsvp(network, lockAddress, captcha, rsvpRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Save custom email content for a specif lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveCustomEmailContent(network, lockAddress, template, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomEmailContent(network, lockAddress, template, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Save event data
     * @param {SaveEventDataRequest} [saveEventDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveEventData(saveEventDataRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveEventData(saveEventDataRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Save lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {LockSettings} [lockSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveLockSetting(network, lockAddress, lockSettings, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveLockSetting(network, lockAddress, lockSettings, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create purchaser details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveReceipt(network, lockAddress, hash, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveReceipt(network, lockAddress, hash, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Create supplier details.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveReceiptsBase(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveReceiptsBase(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Send custom email to recipients
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {SendCustomEmailRequest} [sendCustomEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Send event invites to a list of recipients
     * @param {string} slug Slug
     * @param {SendEventInvitesRequest} [sendEventInvitesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEventInvites(slug, sendEventInvitesRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEventInvites(slug, sendEventInvitesRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Sends a verification code to the user\'s email if the current code is expired or does not exist.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendVerificationCode(captcha, emailAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerificationCode(captcha, emailAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Setup a payment method for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setupPayment(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setupPayment(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Generate signature to prove validity of token.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signTicket(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signTicket(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get QR code for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ticketQRCode(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ticketQRCode(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get the verification URL for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ticketVerificationUrl(network, lockAddress, keyId, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ticketVerificationUrl(network, lockAddress, keyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Finalize the transfer of a key to a recipient. This is to copy over any locksmith related data to the recipient\'s account.
     * @param {TransferDoneRequest} [transferDoneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferDone(transferDoneRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transferDone(transferDoneRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Unsubscribe email from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unsubscribeEmail(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeEmail(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update application by id.
     * @param {string} id Application id.
     * @param {ApplicationBody} [applicationBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateApplication(id, applicationBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(id, applicationBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * A new checkout config is created or updated if it already exists
     * @param {string} id This is optional. If you don\&#39;t pass an ID, a new config will be created. If you pass an ID, the config will be created.
     * @param {UpdateCheckoutConfigRequest} [updateCheckoutConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCheckoutConfig(id, updateCheckoutConfigRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCheckoutConfig(id, updateCheckoutConfigRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update an existing event collection
     * @param {string} slug 
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEventCollection(slug, eventCollectionBody, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventCollection(slug, eventCollectionBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update or create metadata for key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update or create metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Updates a user\'s private key, using their email address as key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {UpdateUserEncryptedPrivateKeyRequest} updateUserEncryptedPrivateKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update or create user metadata for lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {UpdateUserMetadataRequest} updateUserMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Update or create user metadata for lock.
     * @param {UpdateUsersMetadataRequest} updateUsersMetadataRequest users array containing user metadata.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUsersMetadata(updateUsersMetadataRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsersMetadata(updateUsersMetadataRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Upload images to locksmith
     * @param {Array<any>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadImages(images, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImages(images, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Get user details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async user(options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.user(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Check if an address is a verifier of the lock. (deprecated in favor of eventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async verifier(network, lockAddress, verifierAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifier(network, lockAddress, verifierAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * List of verifiers for the lock address. (deprecated in favor of eventVerifiers)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async verifiers(network, lockAddress, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifiers(network, lockAddress, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    },
    /**
     * Verifies the email code sent to the users email address.
     * @param {string} emailAddress 
     * @param {VerifyEmailCodeRequest} verifyEmailCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmailCode(emailAddress, verifyEmailCodeRequest, options) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmailCode(emailAddress, verifyEmailCodeRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios2, BASE_PATH, configuration);
    }
  };
};
var DefaultApiFactory = function(configuration, basePath, axios) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * Add an event to a collection
     * @param {string} slug 
     * @param {AddEventToCollectionBody} addEventToCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEventToCollection(slug, addEventToCollectionBody, options) {
      return localVarFp.addEventToCollection(slug, addEventToCollectionBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Adds a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEventVerifier(slug, verifierAddress, createVerifierRequest, options) {
      return localVarFp.addEventVerifier(slug, verifierAddress, createVerifierRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Add a new manager address to an event collection
     * @param {string} slug 
     * @param {AddManagerBody} addManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addManagerToEventCollection(slug, addManagerBody, options) {
      return localVarFp.addManagerToEventCollection(slug, addManagerBody, options).then((request) => request(axios, basePath));
    },
    /**
     * List applications created the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applications(options) {
      return localVarFp.applications(options).then((request) => request(axios, basePath));
    },
    /**
     * Approves users to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
      return localVarFp.approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Approve a single event within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {ApproveEventBody} approveEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveEvent(slug, approveEventBody, options) {
      return localVarFp.approveEvent(slug, approveEventBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Approves refunds for an event.
     * @param {string} slug Slug
     * @param {ApproveRefundsRequest} approveRefundsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveRefunds(slug, approveRefundsRequest, options) {
      return localVarFp.approveRefunds(slug, approveRefundsRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Approves a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    approveRsvp(network, lockAddress, userAddress, options) {
      return localVarFp.approveRsvp(network, lockAddress, userAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns the merkle proof of an approved refund.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approvedRefunds(slug, options) {
      return localVarFp.approvedRefunds(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balance(options) {
      return localVarFp.balance(options).then((request) => request(axios, basePath));
    },
    /**
     * Bulk approve multiple events within an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkApproveEventsBody} bulkApproveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkApproveEvents(slug, bulkApproveEventsBody, options) {
      return localVarFp.bulkApproveEvents(slug, bulkApproveEventsBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Bulk remove multiple events from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {BulkRemoveEventsBody} bulkRemoveEventsBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkRemoveEvents(slug, bulkRemoveEventsBody, options) {
      return localVarFp.bulkRemoveEvents(slug, bulkRemoveEventsBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Cancel a fiat subscription.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(network, lockAddress, keyId, options) {
      return localVarFp.cancelSubscription(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Capture a purchase
     * @param {CapturePurchaseRequest} [capturePurchaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    capturePurchase(capturePurchaseRequest, options) {
      return localVarFp.capturePurchase(capturePurchaseRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Check if claim is possible for a given purchase
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {CheckClaimRequest} [checkClaimRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkClaim(network, lockAddress, checkClaimRequest, options) {
      return localVarFp.checkClaim(network, lockAddress, checkClaimRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Mark an event ticket as checked in.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkEventTicket(slug, network, lockAddress, keyId, options) {
      return localVarFp.checkEventTicket(slug, network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Check if a user exists in Privy by email or wallet address
     * @param {PrivyUserCheckBody} privyUserCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPrivyUser(privyUserCheckBody, options) {
      return localVarFp.checkPrivyUser(privyUserCheckBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Mark a lock ticket as checked in.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    checkTicket(network, lockAddress, keyId, options) {
      return localVarFp.checkTicket(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Claim a membership key for a lock. If not authenticated, requires an email in the body.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {ClaimRequest} claimRequest 
     * @param {string} [recipient] Recipient address for key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claim(network, lockAddress, captcha, claimRequest, recipient, options) {
      return localVarFp.claim(network, lockAddress, captcha, claimRequest, recipient, options).then((request) => request(axios, basePath));
    },
    /**
     * Connect a stripe account to a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ConnectStripeAccountRequest} [connectStripeAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options) {
      return localVarFp.connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Create application associated with user wallet.
     * @param {ApplicationBody} applicationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplication(applicationBody, options) {
      return localVarFp.createApplication(applicationBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Request the creation of a ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDownloadReceiptsRequest(network, lockAddress, options) {
      return localVarFp.createDownloadReceiptsRequest(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new event collection
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventCollection(eventCollectionBody, options) {
      return localVarFp.createEventCollection(eventCollectionBody, options).then((request) => request(axios, basePath));
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {string} captcha Recaptcha value to pass.
     * @param {LockContractOptions} lockContractOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLockContract(network, captcha, lockContractOptions, options) {
      return localVarFp.createLockContract(network, captcha, lockContractOptions, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a transfer code to transfer an airdropped key to an email address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransferCode(network, lockAddress, keyId, options) {
      return localVarFp.createTransferCode(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Create verifier. (deprecated in favor of addEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {CreateVerifierRequest} [createVerifierRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options) {
      return localVarFp.createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Delete application by id.
     * @param {string} id Application id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplication(id, options) {
      return localVarFp.deleteApplication(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Delete a checkout config
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCheckoutConfig(id, options) {
      return localVarFp.deleteCheckoutConfig(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Removes a verifier for an event.
     * @param {string} slug Slug
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventVerifier(slug, verifierAddress, options) {
      return localVarFp.deleteEventVerifier(slug, verifierAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Remove verifier. (deprecated in favor of deleteEventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    deleteVerifier(network, lockAddress, verifierAddress, options) {
      return localVarFp.deleteVerifier(network, lockAddress, verifierAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Denies users to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
      return localVarFp.denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Denies a user to attend an event. (does not airdrop!)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    denyRsvp(network, lockAddress, userAddress, options) {
      return localVarFp.denyRsvp(network, lockAddress, userAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Disconnect stripe account.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disconnectStripe(network, lockAddress, options) {
      return localVarFp.disconnectStripe(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Download the ZIP file containing all receipts for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadReceipts(network, lockAddress, options) {
      return localVarFp.downloadReceipts(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Ejects a user given their public key. In the case of failure, a rejected promise is returned to the caller.
     * @param {string} publicKey 
     * @param {{ [key: string]: any; }} requestBody Structured data used to generate the signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ejectUser(publicKey, requestBody, options) {
      return localVarFp.ejectUser(publicKey, requestBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Send QR code by email.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailTicket(network, lockAddress, keyId, options) {
      return localVarFp.emailTicket(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * List of verifiers for an event.
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventVerifiers(slug, options) {
      return localVarFp.eventVerifiers(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * Starts Job process to get all the keys for the given lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportKeys(network, lockAddress, query, filterKey, expiration, approval, options) {
      return localVarFp.exportKeys(network, lockAddress, query, filterKey, expiration, approval, options).then((request) => request(axios, basePath));
    },
    /**
     * Generate and return an Apple Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAppleWalletPass(network, lockAddress, keyId, options) {
      return localVarFp.generateAppleWalletPass(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Generate certificate for specific key
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateCertificate(network, lockAddress, keyId, options) {
      return localVarFp.generateCertificate(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Generate and return a Google Wallet pass for a specified key associated with a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGoogleWalletPass(network, lockAddress, keyId, options) {
      return localVarFp.generateGoogleWalletPass(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Generate a ticket for a key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateTicket(network, lockAddress, keyId, options) {
      return localVarFp.generateTicket(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the price breakdown for recipients & total charges with fees for a specific lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {Array<string>} purchaseData The purchase data passed to the purchase function
     * @param {Array<string>} recipients The recipients passed to the purchase function
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesForLock(network, lockAddress, purchaseData, recipients, options) {
      return localVarFp.getChargesForLock(network, lockAddress, purchaseData, recipients, options).then((request) => request(axios, basePath));
    },
    /**
     * 
     * @param {string} id The id of the checkout config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCheckoutConfig(id, options) {
      return localVarFp.getCheckoutConfig(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Get custom email content for a specific template and lock details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomEmailContent(network, lockAddress, template, options) {
      return localVarFp.getCustomEmailContent(network, lockAddress, template, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieves data for recipients and captcha.
     * @param {Array<string>} recipients 
     * @param {string} captchaValue 
     * @param {string} lockAddress 
     * @param {number} network 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options) {
      return localVarFp.getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the data for recipients and gitcoin passport. This is to be used in conjunction with the Gitcoin passport scorer.
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options) {
      return localVarFp.getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the data for recipients and guild. This is to be used in conjunction with the Guild
     * @param {number} network The network the lock is on
     * @param {string} [lockAddress] The address of the lock
     * @param {Array<string>} [recipients] The list of recipients for the purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataForRecipientsAndGuild(network, lockAddress, recipients, options) {
      return localVarFp.getDataForRecipientsAndGuild(network, lockAddress, recipients, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieves an event based on its slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvent(slug, options) {
      return localVarFp.getEvent(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * Get details of an event collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventCollection(slug, options) {
      return localVarFp.getEventCollection(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the event details from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getEventDetails(network, lockAddress, options) {
      return localVarFp.getEventDetails(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * get a ticket for a key by event.
     * @param {string} slug Slug
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTicket(slug, network, lockAddress, keyId, options) {
      return localVarFp.getEventTicket(slug, network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get events in a collection
     * @param {string} slug 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventsInCollection(slug, page, pageSize, options) {
      return localVarFp.getEventsInCollection(slug, page, pageSize, options).then((request) => request(axios, basePath));
    },
    /**
     * Get job result by job id
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} jobId Id of the exported job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportedKeys(network, lockAddress, jobId, options) {
      return localVarFp.getExportedKeys(network, lockAddress, jobId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get saved lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockSettings(network, lockAddress, options) {
      return localVarFp.getLockSettings(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Get lock setting by slug
     * @param {string} slug Slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockSettingsBySlug(slug, options) {
      return localVarFp.getLockSettingsBySlug(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns Stripe connection details
     * @param {string} lockAddress Lock address.
     * @param {number} chain Lock network
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockStripeConnectionDetails(lockAddress, chain, options) {
      return localVarFp.getLockStripeConnectionDetails(lockAddress, chain, options).then((request) => request(axios, basePath));
    },
    /**
     * Get receipt details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceipt(network, lockAddress, hash, options) {
      return localVarFp.getReceipt(network, lockAddress, hash, options).then((request) => request(axios, basePath));
    },
    /**
     * Get all receipts for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceipts(network, lockAddress, options) {
      return localVarFp.getReceipts(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Get supplier details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceiptsBase(network, lockAddress, options) {
      return localVarFp.getReceiptsBase(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve the status of receipt generation for a lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReceiptsStatus(network, lockAddress, options) {
      return localVarFp.getReceiptsStatus(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Lists the locks connected to Stripe for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStripeConnections(options) {
      return localVarFp.getStripeConnections(options).then((request) => request(axios, basePath));
    },
    /**
     * Get an active subscription associated with the key. It can be fiat or crypto.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription(network, lockAddress, keyId, options) {
      return localVarFp.getSubscription(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * get a ticket for a key by lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTicket(network, lockAddress, keyId, options) {
      return localVarFp.getTicket(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get unapproved events for a collection
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnapprovedEventsForCollection(slug, options) {
      return localVarFp.getUnapprovedEventsForCollection(slug, options).then((request) => request(axios, basePath));
    },
    /**
     * Gets User account type by email.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAccountType(emailAddress, options) {
      return localVarFp.getUserAccountType(emailAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * get user metadata for a lock.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserMetadata(lockAddress, userAddress, options) {
      return localVarFp.getUserMetadata(lockAddress, userAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Given a user\'s email address, retrieves their private key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPrivateKey(emailAddress, options) {
      return localVarFp.getUserPrivateKey(emailAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Given a user\'s email address, retrieves their recovery phrase. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRecoveryPhrase(emailAddress, options) {
      return localVarFp.getUserRecoveryPhrase(emailAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Gets Coinbase WAAS token for user by email and selected provider.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {string} selectedProvider 
     * @param {GetWaasTokenRequest} getWaasTokenRequest JSON object containing sign in token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options) {
      return localVarFp.getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Returns if credit card payment are enable for a lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isCardPaymentEnabledForLock(network, lockAddress, options) {
      return localVarFp.isCardPaymentEnabledForLock(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Get key and user metadata.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    keyMetadata(network, lockAddress, keyId, options) {
      return localVarFp.keyMetadata(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager (deprecated, use keys-by-page)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
      return localVarFp.keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options).then((request) => request(axios, basePath));
    },
    /**
     * List of lock keys by network with additional metadata if the caller is the lockManager
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} query Query you want to search for.
     * @param {string} filterKey The key you want to search on.
     * @param {string} [expiration] Expiration time
     * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
     * @param {number} [page] Page number when paginating
     * @param {number} [max] Maximum number of items to get by page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
      return localVarFp.keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options).then((request) => request(axios, basePath));
    },
    /**
     * A list of all checkout configs created by the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCheckoutConfigs(options) {
      return localVarFp.listCheckoutConfigs(options).then((request) => request(axios, basePath));
    },
    /**
     * List payment methods for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPaymentMethods(options) {
      return localVarFp.listPaymentMethods(options).then((request) => request(axios, basePath));
    },
    /**
     * Get metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockMetadata(network, lockAddress, options) {
      return localVarFp.lockMetadata(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Login as a user using Sign in with Ethereum message signed by the wallet address.
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginRequest, options) {
      return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Verify an access token using Privy\'s library and authenticate the user
     * @param {LoginWithPrivyRequest} loginWithPrivyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginWithPrivy(loginWithPrivyRequest, options) {
      return localVarFp.loginWithPrivy(loginWithPrivyRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Logout from locksmith. This will invalidate all your session tokens.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options) {
      return localVarFp.logout(options).then((request) => request(axios, basePath));
    },
    /**
     * Provide a unique nonce for using in sign in with ethereum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonce(options) {
      return localVarFp.nonce(options).then((request) => request(axios, basePath));
    },
    /**
     * 
     * @param {number} network Network id.
     * @param {number} [amount] Amount of tokens you want to get the price for in USD.
     * @param {string} [address] The erc20 address you want to get the price for. If nothing is passed, the API will return the native token price.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    price(network, amount, address, options) {
      return localVarFp.price(network, amount, address, options).then((request) => request(axios, basePath));
    },
    /**
     * Create purchase intent for stripe.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchase(network, lockAddress, purchaseRequest, options) {
      return localVarFp.purchase(network, lockAddress, purchaseRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * resubscribe emails from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reSubscribeEmail(network, lockAddress, options) {
      return localVarFp.reSubscribeEmail(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Remove a single event from an event collection.
     * @param {string} slug Slug of the event collection.
     * @param {RemoveEventBody} removeEventBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeEventFromCollection(slug, removeEventBody, options) {
      return localVarFp.removeEventFromCollection(slug, removeEventBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Remove a manager address from an event collection
     * @param {string} slug 
     * @param {RemoveManagerBody} removeManagerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeManagerFromEventCollection(slug, removeManagerBody, options) {
      return localVarFp.removeManagerFromEventCollection(slug, removeManagerBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Remove all payment methods for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removePaymentMethods(options) {
      return localVarFp.removePaymentMethods(options).then((request) => request(axios, basePath));
    },
    /**
     * Invalidate a session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revoke(options) {
      return localVarFp.revoke(options).then((request) => request(axios, basePath));
    },
    /**
     * User applies to attend an event.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} captcha Recaptcha value to pass.
     * @param {RsvpRequest} [rsvpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rsvp(network, lockAddress, captcha, rsvpRequest, options) {
      return localVarFp.rsvp(network, lockAddress, captcha, rsvpRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Save custom email content for a specif lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} template Template id for email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCustomEmailContent(network, lockAddress, template, options) {
      return localVarFp.saveCustomEmailContent(network, lockAddress, template, options).then((request) => request(axios, basePath));
    },
    /**
     * Save event data
     * @param {SaveEventDataRequest} [saveEventDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventData(saveEventDataRequest, options) {
      return localVarFp.saveEventData(saveEventDataRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Save lock settings
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {LockSettings} [lockSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLockSetting(network, lockAddress, lockSettings, options) {
      return localVarFp.saveLockSetting(network, lockAddress, lockSettings, options).then((request) => request(axios, basePath));
    },
    /**
     * Create purchaser details
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} hash Hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReceipt(network, lockAddress, hash, options) {
      return localVarFp.saveReceipt(network, lockAddress, hash, options).then((request) => request(axios, basePath));
    },
    /**
     * Create supplier details.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveReceiptsBase(network, lockAddress, options) {
      return localVarFp.saveReceiptsBase(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Send custom email to recipients
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {SendCustomEmailRequest} [sendCustomEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options) {
      return localVarFp.sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Send event invites to a list of recipients
     * @param {string} slug Slug
     * @param {SendEventInvitesRequest} [sendEventInvitesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEventInvites(slug, sendEventInvitesRequest, options) {
      return localVarFp.sendEventInvites(slug, sendEventInvitesRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Sends a verification code to the user\'s email if the current code is expired or does not exist.
     * @param {string} captcha Recaptcha value to pass.
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerificationCode(captcha, emailAddress, options) {
      return localVarFp.sendVerificationCode(captcha, emailAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Setup a payment method for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setupPayment(options) {
      return localVarFp.setupPayment(options).then((request) => request(axios, basePath));
    },
    /**
     * Generate signature to prove validity of token.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signTicket(network, lockAddress, keyId, options) {
      return localVarFp.signTicket(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get QR code for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ticketQRCode(network, lockAddress, keyId, options) {
      return localVarFp.ticketQRCode(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get the verification URL for the key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ticketVerificationUrl(network, lockAddress, keyId, options) {
      return localVarFp.ticketVerificationUrl(network, lockAddress, keyId, options).then((request) => request(axios, basePath));
    },
    /**
     * Finalize the transfer of a key to a recipient. This is to copy over any locksmith related data to the recipient\'s account.
     * @param {TransferDoneRequest} [transferDoneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferDone(transferDoneRequest, options) {
      return localVarFp.transferDone(transferDoneRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Unsubscribe email from lock
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsubscribeEmail(network, lockAddress, options) {
      return localVarFp.unsubscribeEmail(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Update application by id.
     * @param {string} id Application id.
     * @param {ApplicationBody} [applicationBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplication(id, applicationBody, options) {
      return localVarFp.updateApplication(id, applicationBody, options).then((request) => request(axios, basePath));
    },
    /**
     * A new checkout config is created or updated if it already exists
     * @param {string} id This is optional. If you don\&#39;t pass an ID, a new config will be created. If you pass an ID, the config will be created.
     * @param {UpdateCheckoutConfigRequest} [updateCheckoutConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCheckoutConfig(id, updateCheckoutConfigRequest, options) {
      return localVarFp.updateCheckoutConfig(id, updateCheckoutConfigRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Update an existing event collection
     * @param {string} slug 
     * @param {EventCollectionBody} eventCollectionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventCollection(slug, eventCollectionBody, options) {
      return localVarFp.updateEventCollection(slug, eventCollectionBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Update or create metadata for key.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} keyId Key Id.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options) {
      return localVarFp.updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Update or create metadata for lock address.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options) {
      return localVarFp.updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Updates a user\'s private key, using their email address as key. In the case of failure a rejected promise is returned to the caller.
     * @param {string} emailAddress 
     * @param {UpdateUserEncryptedPrivateKeyRequest} updateUserEncryptedPrivateKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options) {
      return localVarFp.updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Update or create user metadata for lock.
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} userAddress User address.
     * @param {UpdateUserMetadataRequest} updateUserMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options) {
      return localVarFp.updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Update or create user metadata for lock.
     * @param {UpdateUsersMetadataRequest} updateUsersMetadataRequest users array containing user metadata.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsersMetadata(updateUsersMetadataRequest, options) {
      return localVarFp.updateUsersMetadata(updateUsersMetadataRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Upload images to locksmith
     * @param {Array<any>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImages(images, options) {
      return localVarFp.uploadImages(images, options).then((request) => request(axios, basePath));
    },
    /**
     * Get user details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    user(options) {
      return localVarFp.user(options).then((request) => request(axios, basePath));
    },
    /**
     * Check if an address is a verifier of the lock. (deprecated in favor of eventVerifier)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {string} verifierAddress User address to use as verifier
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    verifier(network, lockAddress, verifierAddress, options) {
      return localVarFp.verifier(network, lockAddress, verifierAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * List of verifiers for the lock address. (deprecated in favor of eventVerifiers)
     * @param {number} network Network id.
     * @param {string} lockAddress Lock address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    verifiers(network, lockAddress, options) {
      return localVarFp.verifiers(network, lockAddress, options).then((request) => request(axios, basePath));
    },
    /**
     * Verifies the email code sent to the users email address.
     * @param {string} emailAddress 
     * @param {VerifyEmailCodeRequest} verifyEmailCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmailCode(emailAddress, verifyEmailCodeRequest, options) {
      return localVarFp.verifyEmailCode(emailAddress, verifyEmailCodeRequest, options).then((request) => request(axios, basePath));
    }
  };
};
var DefaultApi = class extends BaseAPI {
  /**
   * Add an event to a collection
   * @param {string} slug 
   * @param {AddEventToCollectionBody} addEventToCollectionBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  addEventToCollection(slug, addEventToCollectionBody, options) {
    return DefaultApiFp(this.configuration).addEventToCollection(slug, addEventToCollectionBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Adds a verifier for an event.
   * @param {string} slug Slug
   * @param {string} verifierAddress User address to use as verifier
   * @param {CreateVerifierRequest} [createVerifierRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  addEventVerifier(slug, verifierAddress, createVerifierRequest, options) {
    return DefaultApiFp(this.configuration).addEventVerifier(slug, verifierAddress, createVerifierRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Add a new manager address to an event collection
   * @param {string} slug 
   * @param {AddManagerBody} addManagerBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  addManagerToEventCollection(slug, addManagerBody, options) {
    return DefaultApiFp(this.configuration).addManagerToEventCollection(slug, addManagerBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List applications created the user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  applications(options) {
    return DefaultApiFp(this.configuration).applications(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Approves users to attend an event. (does not airdrop!)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
    return DefaultApiFp(this.configuration).approveAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Approve a single event within an event collection.
   * @param {string} slug Slug of the event collection.
   * @param {ApproveEventBody} approveEventBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  approveEvent(slug, approveEventBody, options) {
    return DefaultApiFp(this.configuration).approveEvent(slug, approveEventBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Approves refunds for an event.
   * @param {string} slug Slug
   * @param {ApproveRefundsRequest} approveRefundsRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  approveRefunds(slug, approveRefundsRequest, options) {
    return DefaultApiFp(this.configuration).approveRefunds(slug, approveRefundsRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Approves a user to attend an event. (does not airdrop!)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} userAddress User address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  approveRsvp(network, lockAddress, userAddress, options) {
    return DefaultApiFp(this.configuration).approveRsvp(network, lockAddress, userAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Returns the merkle proof of an approved refund.
   * @param {string} slug Slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  approvedRefunds(slug, options) {
    return DefaultApiFp(this.configuration).approvedRefunds(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  balance(options) {
    return DefaultApiFp(this.configuration).balance(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Bulk approve multiple events within an event collection.
   * @param {string} slug Slug of the event collection.
   * @param {BulkApproveEventsBody} bulkApproveEventsBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  bulkApproveEvents(slug, bulkApproveEventsBody, options) {
    return DefaultApiFp(this.configuration).bulkApproveEvents(slug, bulkApproveEventsBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Bulk remove multiple events from an event collection.
   * @param {string} slug Slug of the event collection.
   * @param {BulkRemoveEventsBody} bulkRemoveEventsBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  bulkRemoveEvents(slug, bulkRemoveEventsBody, options) {
    return DefaultApiFp(this.configuration).bulkRemoveEvents(slug, bulkRemoveEventsBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Cancel a fiat subscription.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  cancelSubscription(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).cancelSubscription(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Capture a purchase
   * @param {CapturePurchaseRequest} [capturePurchaseRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  capturePurchase(capturePurchaseRequest, options) {
    return DefaultApiFp(this.configuration).capturePurchase(capturePurchaseRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Check if claim is possible for a given purchase
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {CheckClaimRequest} [checkClaimRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  checkClaim(network, lockAddress, checkClaimRequest, options) {
    return DefaultApiFp(this.configuration).checkClaim(network, lockAddress, checkClaimRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Mark an event ticket as checked in.
   * @param {string} slug Slug
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  checkEventTicket(slug, network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).checkEventTicket(slug, network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Check if a user exists in Privy by email or wallet address
   * @param {PrivyUserCheckBody} privyUserCheckBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  checkPrivyUser(privyUserCheckBody, options) {
    return DefaultApiFp(this.configuration).checkPrivyUser(privyUserCheckBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Mark a lock ticket as checked in.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  checkTicket(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).checkTicket(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Claim a membership key for a lock. If not authenticated, requires an email in the body.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} captcha Recaptcha value to pass.
   * @param {ClaimRequest} claimRequest 
   * @param {string} [recipient] Recipient address for key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  claim(network, lockAddress, captcha, claimRequest, recipient, options) {
    return DefaultApiFp(this.configuration).claim(network, lockAddress, captcha, claimRequest, recipient, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Connect a stripe account to a lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {ConnectStripeAccountRequest} [connectStripeAccountRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options) {
    return DefaultApiFp(this.configuration).connectStripeAccount(network, lockAddress, connectStripeAccountRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create application associated with user wallet.
   * @param {ApplicationBody} applicationBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createApplication(applicationBody, options) {
    return DefaultApiFp(this.configuration).createApplication(applicationBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Request the creation of a ZIP file containing all receipts for a lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createDownloadReceiptsRequest(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).createDownloadReceiptsRequest(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create a new event collection
   * @param {EventCollectionBody} eventCollectionBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createEventCollection(eventCollectionBody, options) {
    return DefaultApiFp(this.configuration).createEventCollection(eventCollectionBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * 
   * @param {number} network Network id.
   * @param {string} captcha Recaptcha value to pass.
   * @param {LockContractOptions} lockContractOptions 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createLockContract(network, captcha, lockContractOptions, options) {
    return DefaultApiFp(this.configuration).createLockContract(network, captcha, lockContractOptions, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create a transfer code to transfer an airdropped key to an email address.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createTransferCode(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).createTransferCode(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create verifier. (deprecated in favor of addEventVerifier)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} verifierAddress User address to use as verifier
   * @param {CreateVerifierRequest} [createVerifierRequest] 
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options) {
    return DefaultApiFp(this.configuration).createVerifier(network, lockAddress, verifierAddress, createVerifierRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Delete application by id.
   * @param {string} id Application id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  deleteApplication(id, options) {
    return DefaultApiFp(this.configuration).deleteApplication(id, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Delete a checkout config
   * @param {string} id The id of the checkout config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  deleteCheckoutConfig(id, options) {
    return DefaultApiFp(this.configuration).deleteCheckoutConfig(id, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Removes a verifier for an event.
   * @param {string} slug Slug
   * @param {string} verifierAddress User address to use as verifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  deleteEventVerifier(slug, verifierAddress, options) {
    return DefaultApiFp(this.configuration).deleteEventVerifier(slug, verifierAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Remove verifier. (deprecated in favor of deleteEventVerifier)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} verifierAddress User address to use as verifier
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  deleteVerifier(network, lockAddress, verifierAddress, options) {
    return DefaultApiFp(this.configuration).deleteVerifier(network, lockAddress, verifierAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Denies users to attend an event.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {ApproveAttendeesRsvpRequest} approveAttendeesRsvpRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options) {
    return DefaultApiFp(this.configuration).denyAttendeesRsvp(network, lockAddress, approveAttendeesRsvpRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Denies a user to attend an event. (does not airdrop!)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} userAddress User address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  denyRsvp(network, lockAddress, userAddress, options) {
    return DefaultApiFp(this.configuration).denyRsvp(network, lockAddress, userAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Disconnect stripe account.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  disconnectStripe(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).disconnectStripe(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Download the ZIP file containing all receipts for a lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  downloadReceipts(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).downloadReceipts(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Ejects a user given their public key. In the case of failure, a rejected promise is returned to the caller.
   * @param {string} publicKey 
   * @param {{ [key: string]: any; }} requestBody Structured data used to generate the signature
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  ejectUser(publicKey, requestBody, options) {
    return DefaultApiFp(this.configuration).ejectUser(publicKey, requestBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Send QR code by email.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  emailTicket(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).emailTicket(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List of verifiers for an event.
   * @param {string} slug Slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  eventVerifiers(slug, options) {
    return DefaultApiFp(this.configuration).eventVerifiers(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Starts Job process to get all the keys for the given lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} query Query you want to search for.
   * @param {string} filterKey The key you want to search on.
   * @param {string} [expiration] Expiration time
   * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  exportKeys(network, lockAddress, query, filterKey, expiration, approval, options) {
    return DefaultApiFp(this.configuration).exportKeys(network, lockAddress, query, filterKey, expiration, approval, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Generate and return an Apple Wallet pass for a specified key associated with a lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  generateAppleWalletPass(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).generateAppleWalletPass(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Generate certificate for specific key
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  generateCertificate(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).generateCertificate(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Generate and return a Google Wallet pass for a specified key associated with a lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  generateGoogleWalletPass(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).generateGoogleWalletPass(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Generate a ticket for a key.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  generateTicket(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).generateTicket(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get the price breakdown for recipients & total charges with fees for a specific lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {Array<string>} purchaseData The purchase data passed to the purchase function
   * @param {Array<string>} recipients The recipients passed to the purchase function
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getChargesForLock(network, lockAddress, purchaseData, recipients, options) {
    return DefaultApiFp(this.configuration).getChargesForLock(network, lockAddress, purchaseData, recipients, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * 
   * @param {string} id The id of the checkout config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getCheckoutConfig(id, options) {
    return DefaultApiFp(this.configuration).getCheckoutConfig(id, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get custom email content for a specific template and lock details
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} template Template id for email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getCustomEmailContent(network, lockAddress, template, options) {
    return DefaultApiFp(this.configuration).getCustomEmailContent(network, lockAddress, template, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Retrieves data for recipients and captcha.
   * @param {Array<string>} recipients 
   * @param {string} captchaValue 
   * @param {string} lockAddress 
   * @param {number} network 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options) {
    return DefaultApiFp(this.configuration).getDataForRecipientsAndCaptcha(recipients, captchaValue, lockAddress, network, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get the data for recipients and gitcoin passport. This is to be used in conjunction with the Gitcoin passport scorer.
   * @param {number} network The network the lock is on
   * @param {string} [lockAddress] The address of the lock
   * @param {Array<string>} [recipients] The list of recipients for the purchase
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options) {
    return DefaultApiFp(this.configuration).getDataForRecipientsAndGitcoinPassport(network, lockAddress, recipients, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get the data for recipients and guild. This is to be used in conjunction with the Guild
   * @param {number} network The network the lock is on
   * @param {string} [lockAddress] The address of the lock
   * @param {Array<string>} [recipients] The list of recipients for the purchase
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getDataForRecipientsAndGuild(network, lockAddress, recipients, options) {
    return DefaultApiFp(this.configuration).getDataForRecipientsAndGuild(network, lockAddress, recipients, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Retrieves an event based on its slug
   * @param {string} slug Slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getEvent(slug, options) {
    return DefaultApiFp(this.configuration).getEvent(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get details of an event collection
   * @param {string} slug 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getEventCollection(slug, options) {
    return DefaultApiFp(this.configuration).getEventCollection(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get the event details from lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getEventDetails(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).getEventDetails(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * get a ticket for a key by event.
   * @param {string} slug Slug
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getEventTicket(slug, network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).getEventTicket(slug, network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get events in a collection
   * @param {string} slug 
   * @param {number} [page] 
   * @param {number} [pageSize] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getEventsInCollection(slug, page, pageSize, options) {
    return DefaultApiFp(this.configuration).getEventsInCollection(slug, page, pageSize, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get job result by job id
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} jobId Id of the exported job.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getExportedKeys(network, lockAddress, jobId, options) {
    return DefaultApiFp(this.configuration).getExportedKeys(network, lockAddress, jobId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get saved lock settings
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getLockSettings(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).getLockSettings(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get lock setting by slug
   * @param {string} slug Slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getLockSettingsBySlug(slug, options) {
    return DefaultApiFp(this.configuration).getLockSettingsBySlug(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Returns Stripe connection details
   * @param {string} lockAddress Lock address.
   * @param {number} chain Lock network
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getLockStripeConnectionDetails(lockAddress, chain, options) {
    return DefaultApiFp(this.configuration).getLockStripeConnectionDetails(lockAddress, chain, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get receipt details
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} hash Hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getReceipt(network, lockAddress, hash, options) {
    return DefaultApiFp(this.configuration).getReceipt(network, lockAddress, hash, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get all receipts for a lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getReceipts(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).getReceipts(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get supplier details
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getReceiptsBase(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).getReceiptsBase(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Retrieve the status of receipt generation for a lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getReceiptsStatus(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).getReceiptsStatus(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Lists the locks connected to Stripe for a user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getStripeConnections(options) {
    return DefaultApiFp(this.configuration).getStripeConnections(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get an active subscription associated with the key. It can be fiat or crypto.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getSubscription(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).getSubscription(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * get a ticket for a key by lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getTicket(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).getTicket(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get unapproved events for a collection
   * @param {string} slug 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getUnapprovedEventsForCollection(slug, options) {
    return DefaultApiFp(this.configuration).getUnapprovedEventsForCollection(slug, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Gets User account type by email.
   * @param {string} emailAddress 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getUserAccountType(emailAddress, options) {
    return DefaultApiFp(this.configuration).getUserAccountType(emailAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * get user metadata for a lock.
   * @param {string} lockAddress Lock address.
   * @param {string} userAddress User address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getUserMetadata(lockAddress, userAddress, options) {
    return DefaultApiFp(this.configuration).getUserMetadata(lockAddress, userAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Given a user\'s email address, retrieves their private key. In the case of failure a rejected promise is returned to the caller.
   * @param {string} emailAddress 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getUserPrivateKey(emailAddress, options) {
    return DefaultApiFp(this.configuration).getUserPrivateKey(emailAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Given a user\'s email address, retrieves their recovery phrase. In the case of failure a rejected promise is returned to the caller.
   * @param {string} emailAddress 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getUserRecoveryPhrase(emailAddress, options) {
    return DefaultApiFp(this.configuration).getUserRecoveryPhrase(emailAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Gets Coinbase WAAS token for user by email and selected provider.
   * @param {string} captcha Recaptcha value to pass.
   * @param {string} emailAddress 
   * @param {string} selectedProvider 
   * @param {GetWaasTokenRequest} getWaasTokenRequest JSON object containing sign in token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options) {
    return DefaultApiFp(this.configuration).getWaasToken(captcha, emailAddress, selectedProvider, getWaasTokenRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Returns if credit card payment are enable for a lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  isCardPaymentEnabledForLock(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).isCardPaymentEnabledForLock(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get key and user metadata.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  keyMetadata(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).keyMetadata(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List of lock keys by network with additional metadata if the caller is the lockManager (deprecated, use keys-by-page)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} query Query you want to search for.
   * @param {string} filterKey The key you want to search on.
   * @param {string} [expiration] Expiration time
   * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
   * @param {number} [page] Page number when paginating
   * @param {number} [max] Maximum number of items to get by page
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
    return DefaultApiFp(this.configuration).keys(network, lockAddress, query, filterKey, expiration, approval, page, max, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List of lock keys by network with additional metadata if the caller is the lockManager
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} query Query you want to search for.
   * @param {string} filterKey The key you want to search on.
   * @param {string} [expiration] Expiration time
   * @param {string} [approval] Approval status (relevant for offchain approvals on events for example)
   * @param {number} [page] Page number when paginating
   * @param {number} [max] Maximum number of items to get by page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options) {
    return DefaultApiFp(this.configuration).keysByPage(network, lockAddress, query, filterKey, expiration, approval, page, max, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * A list of all checkout configs created by the user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  listCheckoutConfigs(options) {
    return DefaultApiFp(this.configuration).listCheckoutConfigs(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List payment methods for user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  listPaymentMethods(options) {
    return DefaultApiFp(this.configuration).listPaymentMethods(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get metadata for lock address.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  lockMetadata(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).lockMetadata(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Login as a user using Sign in with Ethereum message signed by the wallet address.
   * @param {LoginRequest} loginRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  login(loginRequest, options) {
    return DefaultApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Verify an access token using Privy\'s library and authenticate the user
   * @param {LoginWithPrivyRequest} loginWithPrivyRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  loginWithPrivy(loginWithPrivyRequest, options) {
    return DefaultApiFp(this.configuration).loginWithPrivy(loginWithPrivyRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Logout from locksmith. This will invalidate all your session tokens.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  logout(options) {
    return DefaultApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Provide a unique nonce for using in sign in with ethereum
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  nonce(options) {
    return DefaultApiFp(this.configuration).nonce(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * 
   * @param {number} network Network id.
   * @param {number} [amount] Amount of tokens you want to get the price for in USD.
   * @param {string} [address] The erc20 address you want to get the price for. If nothing is passed, the API will return the native token price.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  price(network, amount, address, options) {
    return DefaultApiFp(this.configuration).price(network, amount, address, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create purchase intent for stripe.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {PurchaseRequest} purchaseRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  purchase(network, lockAddress, purchaseRequest, options) {
    return DefaultApiFp(this.configuration).purchase(network, lockAddress, purchaseRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * resubscribe emails from lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  reSubscribeEmail(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).reSubscribeEmail(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Remove a single event from an event collection.
   * @param {string} slug Slug of the event collection.
   * @param {RemoveEventBody} removeEventBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  removeEventFromCollection(slug, removeEventBody, options) {
    return DefaultApiFp(this.configuration).removeEventFromCollection(slug, removeEventBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Remove a manager address from an event collection
   * @param {string} slug 
   * @param {RemoveManagerBody} removeManagerBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  removeManagerFromEventCollection(slug, removeManagerBody, options) {
    return DefaultApiFp(this.configuration).removeManagerFromEventCollection(slug, removeManagerBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Remove all payment methods for a user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  removePaymentMethods(options) {
    return DefaultApiFp(this.configuration).removePaymentMethods(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Invalidate a session.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  revoke(options) {
    return DefaultApiFp(this.configuration).revoke(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * User applies to attend an event.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} captcha Recaptcha value to pass.
   * @param {RsvpRequest} [rsvpRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  rsvp(network, lockAddress, captcha, rsvpRequest, options) {
    return DefaultApiFp(this.configuration).rsvp(network, lockAddress, captcha, rsvpRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Save custom email content for a specif lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} template Template id for email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  saveCustomEmailContent(network, lockAddress, template, options) {
    return DefaultApiFp(this.configuration).saveCustomEmailContent(network, lockAddress, template, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Save event data
   * @param {SaveEventDataRequest} [saveEventDataRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  saveEventData(saveEventDataRequest, options) {
    return DefaultApiFp(this.configuration).saveEventData(saveEventDataRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Save lock settings
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {LockSettings} [lockSettings] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  saveLockSetting(network, lockAddress, lockSettings, options) {
    return DefaultApiFp(this.configuration).saveLockSetting(network, lockAddress, lockSettings, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create purchaser details
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} hash Hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  saveReceipt(network, lockAddress, hash, options) {
    return DefaultApiFp(this.configuration).saveReceipt(network, lockAddress, hash, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Create supplier details.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  saveReceiptsBase(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).saveReceiptsBase(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Send custom email to recipients
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {SendCustomEmailRequest} [sendCustomEmailRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options) {
    return DefaultApiFp(this.configuration).sendCustomEmail(network, lockAddress, sendCustomEmailRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Send event invites to a list of recipients
   * @param {string} slug Slug
   * @param {SendEventInvitesRequest} [sendEventInvitesRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  sendEventInvites(slug, sendEventInvitesRequest, options) {
    return DefaultApiFp(this.configuration).sendEventInvites(slug, sendEventInvitesRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Sends a verification code to the user\'s email if the current code is expired or does not exist.
   * @param {string} captcha Recaptcha value to pass.
   * @param {string} emailAddress 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  sendVerificationCode(captcha, emailAddress, options) {
    return DefaultApiFp(this.configuration).sendVerificationCode(captcha, emailAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Setup a payment method for user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  setupPayment(options) {
    return DefaultApiFp(this.configuration).setupPayment(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Generate signature to prove validity of token.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  signTicket(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).signTicket(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get QR code for the key.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  ticketQRCode(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).ticketQRCode(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get the verification URL for the key.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  ticketVerificationUrl(network, lockAddress, keyId, options) {
    return DefaultApiFp(this.configuration).ticketVerificationUrl(network, lockAddress, keyId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Finalize the transfer of a key to a recipient. This is to copy over any locksmith related data to the recipient\'s account.
   * @param {TransferDoneRequest} [transferDoneRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  transferDone(transferDoneRequest, options) {
    return DefaultApiFp(this.configuration).transferDone(transferDoneRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Unsubscribe email from lock
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  unsubscribeEmail(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).unsubscribeEmail(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update application by id.
   * @param {string} id Application id.
   * @param {ApplicationBody} [applicationBody] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateApplication(id, applicationBody, options) {
    return DefaultApiFp(this.configuration).updateApplication(id, applicationBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * A new checkout config is created or updated if it already exists
   * @param {string} id This is optional. If you don\&#39;t pass an ID, a new config will be created. If you pass an ID, the config will be created.
   * @param {UpdateCheckoutConfigRequest} [updateCheckoutConfigRequest] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateCheckoutConfig(id, updateCheckoutConfigRequest, options) {
    return DefaultApiFp(this.configuration).updateCheckoutConfig(id, updateCheckoutConfigRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update an existing event collection
   * @param {string} slug 
   * @param {EventCollectionBody} eventCollectionBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateEventCollection(slug, eventCollectionBody, options) {
    return DefaultApiFp(this.configuration).updateEventCollection(slug, eventCollectionBody, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update or create metadata for key.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} keyId Key Id.
   * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options) {
    return DefaultApiFp(this.configuration).updateKeyMetadata(network, lockAddress, keyId, updateLockMetadataRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update or create metadata for lock address.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {UpdateLockMetadataRequest} updateLockMetadataRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options) {
    return DefaultApiFp(this.configuration).updateLockMetadata(network, lockAddress, updateLockMetadataRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Updates a user\'s private key, using their email address as key. In the case of failure a rejected promise is returned to the caller.
   * @param {string} emailAddress 
   * @param {UpdateUserEncryptedPrivateKeyRequest} updateUserEncryptedPrivateKeyRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options) {
    return DefaultApiFp(this.configuration).updateUserEncryptedPrivateKey(emailAddress, updateUserEncryptedPrivateKeyRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update or create user metadata for lock.
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} userAddress User address.
   * @param {UpdateUserMetadataRequest} updateUserMetadataRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options) {
    return DefaultApiFp(this.configuration).updateUserMetadata(network, lockAddress, userAddress, updateUserMetadataRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Update or create user metadata for lock.
   * @param {UpdateUsersMetadataRequest} updateUsersMetadataRequest users array containing user metadata.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  updateUsersMetadata(updateUsersMetadataRequest, options) {
    return DefaultApiFp(this.configuration).updateUsersMetadata(updateUsersMetadataRequest, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Upload images to locksmith
   * @param {Array<any>} [images] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  uploadImages(images, options) {
    return DefaultApiFp(this.configuration).uploadImages(images, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Get user details.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  user(options) {
    return DefaultApiFp(this.configuration).user(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Check if an address is a verifier of the lock. (deprecated in favor of eventVerifier)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {string} verifierAddress User address to use as verifier
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  verifier(network, lockAddress, verifierAddress, options) {
    return DefaultApiFp(this.configuration).verifier(network, lockAddress, verifierAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * List of verifiers for the lock address. (deprecated in favor of eventVerifiers)
   * @param {number} network Network id.
   * @param {string} lockAddress Lock address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  verifiers(network, lockAddress, options) {
    return DefaultApiFp(this.configuration).verifiers(network, lockAddress, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   * Verifies the email code sent to the users email address.
   * @param {string} emailAddress 
   * @param {VerifyEmailCodeRequest} verifyEmailCodeRequest 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  verifyEmailCode(emailAddress, verifyEmailCodeRequest, options) {
    return DefaultApiFp(this.configuration).verifyEmailCode(emailAddress, verifyEmailCodeRequest, options).then((request) => request(this.axios, this.basePath));
  }
};

// src/@generated/client/configuration.ts
var Configuration = class {
  constructor(param = {}) {
    this.apiKey = param.apiKey;
    this.username = param.username;
    this.password = param.password;
    this.accessToken = param.accessToken;
    this.basePath = param.basePath;
    this.baseOptions = param.baseOptions;
    this.formDataCtor = param.formDataCtor;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp("^(application/json|[^;/ 	]+/[^;/ 	]+[+]json)[ 	]*(;.*)?$", "i");
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === "application/json-patch+json");
  }
};

// src/locksmithService.ts
var LocksmithService = class extends DefaultApi {
  /**
   * Helper static createSiweMessage method wrapping SIWE or sign in with ethereum standard message
   */
  static createSiweMessage(options) {
    return new SiweMessage(options);
  }
};

// ../../node_modules/tslib/tslib.es6.mjs
var __assign = function() {
  __assign = Object.assign || function __assign2(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};

// ../../node_modules/graphql-tag/lib/index.js
import { parse } from "graphql";
var docCache = /* @__PURE__ */ new Map();
var fragmentSourceMap = /* @__PURE__ */ new Map();
var printFragmentWarnings = true;
var experimentalFragmentVariables = false;
function normalize(string) {
  return string.replace(/[\s,]+/g, " ").trim();
}
function cacheKeyFromLoc(loc) {
  return normalize(loc.source.body.substring(loc.start, loc.end));
}
function processFragments(ast) {
  var seenKeys = /* @__PURE__ */ new Set();
  var definitions = [];
  ast.definitions.forEach(function(fragmentDefinition) {
    if (fragmentDefinition.kind === "FragmentDefinition") {
      var fragmentName = fragmentDefinition.name.value;
      var sourceKey = cacheKeyFromLoc(fragmentDefinition.loc);
      var sourceKeySet = fragmentSourceMap.get(fragmentName);
      if (sourceKeySet && !sourceKeySet.has(sourceKey)) {
        if (printFragmentWarnings) {
          console.warn("Warning: fragment with name " + fragmentName + " already exists.\ngraphql-tag enforces all fragment names across your application to be unique; read more about\nthis in the docs: http://dev.apollodata.com/core/fragments.html#unique-names");
        }
      } else if (!sourceKeySet) {
        fragmentSourceMap.set(fragmentName, sourceKeySet = /* @__PURE__ */ new Set());
      }
      sourceKeySet.add(sourceKey);
      if (!seenKeys.has(sourceKey)) {
        seenKeys.add(sourceKey);
        definitions.push(fragmentDefinition);
      }
    } else {
      definitions.push(fragmentDefinition);
    }
  });
  return __assign(__assign({}, ast), { definitions });
}
function stripLoc(doc) {
  var workSet = new Set(doc.definitions);
  workSet.forEach(function(node) {
    if (node.loc)
      delete node.loc;
    Object.keys(node).forEach(function(key) {
      var value = node[key];
      if (value && typeof value === "object") {
        workSet.add(value);
      }
    });
  });
  var loc = doc.loc;
  if (loc) {
    delete loc.startToken;
    delete loc.endToken;
  }
  return doc;
}
function parseDocument(source) {
  var cacheKey = normalize(source);
  if (!docCache.has(cacheKey)) {
    var parsed = parse(source, {
      experimentalFragmentVariables,
      allowLegacyFragmentVariables: experimentalFragmentVariables
    });
    if (!parsed || parsed.kind !== "Document") {
      throw new Error("Not a valid GraphQL document.");
    }
    docCache.set(cacheKey, stripLoc(processFragments(parsed)));
  }
  return docCache.get(cacheKey);
}
function gql(literals) {
  var args = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }
  if (typeof literals === "string") {
    literals = [literals];
  }
  var result = literals[0];
  args.forEach(function(arg, i) {
    if (arg && arg.kind === "Document") {
      result += arg.loc.source.body;
    } else {
      result += arg;
    }
    result += literals[i + 1];
  });
  return parseDocument(result);
}
function resetCaches() {
  docCache.clear();
  fragmentSourceMap.clear();
}
function disableFragmentWarnings() {
  printFragmentWarnings = false;
}
function enableExperimentalFragmentVariables() {
  experimentalFragmentVariables = true;
}
function disableExperimentalFragmentVariables() {
  experimentalFragmentVariables = false;
}
var extras = {
  gql,
  resetCaches,
  disableFragmentWarnings,
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables
};
(function(gql_1) {
  gql_1.gql = extras.gql, gql_1.resetCaches = extras.resetCaches, gql_1.disableFragmentWarnings = extras.disableFragmentWarnings, gql_1.enableExperimentalFragmentVariables = extras.enableExperimentalFragmentVariables, gql_1.disableExperimentalFragmentVariables = extras.disableExperimentalFragmentVariables;
})(gql || (gql = {}));
gql["default"] = gql;
var lib_default = gql;

// src/@generated/subgraph/index.ts
var Key_OrderBy = /* @__PURE__ */ ((Key_OrderBy3) => {
  Key_OrderBy3["Cancelled"] = "cancelled";
  Key_OrderBy3["CreatedAt"] = "createdAt";
  Key_OrderBy3["CreatedAtBlock"] = "createdAtBlock";
  Key_OrderBy3["Expiration"] = "expiration";
  Key_OrderBy3["Id"] = "id";
  Key_OrderBy3["Lock"] = "lock";
  Key_OrderBy3["LockAddress"] = "lock__address";
  Key_OrderBy3["LockCreatedAtBlock"] = "lock__createdAtBlock";
  Key_OrderBy3["LockDeployer"] = "lock__deployer";
  Key_OrderBy3["LockExpirationDuration"] = "lock__expirationDuration";
  Key_OrderBy3["LockId"] = "lock__id";
  Key_OrderBy3["LockLastKeyMintedAt"] = "lock__lastKeyMintedAt";
  Key_OrderBy3["LockLastKeyRenewedAt"] = "lock__lastKeyRenewedAt";
  Key_OrderBy3["LockMaxKeysPerAddress"] = "lock__maxKeysPerAddress";
  Key_OrderBy3["LockMaxNumberOfKeys"] = "lock__maxNumberOfKeys";
  Key_OrderBy3["LockName"] = "lock__name";
  Key_OrderBy3["LockNumberOfCancelReceipts"] = "lock__numberOfCancelReceipts";
  Key_OrderBy3["LockNumberOfReceipts"] = "lock__numberOfReceipts";
  Key_OrderBy3["LockPrice"] = "lock__price";
  Key_OrderBy3["LockSymbol"] = "lock__symbol";
  Key_OrderBy3["LockTokenAddress"] = "lock__tokenAddress";
  Key_OrderBy3["LockTotalKeys"] = "lock__totalKeys";
  Key_OrderBy3["LockVersion"] = "lock__version";
  Key_OrderBy3["Manager"] = "manager";
  Key_OrderBy3["Owner"] = "owner";
  Key_OrderBy3["TokenId"] = "tokenId";
  Key_OrderBy3["TokenUri"] = "tokenURI";
  Key_OrderBy3["TransactionsHash"] = "transactionsHash";
  return Key_OrderBy3;
})(Key_OrderBy || {});
var Lock_OrderBy = /* @__PURE__ */ ((Lock_OrderBy2) => {
  Lock_OrderBy2["Address"] = "address";
  Lock_OrderBy2["CreatedAtBlock"] = "createdAtBlock";
  Lock_OrderBy2["Deployer"] = "deployer";
  Lock_OrderBy2["ExpirationDuration"] = "expirationDuration";
  Lock_OrderBy2["Id"] = "id";
  Lock_OrderBy2["KeyGranters"] = "keyGranters";
  Lock_OrderBy2["Keys"] = "keys";
  Lock_OrderBy2["LastKeyMintedAt"] = "lastKeyMintedAt";
  Lock_OrderBy2["LastKeyRenewedAt"] = "lastKeyRenewedAt";
  Lock_OrderBy2["LockManagers"] = "lockManagers";
  Lock_OrderBy2["MaxKeysPerAddress"] = "maxKeysPerAddress";
  Lock_OrderBy2["MaxNumberOfKeys"] = "maxNumberOfKeys";
  Lock_OrderBy2["Name"] = "name";
  Lock_OrderBy2["NumberOfCancelReceipts"] = "numberOfCancelReceipts";
  Lock_OrderBy2["NumberOfReceipts"] = "numberOfReceipts";
  Lock_OrderBy2["Price"] = "price";
  Lock_OrderBy2["ReferrerFees"] = "referrerFees";
  Lock_OrderBy2["Symbol"] = "symbol";
  Lock_OrderBy2["TokenAddress"] = "tokenAddress";
  Lock_OrderBy2["TotalKeys"] = "totalKeys";
  Lock_OrderBy2["Version"] = "version";
  return Lock_OrderBy2;
})(Lock_OrderBy || {});
var OrderDirection = /* @__PURE__ */ ((OrderDirection2) => {
  OrderDirection2["Asc"] = "asc";
  OrderDirection2["Desc"] = "desc";
  return OrderDirection2;
})(OrderDirection || {});
var AllLocksDocument = lib_default`
    query allLocks($first: Int = 1, $skip: Int, $where: Lock_filter, $orderBy: Lock_orderBy, $orderDirection: OrderDirection, $referrerFeeSkip: Int = 0) {
  locks(
    first: $first
    skip: $skip
    where: $where
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    id
    address
    name
    expirationDuration
    tokenAddress
    symbol
    price
    lockManagers
    keyGranters
    version
    maxNumberOfKeys
    maxKeysPerAddress
    createdAtBlock
    totalKeys
    referrerFees(skip: $referrerFeeSkip) {
      id
      referrer
      fee
    }
  }
}
    `;
var AllKeysDocument = lib_default`
    query AllKeys($first: Int = 1, $skip: Int, $where: Key_filter, $orderBy: Key_orderBy, $orderDirection: OrderDirection) {
  keys(
    first: $first
    skip: $skip
    where: $where
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    id
    lock {
      id
      address
      name
      expirationDuration
      tokenAddress
      symbol
      price
      lockManagers
      keyGranters
      version
      maxNumberOfKeys
      maxKeysPerAddress
      createdAtBlock
      totalKeys
    }
    tokenId
    owner
    manager
    expiration
    tokenURI
    createdAt
    createdAtBlock
    cancelled
    transactionsHash
  }
}
    `;
var AllReceiptsDocument = lib_default`
    query AllReceipts($first: Int = 100, $skip: Int, $where: Receipt_filter, $orderBy: Receipt_orderBy, $orderDirection: OrderDirection) {
  receipts(
    first: $first
    skip: $skip
    where: $where
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    id
    receiptNumber
    timestamp
    sender
    payer
    recipient
    lockAddress
    tokenAddress
    gasTotal
    amountTransferred
  }
}
    `;
var AllLocksWithKeysDocument = lib_default`
    query allLocksWithKeys($first: Int = 1, $skip: Int, $where: Lock_filter, $keyFilter: Key_filter, $orderBy: Lock_orderBy, $keyOrderBy: Key_orderBy, $orderDirection: OrderDirection) {
  locks(where: $where, orderBy: $orderBy, orderDirection: $orderDirection) {
    id
    address
    name
    expirationDuration
    tokenAddress
    symbol
    price
    lockManagers
    keyGranters
    version
    maxNumberOfKeys
    maxKeysPerAddress
    createdAtBlock
    totalKeys
    keys(where: $keyFilter, orderBy: $keyOrderBy, first: $first, skip: $skip) {
      id
      tokenId
      owner
      manager
      expiration
      tokenURI
      createdAt
      createdAtBlock
      cancelled
      transactionsHash
    }
  }
}
    `;
var defaultWrapper = (action, _operationName, _operationType, _variables) => action();
function getSdk(client, withWrapper = defaultWrapper) {
  return {
    allLocks(variables, requestHeaders) {
      return withWrapper((wrappedRequestHeaders) => client.request(AllLocksDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), "allLocks", "query", variables);
    },
    AllKeys(variables, requestHeaders) {
      return withWrapper((wrappedRequestHeaders) => client.request(AllKeysDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), "AllKeys", "query", variables);
    },
    AllReceipts(variables, requestHeaders) {
      return withWrapper((wrappedRequestHeaders) => client.request(AllReceiptsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), "AllReceipts", "query", variables);
    },
    allLocksWithKeys(variables, requestHeaders) {
      return withWrapper((wrappedRequestHeaders) => client.request(AllLocksWithKeysDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), "allLocksWithKeys", "query", variables);
    }
  };
}

// src/subgraph/index.ts
import { GraphQLClient } from "graphql-request";
var SubgraphService = class {
  constructor(endpointUrl, networks2) {
    this.networks = networks2 || networks;
    this.endpointUrl = endpointUrl;
  }
  createSdk(networkId = 1) {
    const network = this.networks[networkId];
    const endpointUrl = this.endpointUrl;
    const GraphQLClientURL = endpointUrl ? endpointUrl : network.subgraph.endpoint;
    const client = new GraphQLClient(GraphQLClientURL);
    const sdk = getSdk(client);
    return sdk;
  }
  /**
   * Get locks from multiple networks. By default, all networks will be queried.
   * If you want to query only specific network, you can pass options as the second parameter with network ids array.
   * ```ts
   * const service = new SubgraphService()
   * const locksOnMainnetAndGoerli = await service.locks({ first: 100, skip: 50, where: {}}, { networks: [1, 5] })
   * const locksOnAllNetworks = await service.locks({ first: 1000 })
   * ```
   */
  async locks(variables, options) {
    const networks2 = options?.networks?.map((item) => this.networks[item]) || Object.values(this.networks).filter((item) => item.id !== 31337);
    const items = await Promise.all(
      networks2.map(async (config) => {
        try {
          const sdk = this.createSdk(config.id);
          const results = await sdk.allLocks(variables);
          return results.locks.map((item) => ({
            ...item,
            network: config.id
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      })
    );
    return items.flat();
  }
  /**
   * Get locks with keys from multiple networks. By default, all networks will be queried.
   * If you want to query only specific network, you can pass options as the second parameter with network ids array.
   * ```ts
   * const service = new SubgraphService()
   * const locksKeysOnMainnetAndGoerli = await service.locksKeys({ first: 100, skip: 50, where: {}}, { networks: [1, 5] })
   * const locksKeysOnAllNetworks = await service.locksKeys({ first: 1000 })
   * ```
   */
  async locksKeys(variables, options) {
    const networks2 = options?.networks?.map((item) => this.networks[item]) || Object.values(this.networks).filter((item) => item.id !== 31337);
    const items = await Promise.all(
      networks2.map(async (config) => {
        try {
          const sdk = this.createSdk(config.id);
          const results = await sdk.allLocksWithKeys(variables);
          return results.locks.map((item) => ({
            ...item,
            network: config.id
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      })
    );
    return items.flat();
  }
  /**
   * Get a single lock on a network. This is a helper provided on top of locks.
   */
  async lock(variables, options) {
    const locks = await this.locks(variables, {
      networks: [options.network]
    });
    return locks?.[0];
  }
  /**
   * Get keys and associated lock data from multiple networks. By default, all networks will be queried.
   * If you want to query only specific network, you can pass options as the second parameter with network ids array.
   * ```ts
   * const service = new SubgraphService()
   * const keysOnMainnetAndGoerli = await service.keys({ first: 100, skip: 50, where: {}}, { networks: [1, 5] })
   * const keysOnAllNetworks = await service.keys({ first: 1000 })
   * ```
   */
  async keys(variables, options) {
    const networks2 = options?.networks?.map((item) => this.networks[item]) || Object.values(this.networks).filter((item) => item.id !== 31337);
    const items = await Promise.all(
      networks2.map(async (config) => {
        try {
          const sdk = this.createSdk(config.id);
          const results = await sdk.AllKeys(variables);
          return results.keys.map((item) => ({
            ...item,
            network: config.id
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      })
    );
    return items.flat();
  }
  /**
   * Get a single key on a network. This is a helper provided on top of keys.
   */
  async key(variables, options) {
    const keys = await this.keys(variables, { networks: [options.network] });
    return keys?.[0];
  }
  /** Get list or receipts from multiple networks */
  async receipts(variables, options) {
    const networks2 = options?.networks?.map((item) => this.networks[item]) || Object.values(this.networks).filter((item) => item.id !== 31337);
    const items = await Promise.all(
      networks2.map(async (config) => {
        try {
          const sdk = this.createSdk(config.id);
          const results = await sdk.AllReceipts(variables);
          return results.receipts.map((item) => ({
            ...item,
            network: config.id
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      })
    );
    return items.flat();
  }
  /** Get a single receipt for a specific network */
  async receipt(variables, options) {
    const receipts = await this.receipts(variables, {
      networks: [options.network]
    });
    return receipts?.[0];
  }
};

// src/KeyManager.ts
import { ethers as ethers13 } from "ethers";
var KeyManagerAbi = [
  "function transfer(address lock, uint256 token, address owner, uint256 deadline, bytes transferCode)",
  "function locksmiths(address user) view returns (bool)"
];
var TransferTypes = {
  Transfer: [
    { name: "lock", type: "address" },
    { name: "token", type: "uint256" },
    { name: "owner", type: "address" },
    { name: "deadline", type: "uint256" }
  ]
};
var KeyManager = class {
  constructor(networks2) {
    this.networks = networks2 || networks;
  }
  isSigner(network, signer) {
    const provider = this.providerForNetwork(network);
    const KeyManagerContract = this.getContract({ network });
    return KeyManagerContract.getFunction("locksmiths")(signer);
  }
  providerForNetwork(network) {
    if (!this.networks[network]) {
      throw new Error(`Missing config for ${network}`);
    }
    return new ethers13.JsonRpcProvider(this.networks[network].provider, network);
  }
  getDomain(network) {
    const networkConfig = this.networks[network];
    const domain = {
      name: "KeyManager",
      version: "1",
      chainId: networkConfig.id,
      verifyingContract: networkConfig.keyManagerAddress
    };
    return domain;
  }
  /**
   * This function returns the KeyManager contract for a given network.
   */
  getContract({ network, signer }) {
    const networkConfig = this.networks[network];
    const keyManagerContractAddress = networkConfig.keyManagerAddress;
    if (!keyManagerContractAddress) {
      throw new Error("No key manager contract address found for network");
    }
    const provider = this.providerForNetwork(network);
    const KeyManagerContract = new ethers13.Contract(
      keyManagerContractAddress,
      KeyManagerAbi,
      provider
    );
    if (signer) {
      return KeyManagerContract.connect(signer);
    }
    return KeyManagerContract;
  }
  /**
   * This function creates a transfer signature.
   */
  async createTransferSignature({
    params,
    signer,
    network
  }) {
    const domain = this.getDomain(network);
    const signature = await signer.signTypedData(domain, TransferTypes, params);
    return signature;
  }
  /**
   * This function transfers a key given a transfer signature.
   */
  async transfer({
    network,
    params: { lock, token, owner, deadline, transferSignature },
    signer
  }) {
    const KeyManagerContract = this.getContract({ network, signer });
    const tx = await KeyManagerContract.getFunction("transfer")(
      lock,
      token,
      owner,
      deadline,
      transferSignature
    );
    return tx;
  }
  /**
   * This function checks if a transfer is possible.
   */
  async isTransferPossible({
    network,
    params: { lock, token, owner, deadline, transferSignature },
    signer
  }) {
    const KeyManagerContract = this.getContract({ network, signer });
    const tx = await KeyManagerContract.getFunction("transfer").staticCall(
      lock,
      token,
      owner,
      deadline,
      transferSignature
    );
    return tx;
  }
  /**
   * This function creates a wallet address from a lock address and an email address.
   */
  createTransferAddress({ params }) {
    const item = {
      email: params.email.trim().toLowerCase(),
      lock: params.lockAddress.trim().toLowerCase()
    };
    return ethers13.getAddress(ethers13.id(JSON.stringify(item)).slice(0, 42));
  }
  /**
   * Return signer for the transfer signature provided in the params
   */
  getSignerForTransferSignature({
    params: { lock, token, owner, deadline, transferSignature },
    network
  }) {
    const domain = this.getDomain(network);
    const recoveredAddress = ethers13.verifyTypedData(
      domain,
      TransferTypes,
      { lock, token, owner, deadline },
      transferSignature
    );
    return recoveredAddress;
  }
};
export {
  ApprovedRefunds200ResponseFormatEnum,
  CapturePurchaseRequestPurchaseTypeEnum,
  Configuration,
  CreateDownloadReceiptsRequest200ResponseStatusEnum,
  DefaultApi,
  DefaultApiAxiosParamCreator,
  DefaultApiFactory,
  DefaultApiFp,
  GetReceiptsStatus200ResponseItemsInnerPayloadStatusEnum,
  GetSubscription200ResponseSubscriptionsInnerTypeEnum,
  GetUserAccountType200ResponseUserAccountTypeEnum,
  KeyManager,
  KeyManagerAbi,
  Key_OrderBy as KeyOrderBy,
  Lock_OrderBy as LockOrderBy,
  LocksmithService,
  Configuration as LocksmithServiceConfiguration,
  OrderDirection,
  SubgraphService,
  TransferTypes,
  TransferWithAuthorizationTypes,
  WalletService,
  Web3Service,
  approveTransfer,
  getAllowance,
  getCurrentProvider,
  getErc20BalanceForAddress,
  getErc20Decimals,
  getErc20TokenSymbol,
  getWeb3Provider,
  recoverTransferAuthorization,
  signTransferAuthorization,
  transferWithAuthorization
};
